export const setSessionStorage = (key, value, isJson) => {
    if (isJson) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } else {
        window.sessionStorage.setItem(key, value);
    }
};

export const getSessionStorage = (key, isJson) => {
    const value = window.sessionStorage.getItem(key);

    if (value == null) {
        return null;
    }
    if (isJson) {
        return JSON.parse(value);
    }
    return value;
};
export const removeSessionStorage = (key) => {
    window.sessionStorage.removeItem(key);
};
