//TODO: Replace product name from configuration
export const productName = 'Jumptrack';
export const JUMPTRACK_PRODUCT_NAME = 'Jumptrack';
export const KARMAK_PRODUCT_NAME = 'Karmak';

export const roleMap = {
    ROL_ROUTELINK_ADMIN: { id: 'ROL_ROUTELINK_ADMIN', text: productName + ' Administrator' },
    ROL_ROUTELINK_DISPATCHER: {
        id: 'ROL_ROUTELINK_DISPATCHER',
        text: productName + ' Dispatcher',
    },
    ROL_ROUTELINK_SCHEDULER: { id: 'ROL_ROUTELINK_SCHEDULER', text: productName + ' Scheduler' },
    ROL_ROUTELINK_VIEWER: { id: 'ROL_ROUTELINK_VIEWER', text: productName + ' Viewer' },
    ROL_ROUTELINK_DRIVER: { id: 'ROL_ROUTELINK_DRIVER', text: productName + ' Driver' },
    ROL_ROUTELINK_LOADER: { id: 'ROL_ROUTELINK_LOADER', text: productName + ' Loader' },
};
export const jumptechRoleMap = {
    JUMPTECH_ADMIN: { id: 'ROL_JUMPTECH_ADMIN', text: 'JumpTech Admin User' },
    JUMPTECH: {
        id: 'ROL_JUMPTECH',
        text: 'JumpTech User',
    },
};
