import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

import { GET_DISTRIBUTION_LIST_JSON_URL } from './CONSTANTS';
/**
 *
 * @returns Json array with results
 */
export const getDistributionListWithRegion = (controller) => {
    console.log('jsonInfoLookupServices > getDistributionListWithRegion called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                GET_DISTRIBUTION_LIST_JSON_URL,
                {},
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('getDistributionListWithRegion > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getDistributionListWithRegion > axios err=', err);
                doRejectValidation(err, reject, 'getDistributionListWithRegion');
            });
    });
};
