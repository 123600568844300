import { fleetTabs } from 'pages/manifest/CONSTANTS';
import {
    MANIFEST_FLEET_REDUCER_CHANGE_STATE_VARIABLE,
    MANIFEST_FLEET_REDUCER_SET_LIMITS,
    MANIFEST_FLEET_REDUCER_SET_ASSETS,
    MANIFEST_FLEET_REDUCER_SET_SCHEDULE,
    MANIFEST_FLEET_REDUCER_SET_PARAMS,
    MANIFEST_FLEET_REDUCER_SET_STOPS,
    MANIFEST_FLEET_REDUCER_TOGGLE_HOVER,
    MANIFEST_FLEET_REDUCER_CHANGE_OPERATION_MODAL,
    MANIFEST_FLEET_REDUCER_CHANGE_DELIVERYSTOP_MODAL,
    MANIFEST_FLEET_REDUCER_CHANGE_PRIORITY_MODAL,
    MANIFEST_FLEET_REDUCER_CLEAN,
} from 'redux/CONSTANTS';

let initialData = {
    selectedTab: fleetTabs.STOPS,
    params: {
        distKey: null,
        selectedDate: null,
    },
    maxRoute: '?',
    maxStop: '?',
    distribution: {
        location: null,
        routeBalance: false,
        forceUseAllAssets: false,
        window: null,
        routes: [],
        persist: false,
        loaded: false,
    },
    schedule: {
        fleetRoutes: null,
        missedStops: null,
        emptyRoutes: null,
    },
    stops: [],
    hoveredStop: null,
    operationModal: { assetForOperationModal: null, showOperationModal: false, isRoute: false },
    deliveryStopModal: { stopForModal: null, showDeliveryStopModal: false },
    priorityModal: { stopForModal: null, showPriorityModal: false },
};

const manifestFleetReducer = (state = initialData, action) => {
    switch (action.type) {
        case MANIFEST_FLEET_REDUCER_SET_LIMITS: {
            if (typeof action.value === 'string' || action.value instanceof String) {
                return state;
            }
            const newState = { ...state, ...action.value };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_SET_ASSETS: {
            const newState = { ...state, distribution: { ...state.distribution, ...action.value } };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_SET_STOPS: {
            const newState = {
                ...state,
                stops: [...action.value],
                schedule: { fleetRoutes: null, missedStops: null, emptyRoutes: null },
            };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_SET_SCHEDULE: {
            const newState = {
                ...state,
                schedule: { ...state.schedule, ...action.value },
            };
            if (newState.schedule.overridenDuration) {
                newState.distribution.window.durationSec = newState.schedule.overridenDuration;
                newState.distribution.guiOverridden = true;
            }
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_CHANGE_STATE_VARIABLE: {
            const newState = { ...state };
            newState[action.variable] = action.value;
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_TOGGLE_HOVER: {
            const newState = { ...state };
            if (action.eventType === 'mouseleave' && newState.hoveredStop === action.value) {
                newState.hoveredStop = null;
            } else if (action.eventType === 'mouseenter') {
                newState.hoveredStop = action.value;
            }
            return newState;
        }

        case MANIFEST_FLEET_REDUCER_SET_PARAMS: {
            const newState = { ...state, params: { ...state.params, ...action.value } };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_CHANGE_OPERATION_MODAL: {
            const newState = {
                ...state,
                operationModal: { ...state.operationModal, ...action.value },
            };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_CHANGE_DELIVERYSTOP_MODAL: {
            const newState = {
                ...state,
                deliveryStopModal: { ...state.deliveryStopModal, ...action.value },
            };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_CHANGE_PRIORITY_MODAL: {
            const newState = {
                ...state,
                priorityModal: { ...state.priorityModal, ...action.value },
            };
            return newState;
        }
        case MANIFEST_FLEET_REDUCER_CLEAN: {
            const newState = {
                ...initialData,
            };
            return newState;
        }
        default:
            return state;
    }
};
export default manifestFleetReducer;
