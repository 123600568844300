import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import themeReducer from './themeReducer';
import userReducer from './userReducer';
import siteLookupReducer from './siteLookupReducer';
import loadingReducer from './loadingReducer';
import systemMessagesReducer from './systemMessagesReducer';
import imagesReducer from './imagesReducer';
import deliveryDetailReducer from './deliveryDetailReducer';
import manifestFleetReducer from './manifestFleetReducer';
import regDistRouteReducer from './regDisRouteReducer';
import statusTripsReducer from './statusTripsReducer';
import menuReducer from './menuReducer';

export const rootReducer = combineReducers({
    login: loginReducer,
    theme: themeReducer,
    userinfo: userReducer,
    siteLookup: siteLookupReducer,
    loading: loadingReducer,
    systemMessages: systemMessagesReducer,
    images: imagesReducer,
    deliveryDetail: deliveryDetailReducer,
    fleet: manifestFleetReducer,
    regDistRoute: regDistRouteReducer,
    statusTrips: statusTripsReducer,
    menu: menuReducer,
});
