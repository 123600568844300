import axios from 'axios';
import { doRejectValidation, parseXmlResult } from 'utils/JTUtils';
import {
    GET_AUTOCOMPLETE_DRIVER_SEARCH,
    GET_TRACK_USER_LIST,
    GET_USER_DATA_URL,
    POST_LOAD_SITE_DETAILS_URL,
} from './CONSTANTS';

/**
 * Get assigned drivers selected for the current distribution key
 * @param {String} term term to autocomplete
 * @param {String} distributionKey Distribution key to filter
 * @returns Json array with results
 */
export const getAssignedDriverSearch = (term, distributionKey, controller) => {
    console.log('jumptrackLookupServices > getAssignedDriverSearch called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_AUTOCOMPLETE_DRIVER_SEARCH}&term=${term}&distribution=${distributionKey}`, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getAssignedDriverSearch > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getAssignedDriverSearch > axios err=', err);
                doRejectValidation(err, reject, 'getAssignedDriverSearch');
            });
    });
};
/**
 * Gets a list of all the users that the current logged in user has access
 * @returns array with results from XML
 */
export const postTrackUserList = (controller) => {
    console.log('jumptrackLookupServices > getTrackUserList called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_USER_LIST_2"/></request>`;
        axios
            .post(GET_TRACK_USER_LIST, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getTrackUserList > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('getTrackUserList > axios err=', err);
                doRejectValidation(err, reject, 'getTrackUserList');
            });
    });
};
/**
 * Loads user details based on user guid
 * @param {string} guid user id
 * @param {string} login login email
 * @returns XML with user data
 */
export const postLoadUserDetails = (guid, login, controller) => {
    console.log('jumptrackLookupServices > postLoadUserDetails called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_USER" guid="${guid}" login="${login}"></q></request>`;
        axios
            .post(`${GET_USER_DATA_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postLoadUserDetails > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postLoadUserDetails > axios err=', err);
                doRejectValidation(err, reject, 'postLoadUserDetails');
            });
    });
};
/**
 * Load site details by site key
 * @param {String} siteKey key for site
 * @returns Response element with site details
 */
export const postLoadSiteDetails = (siteKey, controller) => {
    console.log('jumptrackApiXmlServices > postAccountQuery called...');
    return new Promise((resolve, reject) => {
        let xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_SITE" key="${siteKey}"/></request>`;

        axios
            .post(`${POST_LOAD_SITE_DETAILS_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveUserBinding > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveUserBinding > axios err=', err);
                doRejectValidation(err, reject, 'postSaveUserBinding');
            });
    });
};
