import { DeliveryType } from 'config/DeliveryType';

export const emptyProduct = {
    prodNumber: '',
    prodName: '',
    qty: '',
    uom: '',
    description: '',
    licensePlate: '',
    productScan: '',
};

export const manifestTypes = ['Delivery', 'Pick Up'];
export const manifestTypesForEdit = [DeliveryType.INVOICE.id, DeliveryType.RETURN.id];
export const DEFAULT_STOP_PRIORITY = 'NORMAL';
export const stopPriorities = {
    LOW: 'Low',
    HIGH: 'High',
    NORMAL: 'Normal',
};
export const fleetTabs = {
    ASSETS: 'assets',
    STOPS: 'stops',
    SCHEDULE: 'schedule',
};
