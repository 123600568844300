import PropTypes from 'prop-types';

import React, { useEffect, useRef } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
    DASHBOARD,
    MAINTENANCE_ACCOUNT,
    MAINTENANCE_DELIVERY,
    MAINTENANCE_PRODUCTS,
    MAINTENANCE_ROUTES,
    MAINTENANCE_SITES,
    MAINTENANCE_USERS,
    MANIFEST_CREATE,
    MANIFEST_EDIT,
    MANIFEST_FLEET,
    MANIFEST_IMPORT,
    MANIFEST_ORDER,
    REPORTS_DELIVERY_EFFICIENCY,
    REPORTS_PLANNED_VS_ACTUAL_DETAIL,
    REPORTS_PLANNED_VS_ACTUAL_OVERVIEW,
    REPORTS_PRINT_LOAD,
    REPORTS_EXACTLOAD_DISCREPANCY,
    REPORTS_ROUTE_STATUS,
    REPORTS_UNSCHEDULED_DELIVERIES,
    STATUS_DELIVERY,
    SUPPORT,
    STATUS_TRIPS,
    MENU_HOME,
    MENU_REPORTS,
    MENU_STATUS,
    MENU_MAINTENANCE,
    MENU_MANIFEST,
    MENU_SUPPORT,
} from 'navigation/CONSTANTS';
import { fetchUserInformation } from 'redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import NavLinkRefresh from './NavLinkRefresh';

const NavMenu = () => {
    const dispatch = useDispatch();
    const controller = useRef(new AbortController());

    //#region selector hooks
    const menuSelected = useSelector((state) => state.menu.menuSelected);
    const access = useSelector((state) => state.userinfo.user.access);

    //#endregion
    //#region useEffect hooks
    useEffect(() => {
        dispatch(fetchUserInformation(false, controller));
        return () => {
            controller.current.abort();
        };
    }, [dispatch]);

    //#endregion

    return access ? (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav>
                    {access && access.home && (
                        <div className="nav-item">
                            <NavLinkRefresh
                                activeClassName="active"
                                className={
                                    'nav-link ' + (menuSelected === MENU_HOME ? 'active' : '')
                                }
                                to={DASHBOARD}>
                                Home
                            </NavLinkRefresh>
                        </div>
                    )}
                    {access && access.status && (
                        <NavDropdown
                            title="Status"
                            className={menuSelected === MENU_STATUS ? 'active' : ''}
                            id="reports-dropdown"
                            renderMenuOnMount={true}>
                            {access && access.status.delivery && (
                                <NavLink
                                    activeClassName="active"
                                    className="dropdown-item"
                                    to={STATUS_DELIVERY}>
                                    Delivery
                                </NavLink>
                            )}
                            {access && access.status.trips && (
                                <NavLink
                                    activeClassName="active"
                                    className="dropdown-item"
                                    to={STATUS_TRIPS}>
                                    Trips
                                </NavLink>
                            )}
                        </NavDropdown>
                    )}
                    {access && access.reports && (
                        <NavDropdown
                            title="Reports"
                            id="reports-dropdown"
                            renderMenuOnMount={true}
                            className={menuSelected === MENU_REPORTS ? 'active' : ''}>
                            {access && access.reports['unscheduled-and-pickup'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={REPORTS_UNSCHEDULED_DELIVERIES}
                                    activeClassName="active">
                                    Unscheduled Deliveries / Pickups
                                </NavLink>
                            )}
                            {access && access.reports['print-load'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={REPORTS_PRINT_LOAD}
                                    activeClassName="active">
                                    Print Load
                                </NavLink>
                            )}
                            {access && access.reports['exactload-discrepancy'] && (
                                <NavLink
                                    className="dropdown-item"
                                    activeClassName="active"
                                    to={REPORTS_EXACTLOAD_DISCREPANCY}>
                                    Exactload Discrepancy
                                </NavLink>
                            )}
                            {access && access.reports['delivery-efficiency'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={REPORTS_DELIVERY_EFFICIENCY}
                                    activeClassName="active">
                                    Delivery Efficiency
                                </NavLink>
                            )}
                            {access && access.reports['route-status'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={REPORTS_ROUTE_STATUS}
                                    activeClassName="active">
                                    Route Status
                                </NavLink>
                            )}
                            {access && access.reports['pva-report'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={REPORTS_PLANNED_VS_ACTUAL_OVERVIEW}
                                    activeClassName="active">
                                    Planned vs. Actual Overview
                                </NavLink>
                            )}
                            {access && access.reports['pva-detail'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={REPORTS_PLANNED_VS_ACTUAL_DETAIL}
                                    activeClassName="active">
                                    Planned vs. Actual Detail
                                </NavLink>
                            )}
                        </NavDropdown>
                    )}
                    {access && access.maintenance && (
                        <NavDropdown
                            title="Maintenance"
                            className={menuSelected === MENU_MAINTENANCE ? 'active' : ''}
                            id="maintenance-dropdown"
                            renderMenuOnMount={true}>
                            {access && access.maintenance.sites && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MAINTENANCE_SITES}
                                    activeClassName="active">
                                    Sites
                                </NavLink>
                            )}
                            {access && access.maintenance.routes && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MAINTENANCE_ROUTES}
                                    activeClassName="active">
                                    Routes
                                </NavLink>
                            )}
                            {access && access.maintenance.users && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MAINTENANCE_USERS}
                                    activeClassName="active">
                                    Users
                                </NavLink>
                            )}
                            {access && access.maintenance['delivery-status'] && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MAINTENANCE_DELIVERY}
                                    activeClassName="active">
                                    Delivery Status Links
                                </NavLink>
                            )}
                            {access && access.maintenance.products && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MAINTENANCE_PRODUCTS}
                                    activeClassName="active">
                                    Products
                                </NavLink>
                            )}
                            {access && access.maintenance.account && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MAINTENANCE_ACCOUNT}
                                    activeClassName="active">
                                    Account
                                </NavLink>
                            )}
                        </NavDropdown>
                    )}
                    {access && access.manifest && (
                        <NavDropdown
                            title="Manifest"
                            id="manifest-dropdown"
                            renderMenuOnMount={true}
                            className={menuSelected === MENU_MANIFEST ? 'active' : ''}>
                            {access && access.manifest.import && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MANIFEST_IMPORT}
                                    activeClassName="active">
                                    Import
                                </NavLink>
                            )}

                            {access && access.manifest.edit && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MANIFEST_EDIT}
                                    activeClassName="active">
                                    Edit
                                </NavLink>
                            )}
                            {access && access.manifest.create && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MANIFEST_CREATE}
                                    activeClassName="active">
                                    Create
                                </NavLink>
                            )}
                            {access && access.manifest.fleet && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MANIFEST_FLEET}
                                    activeClassName="active">
                                    Fleet
                                </NavLink>
                            )}
                            {access && access.manifest.order && (
                                <NavLink
                                    className="dropdown-item"
                                    to={MANIFEST_ORDER}
                                    activeClassName="active">
                                    Order
                                </NavLink>
                            )}
                        </NavDropdown>
                    )}
                    {access && access.support && (
                        <div className="nav-item">
                            <NavLink
                                to={SUPPORT}
                                activeClassName="active"
                                className={
                                    'nav-link ' + (menuSelected === MENU_SUPPORT ? 'active' : '')
                                }>
                                Support
                            </NavLink>
                        </div>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    ) : (
        <Skeleton
            baseColor="#444"
            width="100%"
            highlightColor="#ee3741"
            height={'30px'}
            style={{ borderRadius: 0 }}
        />
    );
};
NavMenu.propTypes = {
    location: PropTypes.object,
};
export default NavMenu;
