import React from 'react';
import logoJT from 'assets/images/jumptrack-logo-full.png';
import logoKarmak from 'assets/images/karmak/karmak_logo.png';
import { useSelector } from 'react-redux';
import { JUMPTRACK_THEME, KARMAK_THEME } from 'themes/CONSTANTS';
import { HOME_NAVIGATOR } from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom';

const MainLogo = () => {
    const selectedTheme = useSelector((state) => state.theme.currentTheme);
    return (
        <Link to={HOME_NAVIGATOR} className="logo-link">
            {selectedTheme.id === JUMPTRACK_THEME && (
                <img src={logoJT} className="logo" alt="ECI | Jumptrack" />
            )}
            {selectedTheme.id === KARMAK_THEME && (
                <img src={logoKarmak} className="logo" alt="Karmak | Deliver-it" />
            )}
        </Link>
    );
};
export default MainLogo;
