export const DeliveryType = {
    INVOICE: {
        id: 'INVOICE',
        name: 'Delivery',
        valueForReport: 'delivery',
        nameForStatusPage: 'Delivery',
    },
    RETURN: {
        id: 'RETURN',
        name: 'Return',
        valueForReport: 'pickup',
        nameForStatusPage: 'Pickup',
    },
};
