import React, { useEffect, useRef } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import {
    ADMIN_AFFILIATES,
    ADMIN_AFFILIATE_DETAIL_CREATE,
    ADMIN_USERS,
    ADMIN_AFFILIATE_EDIT,
    ADMIN_MESSAGING,
    MENU_ADMINISTRATION,
    ADMIN_USER_FORM,
    ADMIN_PARTNER_API_KEYS,
    ADMIN_PARTNER_API_KEY_DETAIL,
} from 'navigation/CONSTANTS';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInformation } from 'redux/actions/userActions';

const AdminNavMenu = () => {
    const dispatch = useDispatch();
    const fetchUserInfoController = useRef(new AbortController());
    //#region selector hooks
    const menuSelected = useSelector((state) => state.menu.menuSelected);
    //#endregion
    //#region useEffect hooks
    useEffect(() => {
        dispatch(fetchUserInformation(false, fetchUserInfoController));
        return () => {
            fetchUserInfoController.current.abort();
        };
    }, [dispatch]);
    //#endregion

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <NavDropdown
                        title="Administration"
                        className={menuSelected === MENU_ADMINISTRATION ? 'active' : ''}
                        id="reports-dropdown"
                        renderMenuOnMount={true}>
                        <NavLink
                            activeClassName="active"
                            to={ADMIN_AFFILIATES}
                            className="dropdown-item">
                            Affiliates
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_AFFILIATE_DETAIL_CREATE}
                            activeClassName="active">
                            Add Affiliate
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_AFFILIATE_EDIT}
                            activeClassName="active">
                            Edit Affiliate
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_MESSAGING}
                            activeClassName="active">
                            Messaging
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_USERS}
                            activeClassName="active">
                            Users
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_USER_FORM}
                            activeClassName="active">
                            Administrators
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_PARTNER_API_KEYS}
                            activeClassName="active">
                            API Keys
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to={ADMIN_PARTNER_API_KEY_DETAIL}
                            activeClassName="active">
                            Create API Key
                        </NavLink>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
export default AdminNavMenu;
