import Header from 'components/Header';
import React, { useEffect } from 'react';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { House } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { HOME_NAVIGATOR } from './CONSTANTS';
import ContactText from 'components/ContactText';
import { useSelector } from 'react-redux';
import accessDeniedImg from 'assets/images/access-denied.svg';
import { removeSessionStorage } from 'utils/storage/SessionStorageHelper';
import { LAST_LOCATION_BROWSED_KEY } from 'utils/storage/CONSTANTS';

const NotAllowed = () => {
    const selectedTheme = useSelector((state) => state.theme.currentTheme);
    useEffect(() => {
        document.title = 'JumpTrack | Access Denied';
        removeSessionStorage(LAST_LOCATION_BROWSED_KEY);
    }, []);
    return (
        <>
            <Header />
            <Container fluid>
                <Row>
                    <Col>
                        <div>
                            <Row className="mt-3">
                                <Col>
                                    <Breadcrumb>
                                        <li className="breadcrumb-item">
                                            <Link to={HOME_NAVIGATOR}>
                                                <House />
                                            </Link>
                                        </li>
                                        <Breadcrumb.Item active>Access Denied</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-center">
                                        <img src={accessDeniedImg} alt="Access Denied" />
                                    </div>
                                    <h2 className="text-center">Access Denied</h2>
                                    <div className="text-center">
                                        You do not have permission to access this page.
                                        <br /> Please contact the administration to obtain access.
                                    </div>
                                    <div className="mt-5 mb-3 text-center techsupport">
                                        {!selectedTheme.data.indexSupportEmail ? (
                                            <p>
                                                Technical support can be reached at:
                                                <br />
                                            </p>
                                        ) : (
                                            <>
                                                <div>Technical support can be reached at:</div>
                                                <span>
                                                    <ContactText
                                                        indexSupportEmail={
                                                            selectedTheme.data.indexSupportEmail
                                                        }
                                                        supportPhoneNumber={
                                                            selectedTheme.data.supportPhoneNumber
                                                        }
                                                        brandingSSL={selectedTheme.data.brandingSSL}
                                                    />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default NotAllowed;
