import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
const NavLinkRefresh = ({ to, exact, activeClassName, className, children }) => {
    const location = useLocation();

    return (
        <>
            {to === location.pathname ? (
                <a href={to} className={className + ' ' + activeClassName}>
                    {children}
                </a>
            ) : (
                <NavLink
                    activeClassName={activeClassName}
                    className={className}
                    to={to}
                    exact={exact}>
                    {children}
                </NavLink>
            )}
        </>
    );
};
NavLinkRefresh.propTypes = {
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    activeClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default NavLinkRefresh;
