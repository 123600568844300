export const ALL_THEMES_KEY = 'all-themes';
export const CURRENT_THEME_KEY = 'THEME';
export const BACK_URL_KEY = 'backUrl';
export const SECONDARY_LOGIN_KEY = 'LOGIN2';
export const LOGIN_KEY = 'LOGIN';
export const LAST_USER_KEY = 'LAST_USER';
export const STYLE_RECENT_DELIVERIES_HEIGHT_KEY = 'styleRecentDeliveries';
export const STYLE_REMAINING_DELIVERIES_HEIGHT_KEY = 'styleRemainingDeliveries';
export const SELECTED_CUSTOMER_KEY = 'selectedCustomer';
export const SELECTED_INVOICE_NUMBER_KEY = 'selectedInvoiceNumber';
export const SELECTED_USER_MAIL_KEY = 'selectedUserMail';
export const SELECTED_START_DATE_KEY = 'selectedStartDate';
export const SELECTED_END_DATE_KEY = 'selectedEndDate';
export const SELECTED_LEG_KEY = 'selectedLegKey';
export const SELECTED_STOP_KEY = 'selectedStopKey';
export const SELECTED_SITE_KEY = 'selectedSiteKey';
export const SELECTED_TRIP_DATE_KEY = 'selectedTripDate';
export const SELECTED_TRIP_KEY = 'selectedTripKey';
export const SELECTED_REGION_FOR_SEARCH_KEY = 'selectedRegionForSearch';
export const SELECTED_DIST_FOR_SEARCH_KEY = 'selectedDistForSearch';
export const SELECTED_ROUTE_FOR_SEARCH_KEY = 'selectedRouteForSearch';
export const UPLOADED_SITE_FILENAME_KEY = 'uploadedSiteFilename';
export const UPLOADED_USER_FILENAME_KEY = 'uploadedUserFilename';
export const UPLOADED_ROUTES_FILENAME_KEY = 'uploadedRoutesFilename';
export const PRINT_DELIVERY_ID_KEY = 'printDeliveryId';
export const PRINT_ACCOUNT_KEY = 'printAccount';
export const PRINT_VENDOR_ID_KEY = 'printVendorId';
export const PRINT_DISPLAY_CD_KEY = 'printDisplayCd';
export const PRINT_SHOW_BIGGER_KEY = 'printShowBigger';
export const GLOBAL_REGION_SELECTED_KEY = 'globalRegionSelected';
export const GLOBAL_REGION_VALIDATION_KEY = 'globalRegionValidation';
export const GLOBAL_DISTRIBUTION_SELECTED_KEY = 'globalDistributionSelected';
export const GLOBAL_DISTRIBUTION_VALIDATION_KEY = 'globalDistributionValidation';
export const GLOBAL_ROUTE_SELECTED_KEY = 'globalRouteSelected';
export const GLOBAL_ROUTE_VALIDATION_KEY = 'globalRouteValidation';
export const GLOBAL_IGNORE_ALL_KEY = 'globalIgnoreAll';
export const SELECTED_REGION_KEY = 'selectedRegionKey';
export const SELECTED_REGION_NAME = 'selectedRegionName';
export const SELECTED_DISTRIBUTION_CENTER_KEY = 'selectedDCKey';
export const SELECTED_DISTRIBUTION_CENTER_NAME = 'selectedDCName';
export const LOGIN_ERROR_MSG = 'loginErrorMsg';
export const TOTP_SECRET_KEY = 'totpSecret';
export const TOTP_QR_URL_KEY = 'totpQrUrl';
export const SELECTED_ROUTE_FOR_TRIP_KEY = 'selectedRouteForTrip';
export const SELECTED_DATE_FOR_TRIP_KEY = 'selectedDateForTrip';
export const RECENT_DELIVERIES_PAGE_SIZE_KEY = 'table-recent-deliveries';
export const HIDE_AFFILIATES_WITH_NO_ROUTES_KEY = 'hideAffiliateWithNoRoutes';
export const LAST_LOCATION_BROWSED_KEY = 'lastLocationBrowsed';
export const LAST_LOGIN_ATTEMPT_KEY = 'atKey';
export const LOGIN_WAIT_TIME_KEY = 'wtkey';
export const LOCK_ALLOWED_KEY = 'isLocked';
