import {
    DELIVERY_DETAIL_REDUCER_SHOW_MODAL,
    DELIVERY_DETAIL_REDUCER_HIDE_MODAL,
} from 'redux/CONSTANTS';

let initialData = {
    showModal: false,
    lines: [],
};

const deliveryDetailReducer = (state = initialData, action) => {
    switch (action.type) {
        case DELIVERY_DETAIL_REDUCER_SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                lines: action.lines,
            };
        case DELIVERY_DETAIL_REDUCER_HIDE_MODAL:
            return {
                ...state,
                showModal: false,
                lines: [],
            };
        default:
            return state;
    }
};
export default deliveryDetailReducer;
