import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';
import {
    GET_FLEET_OPTIMIZATION_LIMIT,
    GET_FLEET_DISTRIBUTION_ASSET,
    GET_FLEET_DISTRIBUTION_STOPS,
    POST_FLEET_OPTIMIZATION,
    POST_FLEET_SCHEDULE,
} from './CONSTANTS';

/**
 *
 * @param {String} distKey Distribution center id
 * @returns Json array with results
 */
export const getOptimizationLimits = (distKey, controller) => {
    console.log('customerLookupServices > getOptimizationLimits called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_FLEET_OPTIMIZATION_LIMIT}&distKey=${distKey}`, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getOptimizationLimits > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getOptimizationLimits > axios err=', err);
                doRejectValidation(err, reject, 'getOptimizationLimits');
            });
    });
};
/**
 *
 * @param {String} distKey Distribution center id
 * @param {String} scheduleDate Date to obtain assets from
 * @returns Json array with results
 */
export const getDistributionAsset = (distKey, scheduleDate, controller) => {
    console.log('fleetServices > getDistributionAsset called...');
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${GET_FLEET_DISTRIBUTION_ASSET}&distKey=${distKey}&scheduleDate=${scheduleDate}`,
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('getDistributionAsset > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getDistributionAsset > axios err=', err);
                doRejectValidation(err, reject, 'getDistributionAsset');
            });
    });
};
/**
 *
 * @param {String} distKey Distribution center id
 * @param {String} scheduleDate Date to obtain assets from
 * @param {Object} params parameters to post
 * @returns Json array with results
 */
export const postDistributionAsset = (distKey, scheduleDate, params, controller) => {
    console.log('fleetServices > postDistributionAsset called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${GET_FLEET_DISTRIBUTION_ASSET}&distKey=${distKey}&scheduleDate=${scheduleDate}`,
                params,
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postDistributionAsset > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postDistributionAsset > axios err=', err);
                doRejectValidation(err, reject, 'postDistributionAsset');
            });
    });
};
/**
 *
 * @param {String} distKey Distribution center id
 * @param {String} scheduleDate Date to obtain assets from
 * @returns Json array with results
 */
export const getDistributionStops = (distKey, scheduleDate, controller) => {
    console.log('fleetServices > getDistributionStops called...');
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${GET_FLEET_DISTRIBUTION_STOPS}&distKey=${distKey}&scheduleDate=${scheduleDate}`,
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('getDistributionStops > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getDistributionStops > axios err=', err);
                doRejectValidation(err, reject, 'getDistributionStops');
            });
    });
};
/**
 *
 * @param {String} distKey Distribution center id
 * @param {String} scheduleDate Date to obtain assets from
 * @param {String} params Fleet optimization parameters
 * @returns Json array with results
 */
export const getFleetOptimization = (distKey, scheduleDate, params, controller) => {
    console.log('fleetServices > getFleetOptimization called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_FLEET_OPTIMIZATION}&distKey=${distKey}&scheduleDate=${scheduleDate}`,
                params,
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('getFleetOptimization > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getFleetOptimization > axios err=', err);
                doRejectValidation(err, reject, 'getFleetOptimization');
            });
    });
};
/**
 *
 * @param {String} distKey Distribution center id
 * @param {String} scheduleDate Date to obtain assets from
 * @param {String} params Fleet optimization parameters
 * @returns Json array with results
 */
export const postFleetSchedule = (distKey, scheduleDate, params, controller) => {
    console.log('fleetServices > postFleetSchedule called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_FLEET_SCHEDULE}&distKey=${distKey}&scheduleDate=${scheduleDate}`,
                params,
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postFleetSchedule > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postFleetSchedule > axios err=', err);
                doRejectValidation(err, reject, 'postFleetSchedule');
            });
    });
};
