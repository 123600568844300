/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 *
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 *
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

// Actual endpoints. Uncomment below section to use actual data.
export const POST_LOGIN = `${process.env.REACT_APP_BASE_URL}login`;
export const POST_LOGOUT = `${process.env.REACT_APP_BASE_URL}logout`;
export const POST_GET_USER_INFO = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&v=11&type=user-info`;
export const POST_SYNC_WORKER = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=sync-worker`;
export const POST_REGION_LIST = process.env.REACT_APP_RHJTQUIET;
export const POST_ROUTES_LIST = process.env.REACT_APP_RHJTQUIET;
export const POST_DISTRIBUTION_CENTERS_LIST = process.env.REACT_APP_RHJTQUIET;
export const POST_DISTRIBUTION_CENTER_BY_ID = process.env.REACT_APP_RHJTQUIET;
export const POST_SAVE_DISTRIBUTION_CENTER = process.env.REACT_APP_RHJTQUIET;
export const POST_SAVE_REGION = process.env.REACT_APP_RHJTQUIET;
export const GET_AUTOCOMPLETE_CUSTOMER_NAME =
    process.env.REACT_APP_RHWWW_URL + '?format=RAW&type=customer-lookup';
export const GET_STOP_RECENT_URL = `${process.env.REACT_APP_RHWWW_URL}?type=stop-recent&format=RAW`;
export const POST_DELIVERY_STOP_SEARCH = process.env.REACT_APP_RHJTQUIET;
export const GET_REMAINING_DELIVERY = `${process.env.REACT_APP_RHWWW_URL}?type=remaining-delivery&format=RAW`;
export const POST_LIST_DELIVERY_STATUS = `${process.env.REACT_APP_RHWWW_URL}?type=delivery-status&format=RAW`;
export const POST_LIST_DELIVERY_STATUS_CSV = `${process.env.REACT_APP_RHWWW_URL}?type=delivery-status-csv&format=RAW`;
export const POST_DELIVERY_INFO_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=delivery-info`;
export const GET_AUTOCOMPLETE_DRIVER_SEARCH = `${process.env.REACT_APP_RHJT}?format=RAW&type=jumptrack-report-json&name=TRACK_DISTRIBUTION_DRIVER`;
export const GET_ROUTE_COLOR_URL = `${process.env.REACT_APP_RHWWW_URL}?type=route-color&format=RAW`;
export const GET_TRACK_USER_LIST = process.env.REACT_APP_RHJTQUIET;
export const GET_DISTRIBUTION_LIST_JSON_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=json-lookup&name=TRACK_DISTRIBUTION_LIST`;
export const GET_USER_DATA_URL = process.env.REACT_APP_RHJTQUIET;
export const POST_SAVE_USER_URL = process.env.REACT_APP_RHJT;
export const GET_DISTRIBUTION_USER_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=distribution-user`;
export const POST_PRODUCTS_FILE_URL = `${process.env.REACT_APP_RHWWW_URL}?format=FILE_WEB&type=file-upload&name=TRACK_PRODUCTS_UPLOAD`;
export const POST_MANIFEST_FILE_URL = `${process.env.REACT_APP_RHWWW_URL}?format=FILE_WEB&type=file-upload&name=TRACK_MANIFEST_UPLOAD`;
export const POST_SITES_FILE_URL = `${process.env.REACT_APP_RHWWW_URL}?format=FILE_WEB&type=file-upload&name=TRACK_SITE_UPLOAD`;
export const POST_USERS_FILE_URL = `${process.env.REACT_APP_RHWWW_URL}?format=FILE_WEB&type=file-upload&name=TRACK_USERS_UPLOAD`;
export const POST_ROUTES_FILE_URL = `${process.env.REACT_APP_RHWWW_URL}?format=FILE_WEB&type=file-upload&name=TRACK_ROUTES_UPLOAD`;

export const POST_WEBUPLOADER_QUERY_URL = process.env.REACT_APP_RHJTQUIET;
export const POST_ACCOUNT_QUERY_URL = process.env.REACT_APP_RHJT;
export const POST_FETCH_SITE_CUSTOMER_NAME_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=site-lookup`;
export const POST_LOAD_SITE_DETAILS_URL = process.env.REACT_APP_RHJTQUIET;
export const POST_EMAIL_SUSCRIPTION_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=subscription-status`;
export const POST_EMAIL_SUSCRIPTION_INVITE_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=subscription-invite`;
export const POST_SITE_SAVE_URL = process.env.REACT_APP_RHJT;
export const POST_FETCH_ACCOUNT_LOOKUP_NAME_URL = `${process.env.REACT_APP_RHJTQUIET}?format=RAW&type=jumptrack-account-lookup-ac&searchType=name`;
export const POST_FETCH_ACCOUNT_LOOKUP_URL = `${process.env.REACT_APP_RHJTQUIET}?format=RAW&type=jumptrack-account-lookup-ac`;
export const POST_DELIVERY_SEARCH = process.env.REACT_APP_RHJTQUIET;
export const POST_MANIFEST_EDIT = process.env.REACT_APP_RHJT;
export const GET_DEMO_TABLE_DATA = 'https://api.tvmaze.com/search/shows?q=snow';
export const GET_FLEET_OPTIMIZATION_LIMIT = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=optimization-limit`;
export const GET_FLEET_DISTRIBUTION_ASSET = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=distribution-asset`;
export const GET_FLEET_DISTRIBUTION_STOPS = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=distribution-stop`;
export const POST_FLEET_OPTIMIZATION = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=fleet-optimization`;
export const POST_FLEET_STOP_WINDOW = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=stop-window`;
export const POST_FLEET_SCHEDULE = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=fleet-schedule`;
export const POST_UPLOAD_CSV = process.env.REACT_APP_RHJUMPTECH;
export const POST_DELIVERY_UNSCHEDULED_AND_RETURN = process.env.REACT_APP_RHJTQUIET;
export const POST_PLANNED_VS_ACTUAL_OVERVIEW = process.env.REACT_APP_RHJTQUIET;
export const POST_PVA_DETAIL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=pva-detail`;
export const POST_LOADING_REPORT = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=loading`;
export const POST_REPLAN = process.env.REACT_APP_RHJT;
export const POST_OPTIMIZE = process.env.REACT_APP_RHJT;
export const POST_QUERY_ROUTE_CRUMBS = process.env.REACT_APP_RHJT;
export const POST_QUERY_TRIP_ROUTE = process.env.REACT_APP_RHJT;
export const GET_LOADERS_LIST = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=loaders`;
export const POST_DISCREPANCY_REPORT = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=loaded`;
export const POST_STOP_PENDING = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=stop-pending`;
export const POST_STOP_ORDER = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=stop-order`;
export const POST_STOP_OPTIMIZE = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=stop-optimize`;
export const GET_ROUTES_BY_DAY = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=route-day`;
export const GET_ROUTE_TRAIL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=route-trail`;
export const GET_ICONS_URL = `${process.env.REACT_APP_RHJUMPTECH}?format=RAW&type=icon`;
export const GET_ADMIN_FLEET_OPTIMIZATION_LIMIT = `${process.env.REACT_APP_RHJUMPTECH}?format=RAW&type=admin-optimization-limit`;
export const POST_TRIP_LEG_INFO_URL = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=trip-leg-info`;
export const GET_ADMIN_AFFILIATE_LIST = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-affiliate-list`;
export const GET_ADMIN_USER_LIST = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-user-list`;
export const GET_ADMIN_AFFILIATE_EDIT = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-affiliate-edit`;
export const GET_POST_DELETE_MESSAGING = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-messaging`;
export const GET_HOMEPAGE_MESSAGING = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=homepage-messaging`;
export const POST_SEND_EMAIL_VALIDATION = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=password-reset`;
export const POST_RESET_PASSWORD = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=password-reset`;
export const DELETE_ADMIN_AFFILIATE = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-affiliate-delete`;
export const POST_LOGIN_AS = `${process.env.REACT_APP_BASE_URL}loginas`;
export const POST_LOGOUT_AS = `${process.env.REACT_APP_BASE_URL}loginas/logout`;
export const POST_SEND_EMAIL = process.env.REACT_APP_RHJTQUIET;
export const POST_PRINT_STATUS = process.env.REACT_APP_RHJTQUIET;
export const GET_ADMIN_USER_EDIT = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-user-edit`;
export const POST_TOTP_RESET = `${process.env.REACT_APP_RHJUMPTECH}?format=RAW&type=admin-totp-reset`;
export const ROUTES_REPORT_DOWNLOAD = `${process.env.REACT_APP_RHJTQUIET}?format=RAW&type=jumptrack-report&name=TRACK_ROUTE_MAINTENANCE&report=true`;
export const USERS_REPORT_DOWNLOAD = `${process.env.REACT_APP_RHJTQUIET}?format=RAW&type=jumptrack-report&name=TRACK_USER_MAINTENANCE&report=true`;
export const GET_USER_MESSAGES = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&v=11&type=user-message`;
export const GET_MOBILE_INFO = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&v=11&type=mobile-info`;
export const POST_GET_TOTP = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=totp`;
export const POST_SUBSCRIBE = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=subscribe`;
export const GET_ADMIN_PARTNER_APIKEY_LIST = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-apikeys-list`;
export const DELETE_PARTNER_API_KEY = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-apikeys-detail`;
export const GET_PARTNER_API_KEY = `${process.env.REACT_APP_RHWWW_URL}?format=RAW&type=admin-apikeys-detail`;
export const GET_PARTNER_IPS = `${process.env.REACT_APP_RHJUMPTECH}?format=RAW&type=partner-ips`;
export const POST_ACCEPT_JUMPTRACK_EULA = `${process.env.REACT_APP_RHJT}?format=RAW&type=jumptrack-accept-user-license`;
export const POST_SYNC_WORKER_GET_MANIFEST_DATA = `${process.env.REACT_APP_RHJT}?type=jumppod-get-manifest-data`;
