import {
    ADMIN_LOGIN,
    DELIVERY_STATUS_VENDOR_LOGIN,
    LOGIN,
    PASSWORD_RESET,
    PASSWORD_RESET_TOKEN,
    ROOT,
    STATUS_PRINT,
    SUBSCRIBE,
    TOTP,
    UNSUBSCRIBE,
} from 'navigation/CONSTANTS';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import MainLogo from './MainLogo';

const PageLoading = () => {
    const location = useLocation();

    const publicRoutes = [
        ROOT,
        LOGIN,
        ADMIN_LOGIN,
        DELIVERY_STATUS_VENDOR_LOGIN,
        PASSWORD_RESET_TOKEN,
        PASSWORD_RESET,
        TOTP,
        SUBSCRIBE,
        UNSUBSCRIBE,
        STATUS_PRINT,
    ];
    return (
        <>
            {publicRoutes.includes(location.pathname) ? (
                <div>Loading...</div>
            ) : (
                <div className="header">
                    <div className="header-top container-fluid">
                        <Row>
                            <Col sm={6}>
                                <MainLogo />
                            </Col>
                            <Col sm={6}>
                                <Skeleton width="200px" style={{ float: 'right', marginTop: 10 }} />
                            </Col>
                        </Row>
                    </div>
                    <div className="header-bottom">
                        <Skeleton
                            baseColor="#444"
                            highlightColor="#ee3741"
                            width="100%"
                            style={{ borderRadius: 0 }}
                            height={'30px'}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default PageLoading;
