import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/bootstrap-theme.scss';
import { Provider } from 'react-redux';
import themes from './themes/schema.json';
import { setLocalStorage } from './utils/storage/LocalStorageHelper';
import { ALL_THEMES_KEY } from 'utils/storage/CONSTANTS';
import { setupStore } from 'redux/store';

const Index = () => {
    setLocalStorage(ALL_THEMES_KEY, themes.data, true);
    return (
        <React.StrictMode>
            <Provider store={setupStore()}>
                <App />
            </Provider>
        </React.StrictMode>
    );
};
ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
