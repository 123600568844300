import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

/**
 * Function to post file upload data
 */
export const sendFileuploadData = (uploadUrl, file, controller) => {
    console.log('fileUploadServices > sendFileuploadData called...');
    return new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.append('file', file);
        axios
            .post(uploadUrl, formData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('sendFileuploadData > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('sendFileuploadData > axios err=', err);
                doRejectValidation(err, reject, 'sendFileuploadData');
            });
    });
};
