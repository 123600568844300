import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

import { GET_AUTOCOMPLETE_CUSTOMER_NAME } from './CONSTANTS';
/**
 *

 * @param {String} term term to autocomplete
 * @returns Json array with results
 */
export const getCustomerNameLookup = (term, controller) => {
    console.log('customerLookupServices > getCustomerNameLookup called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_AUTOCOMPLETE_CUSTOMER_NAME}&term=${term}`, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getCustomerNameLookup > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getCustomerNameLookup > axios err=', err);
                doRejectValidation(err, reject, 'getCustomerNameLookup');
            });
    });
};
