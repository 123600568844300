import axios from 'axios';

import {
    doRejectValidation,
    escapeXml,
    parseXmlResponseDataList,
    parseXmlResult,
} from 'utils/JTUtils';

import {
    POST_DISTRIBUTION_CENTER_BY_ID,
    POST_DISTRIBUTION_CENTERS_LIST,
    POST_ROUTES_LIST,
    POST_SAVE_DISTRIBUTION_CENTER,
    POST_SAVE_REGION,
    POST_REGION_LIST,
    POST_FETCH_SITE_CUSTOMER_NAME_URL,
} from './CONSTANTS';

/**
 * Get region list for user

 * @returns Region list as XML
 */
export const getRegionList = (controller) => {
    console.log('siteLookupServices > getRegionList called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData =
            '<request type="jumptrack-lookup"><q name="TRACK_REGION_LIST"/></request>';
        axios
            .post(POST_REGION_LIST, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getRegionList > axios res=', res);
                resolve(parseXmlResponseDataList(res.data));
            })
            .catch((err) => {
                console.log('getRegionList > axios err=', err);
                doRejectValidation(err, reject, 'getRegionList');
            });
    });
};
/**
 * Get routes list by distribution center
 * @param {String} distributionCenterId Distribution center id for filtering
 * @returns Routes list as XML
 */
export const getRoutesList = (distributionCenterId, controller) => {
    console.log('siteLookupServices > getRoutesList called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_ROUTE_LIST"><p>${distributionCenterId}</p></q></request>`;
        axios
            .post(POST_ROUTES_LIST, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getRoutesList > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('getRoutesList > axios err=', err);
                doRejectValidation(err, reject, 'getRoutesList');
            });
    });
};
/**
 * Get Distribution center list by Region Id
 * @param {String} regionId region Id for filtering
 * @returns Distribution center list as XML
 */
export const getDistributionCentersList = (regionId, controller) => {
    console.log('siteLookupServices > getDistributionCentersList called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_DISTRIBUTION_LIST"><p>${regionId}</p></q></request>`;
        axios
            .post(POST_DISTRIBUTION_CENTERS_LIST, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getDistributionCentersList > axios res=', res);
                resolve(parseXmlResponseDataList(res.data));
            })
            .catch((err) => {
                console.log('getDistributionCentersList > axios err=', err);
                doRejectValidation(err, reject, 'getDistributionCentersList');
            });
    });
};
/**
 * Gets distribution center as XML
 * @param {String} distributionCenterId Distribution center Id to get the object
 * @returns Distribution center object as XML
 */
export const getDistributionCenterById = (distributionCenterId, controller) => {
    console.log('siteLookupServices > getDistributionCenter called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_DISTRIBUTION" key="${distributionCenterId}"></q></request>`;
        axios
            .post(POST_DISTRIBUTION_CENTER_BY_ID, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getDistributionCenter > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('getDistributionCenter > axios err=', err);
                doRejectValidation(err, reject, 'getDistributionCenter');
            });
    });
};
/**
 * Gets route as XML
 * @param {String} routeId Route Id to get the object
 * @returns Route object as XML
 */
export const getRouteById = (routeId, controller) => {
    console.log('siteLookupServices > getRouteById called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_ROUTE" key="${routeId}"></q></request>`;
        axios
            .post(POST_DISTRIBUTION_CENTER_BY_ID, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getRouteById > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('getRouteById > axios err=', err);
                doRejectValidation(err, reject, 'getRouteById');
            });
    });
};

/**
 * Post distribution center for save / update
 * @param {String} params params for distribution center save
 * @returns Distribution Center object as XML
 */
export const postSaveDistributionCenter = (params, controller) => {
    console.log('siteLookupServices > saveDistributionCenter called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-api-xml" version="1.2"><routes results="false"><region><name>${escapeXml(
            params.region.name
        )}</name><distribution enabled="${params.distribution.enabled}"><name>${escapeXml(
            params.distribution.name
        )}</name><rename>${escapeXml(params.distribution.rename)}</rename><address1>${escapeXml(
            params.distribution.address1
        )}</address1><address2>${escapeXml(
            params.distribution.address2
        )}</address2><city>${escapeXml(params.distribution.city)}</city><state>${escapeXml(
            params.distribution.state
        )}</state><zip>${escapeXml(
            params.distribution.zip
        )}</zip></distribution></region></routes></request>`;

        axios
            .post(POST_SAVE_DISTRIBUTION_CENTER, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('saveDistributionCenter > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('saveDistributionCenter > axios err=', err);
                doRejectValidation(err, reject, 'saveDistributionCenter');
            });
    });
};
/**
 * Post route for save / update
 * @param {String} params params for route save
 * @returns Route object as XML
 */
export const postSaveRoute = (params, controller) => {
    console.log('siteLookupServices > postSaveRoute called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-api-xml" version="1.2"><routes results="false"><region><name>${escapeXml(
            params.region.name
        )}</name><distribution><name>${escapeXml(
            params.distributionCenter.name
        )}</name><route enabled="${params.route.enabled}" clear-owner="${
            params.route.clearOwner
        }" rgbBackground="${params.route.rgbBackground}" assigned-driver="${
            params.route.assignedDriver
        }"><id>${escapeXml(params.route.routeId)}</id><name>${escapeXml(
            params.route.name
        )}</name><rename>${escapeXml(
            params.route.rename
        )}</rename></route></distribution></region></routes></request>`;
        axios
            .post(POST_SAVE_DISTRIBUTION_CENTER, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveRoute > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveRoute > axios err=', err);
                doRejectValidation(err, reject, 'postSaveRoute');
            });
    });
};

/**
 * Post distribution center for save / update
 * @param {String} params params for region save
 * @returns Distribution Center object as XML
 */
export const postSaveRegion = (params, controller) => {
    console.log('siteLookupServices > postSaveRegion called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-api-xml" version="1.2"><routes results="false"><region enabled="${
            params.region.enabled
        }"><name>${escapeXml(params.region.name || '')}</name><rename>${escapeXml(
            params.region.rename
        )}</rename></region></routes></request>`;
        axios
            .post(POST_SAVE_REGION, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveRegion > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveRegion > axios err=', err);
                doRejectValidation(err, reject, 'postSaveRegion');
            });
    });
};
/**
 * Obtains a list of sites by the following parameters
 * @param {String} term term for searching sites by customer name
 * @param {String} searchType type of search based on crumbs
 * @returns Results array
 */
export const postFetchSiteCustomerNameList = (term, searchType, controller) => {
    console.log('siteLookupServices > postFetchSiteCustomerNameList called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_FETCH_SITE_CUSTOMER_NAME_URL}&term=${escapeXml(
                    term
                )}&searchType=${searchType}`,
                {},
                {
                    signal: controller.current.signal,
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postFetchSiteCustomerNameList > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postFetchSiteCustomerNameList > axios err=', err);
                doRejectValidation(err, reject, 'postFetchSiteCustomerNameList');
            });
    });
};
