import {
    AUTHENTICATION_FAILED_ERROR,
    COMMON_ERROR_TITLE,
    COMMON_SUCCESS_TITLE,
} from 'config/CONSTANTS';
import {
    SYSTEM_MESSAGES_REDUCER_SHOW_ERROR,
    SYSTEM_MESSAGES_REDUCER_HIDE,
    SYSTEM_MESSAGES_REDUCER_SHOW_INFO,
    EMPTY_ACTION,
} from 'redux/CONSTANTS';

export const showErrorMessage = ({ title, message, error }) => {
    if (
        title === AUTHENTICATION_FAILED_ERROR ||
        message === AUTHENTICATION_FAILED_ERROR ||
        error === AUTHENTICATION_FAILED_ERROR
    ) {
        return { type: EMPTY_ACTION };
    }
    if (message == null) {
        message = title;
        title = COMMON_ERROR_TITLE;
    }
    if (!title) {
        title = COMMON_ERROR_TITLE;
    }

    return {
        type: SYSTEM_MESSAGES_REDUCER_SHOW_ERROR,
        title: title,
        message: message,
    };
};
export const showInfoMessage = (title, message) => {
    if (message == null) {
        message = title;
        title = COMMON_SUCCESS_TITLE;
    }
    return {
        type: SYSTEM_MESSAGES_REDUCER_SHOW_INFO,
        title: title,
        message: message,
    };
};
export const hideNotificationMessage = (index) => {
    return {
        type: SYSTEM_MESSAGES_REDUCER_HIDE,
        index: index,
    };
};
