import {
    getLocalStorage,
    removeLocalStorage,
    setLocalStorage,
} from 'utils/storage/LocalStorageHelper';
import {
    ALL_THEMES_KEY,
    SECONDARY_LOGIN_KEY,
    LOGIN_KEY,
    GLOBAL_DISTRIBUTION_SELECTED_KEY,
    GLOBAL_ROUTE_SELECTED_KEY,
    GLOBAL_REGION_SELECTED_KEY,
    LAST_USER_KEY,
} from 'utils/storage/CONSTANTS';
import { USER_LOGGEDIN, USER_LOGGEDINAS, USER_LOGGEDOUT, USER_LOGGEDINAS_BACK } from '../CONSTANTS';

let initialData = {
    login: getLocalStorage(LOGIN_KEY),
    secondary_login: getLocalStorage(SECONDARY_LOGIN_KEY),
};

const loginReducer = (state = initialData, action) => {
    switch (action.type) {
        case USER_LOGGEDIN: {
            setLocalStorage(LOGIN_KEY, action.login);
            const lastUser = getLocalStorage(LAST_USER_KEY);
            if (lastUser && lastUser !== action.login) {
                removeLocalStorage(GLOBAL_DISTRIBUTION_SELECTED_KEY);
                removeLocalStorage(GLOBAL_ROUTE_SELECTED_KEY);
                removeLocalStorage(GLOBAL_REGION_SELECTED_KEY);
            }
            setLocalStorage(LAST_USER_KEY, action.login);
            return {
                ...state,
                login: action.login,
                secondary_login: '',
            };
        }
        case USER_LOGGEDOUT:
            removeLocalStorage(ALL_THEMES_KEY);
            removeLocalStorage(LOGIN_KEY);
            removeLocalStorage(SECONDARY_LOGIN_KEY);

            return {
                ...state,
                login: '',
                secondary_login: '',
            };
        case USER_LOGGEDINAS:
            setLocalStorage(SECONDARY_LOGIN_KEY, state.login);
            setLocalStorage(LOGIN_KEY, action.login);
            removeLocalStorage(GLOBAL_DISTRIBUTION_SELECTED_KEY);
            removeLocalStorage(GLOBAL_ROUTE_SELECTED_KEY);
            removeLocalStorage(GLOBAL_REGION_SELECTED_KEY);
            return {
                ...state,
                secondary_login: state.login,
                login: action.login,
            };
        case USER_LOGGEDINAS_BACK: {
            const secondaryLogin = state.secondary_login;
            setLocalStorage(LOGIN_KEY, secondaryLogin);
            removeLocalStorage(SECONDARY_LOGIN_KEY);
            removeLocalStorage(GLOBAL_DISTRIBUTION_SELECTED_KEY);
            removeLocalStorage(GLOBAL_ROUTE_SELECTED_KEY);
            removeLocalStorage(GLOBAL_REGION_SELECTED_KEY);
            return {
                ...state,
                login: state.secondary_login,
                secondary_login: '',
            };
        }
        default:
            return state;
    }
};
export default loginReducer;
