import {
    STATUSTRIPS_SET_ROUTE_LIST,
    STATUSTRIPS_SET_SELECTED_ROUTES,
    STATUSTRIPS_CHANGE_DATE,
    STATUSTRIPS_SELECT_TRIP,
    STATUSTRIPS_LOADCRUMBS,
    STATUSTRIPS_ADDROUTEKEY,
    STATUSTRIPS_DISTRIBUTIONCENTER,
    STATUSTRIPS_CLEAR,
} from '../CONSTANTS';

let initialData = {
    routeList: [],
    distributionCenterCachedFor: [],
    selectedDate: null,
    loadedRoutes: [],
    selectedTrips: {},
    routesForCrumbs: {},
    tripTrails: {},
    distributionCenterLoaded: null,
    tripJustAdded: null,
    routeJustAdded: null,
};

const statusTripsReducer = (state = initialData, action) => {
    switch (action.type) {
        case STATUSTRIPS_SET_ROUTE_LIST:
            return {
                ...state,
                routeList: action.value,
                distributionCenterCachedFor: action.distributionCenter,
            };
        case STATUSTRIPS_SET_SELECTED_ROUTES: {
            const newTripTrails = { ...state.tripTrails };
            const newTrips = { ...state.selectedTrips };
            const newRoutesForCrumbs = { ...state.routesForCrumbs };
            Object.keys(state.tripTrails).forEach((routeKey) => {
                const foundRoutes = action.routes.filter((item) => 'r' + item.key === routeKey);
                if (foundRoutes.length === 0) {
                    delete newTripTrails[routeKey];
                }
            });
            Object.keys(state.selectedTrips).forEach((routeKey) => {
                const foundRoutes = action.routes.filter((item) => 'r' + item.key === routeKey);
                if (foundRoutes.length === 0) {
                    delete newTrips[routeKey];
                }
            });
            Object.keys(state.routesForCrumbs).forEach((routeKey) => {
                const foundRoutes = action.routes.filter((item) => 'r' + item.key === routeKey);
                if (foundRoutes.length === 0) {
                    delete newRoutesForCrumbs[routeKey];
                }
            });
            return {
                ...state,
                loadedRoutes: action.routes,
                selectedDate: action.date,
                tripTrails: newTripTrails,
                selectedTrips: newTrips,
                routesForCrumbs: newRoutesForCrumbs,
            };
        }
        case STATUSTRIPS_CHANGE_DATE:
            return {
                ...state,
                selectedDate: action.date,
                loadedRoutes: [],
                selectedTrips: {},
                routesForCrumbs: {},
                tripTrails: {},
                tripJustAdded: null,
                routeJustAdded: null,
                routeList: [...state.routeList],
            };
        case STATUSTRIPS_SELECT_TRIP: {
            const newState = { ...state };
            const newRoutes = [...newState.loadedRoutes];

            const newTrips = { ...newState.selectedTrips };
            newTrips['r' + action.route.key] = {
                ...action.trip,
                routeColor: action.route.rgbBackground,
            };
            newState.selectedTrips = newTrips;
            newState.loadedRoutes = newRoutes;
            newState.tripJustAdded = action.trip;
            newState.routeJustAdded = action.route.key;
            return newState;
        }
        case STATUSTRIPS_LOADCRUMBS: {
            const newRoutesForCrumbs = { ...state.routesForCrumbs };
            newRoutesForCrumbs['r' + action.routeKey] = action.routes;
            return { ...state, routesForCrumbs: newRoutesForCrumbs };
        }
        case STATUSTRIPS_ADDROUTEKEY: {
            const newTripTrails = { ...state.tripTrails };
            newTripTrails['r' + action.routeKey] = action.trail;
            return { ...state, tripTrails: newTripTrails };
        }
        case STATUSTRIPS_DISTRIBUTIONCENTER: {
            const newState = { ...state };
            newState.distributionCenterLoaded = action.distributionCenter;
            newState.selectedTrips = {};
            newState.routesForCrumbs = {};
            newState.tripTrails = {};
            newState.tripJustAdded = null;
            newState.routeList = [];
            newState.routeJustAdded = null;
            return newState;
        }
        case STATUSTRIPS_CLEAR: {
            return initialData;
        }
        default:
            return state;
    }
};
export default statusTripsReducer;
