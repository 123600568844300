import { LOADING_REDUCER_HIDE_LOADING, LOADING_REDUCER_SHOW_LOADING } from 'redux/CONSTANTS';

let initialData = {
    loading: false,
    message: null,
};

const loadingReducer = (state = initialData, action) => {
    switch (action.type) {
        case LOADING_REDUCER_SHOW_LOADING:
            return {
                ...state,
                loading: true,
                message: action.message,
            };
        case LOADING_REDUCER_HIDE_LOADING:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export default loadingReducer;
