import axios from 'axios';
import { doRejectValidation, escapeXml, parseXmlResult } from 'utils/JTUtils';
import { POST_ACCOUNT_QUERY_URL, POST_SAVE_USER_URL, POST_SITE_SAVE_URL } from './CONSTANTS';

/**
 * Save user data
 * @param {*} params Save User Params
 * @returns Response Element from XML
 */
export const postSaveUser = ({
    enabled,
    changedEmail,
    email,
    firstname,
    lastname,
    password,
    employeeId,
    roles: {
        isAdminRole,
        isDispatcherRole,
        isDriverRole,
        isSchedulerRole,
        isViewerRole,
        isLoaderRole,
    },
    controller,
}) => {
    console.log('jumptrackApiXmlServices > postSaveUser called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-api-xml" version="WWW"><users results="false"><user enabled="${enabled}"><email>${escapeXml(
            email
        )}</email><email-rename>${escapeXml(changedEmail)}</email-rename><first>${escapeXml(
            firstname
        )}</first><last>${escapeXml(lastname)}</last><password>${escapeXml(
            password
        )}</password><employee-id>${escapeXml(
            employeeId
        )}</employee-id><role name="admin" enabled="${isAdminRole}"/><role name="dispatcher" enabled="${isDispatcherRole}"/><role name="driver" enabled="${isDriverRole}"/><role name="scheduler" enabled="${isSchedulerRole}"/><role name="viewer" enabled="${isViewerRole}"/><role name="loader" enabled="${isLoaderRole}"/></user></users></request>`;
        axios
            .post(`${POST_SAVE_USER_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveUser > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveUser > axios err=', err);
                doRejectValidation(err, reject, 'postSaveUser');
            });
    });
};
/**
 * Save user distribution center binding
 * @param {Array} regions region list
 * @param {String} email distribution center email
 * @returns Response element
 */
export const postSaveUserBinding = (regions, email, controller) => {
    console.log('jumptrackApiXmlServices > postSaveUserBinding called...');
    return new Promise((resolve, reject) => {
        let xmlRequestData =
            '<request type="jumptrack-api-xml" version="1.2"><routes results="false">';
        let regString = Object.entries(regions)
            .map((region) => {
                return (
                    '<region><name>' +
                    escapeXml(region[0]) +
                    '</name>' +
                    region[1]
                        .map(
                            (dist) =>
                                `<distribution><name>${escapeXml(dist.name)}</name><user binding="${
                                    dist.binding
                                }"><email enabled="true">${escapeXml(
                                    email
                                )}</email></user></distribution>`
                        )
                        .join('') +
                    '</region>'
                );
            })
            .join('');
        xmlRequestData += regString;
        xmlRequestData += '</routes></request>';
        axios
            .post(`${POST_SAVE_USER_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveUserBinding > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveUserBinding > axios err=', err);
                doRejectValidation(err, reject, 'postSaveUserBinding');
            });
    });
};
/**
 * Query Account data
 * @returns Array with results from XML
 */
export const postFetchAccountQuery = (controller) => {
    console.log('jumptrackApiXmlServices > postAccountQuery called...');
    return new Promise((resolve, reject) => {
        let xmlRequestData = `<request type="jumptrack-api-xml" version="1.3"><account result="true"/></request>`;
        axios
            .post(`${POST_ACCOUNT_QUERY_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveUserBinding > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveUserBinding > axios err=', err);
                doRejectValidation(err, reject, 'postSaveUserBinding');
            });
    });
};
/**
 * Saves account settings based on params
 * @param {*} params account settings params
 * @returns Response element
 */
export const postSaveAccountSettings = ({
    contactEmail,
    locationCountry,
    driver: { sortOrder, unscheduledDelivery, unscheduledReturns },
    photo: { max, shortPx },
    manifestDateRange,
    gpsEnabled,
    exactloadEnabled,
    geoFenceSize,
    reportThreshold,
    trip: { stopDuration, endMethod },
    stopNotifyEnabled,
    partialReasons,
    cleanLocation,
    disclaimer: { disclaimerEnabled, disclaimerText },
    controller,
}) => {
    console.log('jumptrackApiXmlServices > postSaveAccountSettings called...');
    return new Promise((resolve, reject) => {
        const parsedReasons = partialReasons
            .map((item) => `<reason>${escapeXml(item)}</reason>`)
            .join('');
        let cleanedLocation = cleanLocation ? '<city></city><state></state><zip></zip>' : '';
        let xmlRequestData = `<request type="jumptrack-api-xml" version="1.3"><account><contact>${cleanedLocation}<reply-to>${escapeXml(
            contactEmail
        )}</reply-to></contact><location country="${locationCountry}"/><driver sort-order="${sortOrder}" unscheduled-delivery="${unscheduledDelivery}" unscheduled-returns="${unscheduledReturns}"><photo max="${max}" short-px="${shortPx}"/></driver><manifest days-back="${manifestDateRange}"/><gps enabled="${gpsEnabled}"/><load enabled="${exactloadEnabled}"/><geo-fence size="${geoFenceSize}"/><report threashold="${reportThreshold}"/><trip stop-duration="${stopDuration}" end-method="${endMethod}"/><stop-notify email="${stopNotifyEnabled}"/><partial>${parsedReasons}</partial><disclaimer enabled="${disclaimerEnabled}" text="${escapeXml(
            disclaimerText
        )}"></disclaimer></account></request>`;
        axios
            .post(`${POST_ACCOUNT_QUERY_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveAccountSettings > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveAccountSettings > axios err=', err);
                doRejectValidation(err, reject, 'postSaveAccountSettings');
            });
    });
};
/**
 *
 * Saves site user based on params
 * @param {*} params site Params
 * @returns Response element
 */
export const postSaveSite = ({
    accountNo,
    companyName,
    address1,
    address2,
    address3,
    city,
    state,
    zip,
    lat,
    lng,
    unloadDefault,
    unloadDuration,
    autocompleteEnabled,
    geofenceDefault,
    geofenceRadius,
    email,
    phone,
    windowType,
    windowStart,
    windowEnd,
    isLicenseOptimizationFleet,
    schedulePriority,
    controller,
}) => {
    console.log('jumptrackApiXmlServices > postSaveSite called...');
    return new Promise((resolve, reject) => {
        let xmlRequestData = `<request type="jumptrack-api-xml" version="WWW">'<site lat="${
            lat ? lat : ''
        }" lng="${lng ? lng : ''}" unload-duration="${
            unloadDefault ? '' : unloadDuration
        }" autocomplete="${autocompleteEnabled}"><account>${escapeXml(
            accountNo
        )}</account><name>${escapeXml(companyName)}</name><address1>${escapeXml(
            address1
        )}</address1><address2>${escapeXml(address2)}</address2><address3>${escapeXml(
            address3
        )}</address3><city>${escapeXml(city)}</city><state>${escapeXml(
            state
        )}</state><zip>${escapeXml(zip)}</zip><geofence radius="${
            geofenceDefault ? '' : geofenceRadius
        }"/><window type="${windowType}" start="${windowStart ? windowStart : ''}" end="${
            windowEnd ? windowEnd : ''
        }" /><schedule ${
            isLicenseOptimizationFleet ? `priority="${schedulePriority}"` : ''
        } /><email>${escapeXml(email)}</email><phone>${escapeXml(phone)}</phone></site></request>`;
        axios
            .post(`${POST_SITE_SAVE_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveSite > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveSite > axios err=', err);
                doRejectValidation(err, reject, 'postSaveSite');
            });
    });
};
/**
 * Saves account info based on params
 * @param {*} params account info params
 * @returns Response element
 */
export const postSaveAccountInfo = ({
    firstName,
    lastName,
    companyName,
    address1,
    address2,
    city,
    state,
    zip,
    email,
    phone,
    mobile,
    controller,
}) => {
    console.log('jumptrackApiXmlServices > postSaveAccountInfo called...');
    return new Promise((resolve, reject) => {
        let xmlRequestData = `<request type="jumptrack-api-xml" version="1.3"><account><contact><first-name>${escapeXml(
            firstName
        )}</first-name><last-name>${escapeXml(lastName)}</last-name><company>${escapeXml(
            companyName
        )}</company><address1>${escapeXml(address1)}</address1><address2>${escapeXml(
            address2
        )}</address2><city>${escapeXml(city)}</city><state>${escapeXml(
            state
        )}</state><zip>${escapeXml(zip)}</zip><email>${escapeXml(email)}</email><phone>${escapeXml(
            phone
        )}</phone><mobile>${escapeXml(mobile)}</mobile></contact></account></request>`;
        axios
            .post(`${POST_ACCOUNT_QUERY_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveAccountInfo > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveAccountInfo > axios err=', err);
                doRejectValidation(err, reject, 'postSaveAccountInfo');
            });
    });
};
/**
 * Save manifest based on params
 * @param {object} params
 * @returns Manifest information
 */
export const postSaveManifestCreate = ({
    regionName,
    distributionName,
    routeName,
    sortOrder,
    deliveryDate,
    site: { accountNumber, siteName, address1, address2, address3, city, state, zip },
    delivery: { deliveryType, deliveryNumber, deliveryNotes, deliveryPo },
    products,
    isPackage,
    deliveryProducts,
    controller,
}) => {
    console.log('jumptrackApiXmlServices > postSaveManifestCreate called...');
    return new Promise((resolve, reject) => {
        let parsedProds = '';
        if (isPackage) {
            parsedProds = deliveryProducts
                .map(
                    (item) =>
                        `<delivery-line qty-target="${item.qty}"><name>${escapeXml(
                            item.name
                        )}</name></delivery-line>`
                )
                .join('');
        } else {
            parsedProds = products
                .map((item) => {
                    const licensePlate = item.licensePlate.split(',').map((item) => item.trim());
                    let plates = '';
                    licensePlate.forEach((item) => {
                        if (!item) {
                            return;
                        }
                        plates += `<license-plate><meta>${escapeXml(item)}</meta></license-plate>`;
                    });
                    return `<delivery-line qty-target="${escapeXml(item.qty)}"><name>${escapeXml(
                        item.name
                    )}</name><product-no>${escapeXml(item.no)}</product-no><uom>${escapeXml(
                        item.uom
                    )}</uom><desc>${escapeXml(item.desc)}</desc>${plates}<scan>${escapeXml(
                        item.productScan
                    )}</scan></delivery-line>`;
                })
                .join('');
        }
        const sortOrderParsed = sortOrder ? `sort-order="${sortOrder}"` : '';
        let deliveryId = '<id generate="SEQUENCE"></id>';
        if (deliveryNumber && deliveryNumber !== '') {
            deliveryId = `<id>${escapeXml(deliveryNumber)}</id>`;
        }
        let xmlRequestData = `<request type="jumptrack-api-xml" version="1.3.5"><manifest-no-cache/><manifest><route><region-name>${escapeXml(
            regionName
        )}</region-name><distribution-name>${escapeXml(
            distributionName
        )}</distribution-name><route-name>${escapeXml(
            routeName
        )}</route-name></route><stop ${sortOrderParsed}><stop-code/><delivery-date>${deliveryDate}</delivery-date><site><account>${escapeXml(
            accountNumber
        )}</account><name>${escapeXml(siteName)}</name><address1>${escapeXml(
            address1
        )}</address1><address2>${escapeXml(address2)}</address2><address3>${escapeXml(
            address3
        )}</address3><city>${escapeXml(city)}</city><state>${escapeXml(
            state
        )}</state><zip>${escapeXml(
            zip
        )}</zip></site><delivery type="${deliveryType}">${deliveryId}<display-cd/><delivery-po>${escapeXml(
            deliveryPo
        )}</delivery-po><delivery-note>${escapeXml(
            deliveryNotes
        )}</delivery-note>${parsedProds}</delivery></stop></manifest></request>`;
        axios
            .post(`${POST_ACCOUNT_QUERY_URL}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveManifestCreate > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveManifestCreate > axios err=', err);
                doRejectValidation(err, reject, 'postSaveManifestCreate');
            });
    });
};
