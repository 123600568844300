import React from 'react';
import PropTypes from 'prop-types';

const ContactText = ({ indexSupportEmail, supportPhoneNumber, brandingSSL }) => {
    return brandingSSL ? (
        <>
            USA:{' '}
            <a className="bluelink" href={'mailto:' + indexSupportEmail}>
                {indexSupportEmail}
            </a>{' '}
            or call us at {supportPhoneNumber} <br />
            UK:{' '}
            <a className="bluelink" href="https://customersupport.ecisolutions.com/">
                Customer Web Portal
            </a>{' '}
            or call us
            <br />
            at +44 (0) 333 123 0444{' '}
        </>
    ) : (
        <>
            <a className="bluelink" href={'mailto:' + indexSupportEmail}>
                {indexSupportEmail}
            </a>{' '}
            or call us
            <br />
            at {supportPhoneNumber} <br />
        </>
    );
};
ContactText.propTypes = {
    indexSupportEmail: PropTypes.string,
    supportPhoneNumber: PropTypes.string,
    brandingSSL: PropTypes.bool,
};
export default ContactText;
