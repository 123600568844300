export const setLocalStorage = (key, value, isJson) => {
    if (isJson) {
        window.localStorage.setItem(key, JSON.stringify(value));
    } else {
        window.localStorage.setItem(key, value);
    }
};

export const getLocalStorage = (key, isJson) => {
    const value = window.localStorage.getItem(key);

    if (value == null) {
        return null;
    }
    if (isJson) {
        return JSON.parse(value);
    }
    return value;
};
export const removeLocalStorage = (key) => {
    window.localStorage.removeItem(key);
};
