import {
    REGDISROUTE_SET_ROUTE_LIST,
    REGDISROUTE_SET_REGION_LIST,
    REGDISROUTE_SET_DISTRIBUTION_LIST,
    REGDISROUTE_SELECT_REGION,
    REGDISROUTE_SELECT_ROUTE,
    REGDISROUTE_SELECT_DISTRIBUTION_CENTER,
    REGDISTROUTE_CLEAN_SELECTION,
} from '../CONSTANTS';

export const routeListLoaded = (value, distributionCenter) => {
    return {
        type: REGDISROUTE_SET_ROUTE_LIST,
        value,
        distributionCenter,
    };
};
export const regionListLoaded = (value) => {
    return {
        type: REGDISROUTE_SET_REGION_LIST,
        value,
    };
};

export const distributionListLoaded = (value, region) => {
    return {
        type: REGDISROUTE_SET_DISTRIBUTION_LIST,
        value,
        region,
    };
};

export const selectRoute = (route) => {
    return {
        type: REGDISROUTE_SELECT_ROUTE,
        key: route.key,
        name: route.name,
    };
};
export const selectRegion = (region) => {
    return {
        type: REGDISROUTE_SELECT_REGION,
        key: region.key,
        name: region.name,
    };
};

export const selectDistributionCenter = (distCenter) => {
    return {
        type: REGDISROUTE_SELECT_DISTRIBUTION_CENTER,
        key: distCenter.key,
        name: distCenter.name,
    };
};
export const cleanRegDistRouteSelection = () => {
    return {
        type: REGDISTROUTE_CLEAN_SELECTION,
    };
};
