import { USER_LOGGEDIN, USER_LOGGEDOUT, USER_LOGGEDINAS, USER_LOGGEDINAS_BACK } from '../CONSTANTS';

export const userLoggedIn = (login) => {
    return {
        type: USER_LOGGEDIN,
        value: true,
        login,
    };
};
export const userLoggedOut = () => {
    return {
        type: USER_LOGGEDOUT,
    };
};
export const userLoggedInAs = (otherLogin) => {
    return {
        type: USER_LOGGEDINAS,
        login: otherLogin,
    };
};
export const userLoggedAsInGoBack = () => {
    return {
        type: USER_LOGGEDINAS_BACK,
    };
};
