import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

import { GET_DISTRIBUTION_USER_URL } from './CONSTANTS';
/**
 *
 * @param {String} userId userId to fetch
 * @returns Json array with results
 */
export const getDistributionCentersByUser = (userId, controller) => {
    console.log('distributionUserServices > getDistributionCentersByUser called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_DISTRIBUTION_USER_URL}&distributionUserId=${userId}`, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getDistributionCentersByUser > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getDistributionCentersByUser > axios err=', err);
                doRejectValidation(err, reject, 'getDistributionCentersByUser');
            });
    });
};
