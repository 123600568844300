import axios from 'axios';
import { doRejectValidation, parseXmlResult } from 'utils/JTUtils';

import { POST_WEBUPLOADER_QUERY_URL } from './CONSTANTS';

/**
 * Function to post file upload data
 * @param {string} handler handler name to upload
 * @param {string} name  file name to upload
 * @returns Upload file result
 */
export const postWebUploaderQuery = (handler, name, controller) => {
    console.log('queryServices > postWebUploaderQuery called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="webuploader-query" handler="${handler}"><name>${name}</name><result type="error"><limit offset="0" count="999"/></result></request>`;
        axios
            .post(POST_WEBUPLOADER_QUERY_URL, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postWebUploaderQuery > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postWebUploaderQuery > axios err=', err);
                doRejectValidation(err, reject, 'postWebUploaderQuery');
            });
    });
};
