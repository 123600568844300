import PageLoading from 'components/PageLoading';
import StatusPrintPage from 'pages/status/StatusPrintPage';
import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute.js';
import {
    MAINTENANCE_DELIVERY,
    MAINTENANCE_PRODUCTS,
    MAINTENANCE_ROUTES,
    ADMIN_LOGIN,
    DASHBOARD,
    LOGIN,
    MAINTENANCE_ACCOUNT,
    MAINTENANCE_SITES,
    MAINTENANCE_USER_DETAIL,
    MAINTENANCE_USERS,
    MANIFEST_CREATE,
    MANIFEST_EDIT,
    PASSWORD_RESET,
    ROOT,
    STATUS_DELIVERY,
    MANIFEST_FLEET,
    SUPPORT,
    MANIFEST_IMPORT,
    REPORTS_DELIVERY_EFFICIENCY,
    REPORTS_PLANNED_VS_ACTUAL_OVERVIEW,
    REPORTS_UNSCHEDULED_DELIVERIES,
    REPORTS_PLANNED_VS_ACTUAL_DETAIL,
    REPORTS_PRINT_LOAD,
    REPORTS_EXACTLOAD_DISCREPANCY,
    MAINTENANCE_SITES_PREVIEW,
    MAINTENANCE_USERS_PREVIEW,
    MANIFEST_ORDER,
    STATUS_TRIPS,
    REPORTS_ROUTE_STATUS,
    ADMIN_AFFILIATES,
    ADMIN_USERS,
    ADMIN_AFFILIATE_DETAIL_CREATE,
    ADMIN_AFFILIATE_DETAIL_EDIT,
    ADMIN_AFFILIATE_EDIT,
    ADMIN_MESSAGING,
    PASSWORD_RESET_TOKEN,
    STATUS_PRINT,
    DELIVERY_STATUS_VENDOR_LOGIN,
    ADMIN_USER_DETAIL_BY_AFFILIATE,
    ADMIN_USER_FORM,
    MESSAGES,
    MOBILE,
    TOTP,
    UNSUBSCRIBE,
    SUBSCRIBE,
    ADMIN_PARTNER_API_KEYS,
    ADMIN_PARTNER_API_KEY_DETAIL,
    LOGINAS_NAVIGATOR,
    LOGOUTAS_NAVIGATOR,
    MAINTENANCE_ROUTES_PREVIEW,
    DELIVERY_STATUS_VENDOR_LOGIN_WITH_PARAMS,
    HOME_NAVIGATOR,
    TEMPORARY_LOCK,
    PERMANENT_LOCK,
} from './CONSTANTS';
import { NotFound } from './NotFound';

import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { hotjar } from 'react-hotjar';

// Lazy imports
// Manifest pages
const ManifestImportPage = lazy(() => import('pages/manifest/UploadManifestPage'));
const ManifestOrderPage = lazy(() => import('pages/manifest/OrderPage'));
const ManifestFleetPage = lazy(() => import('pages/manifest/FleetPage'));
const ManifestEditPage = lazy(() => import('pages/manifest/EditPage'));
const ManifestCreatePage = lazy(() => import('pages/manifest/CreatePage'));

// Maintenace pages
const MaintenanceUsersPage = lazy(() => import('pages/maintenance/users/UserListPage'));
const MaintenanceUsersPreviewPage = lazy(() => import('pages/maintenance/users/UploadUsersPage'));
const MaintenanceSitesPreviewPage = lazy(() => import('pages/maintenance/sites/UploadSitesPage'));
const MaintenanceSitesPage = lazy(() => import('pages/maintenance/sites/SitesPage'));
const MaintenanceRoutesPage = lazy(() => import('pages/maintenance/RoutesPage'));
const MaintenanceRoutesPreviewPage = lazy(() =>
    import('pages/maintenance/routestabs/UploadRoutesPage')
);
const MaintenanceUserDetailPage = lazy(() => import('pages/maintenance/users/UserDetailPage'));
const MaintenanceProductsPage = lazy(() => import('pages/maintenance/products/UploadProductsPage'));
const MaintenanceAccountPage = lazy(() => import('pages/maintenance/AccountPage'));
const MaintenanceDeliveryStatusLinkPage = lazy(() =>
    import('pages/maintenance/DeliveryStatusLinkPage')
);

// Reports pages
const ReportsRouteStatusPage = lazy(() => import('pages/reports/RouteStatusPage'));
const ReportsPlannedVsActualOverviewPage = lazy(() =>
    import('pages/reports/PlannedvsActualOverviewPage')
);
const ReportsPlannedVsActualDetailPage = lazy(() =>
    import('pages/reports/PlannedvsActualDetailPage')
);
const ReportsDeliveryEfficiencyPage = lazy(() => import('pages/reports/DeliveryEfficiencyPage'));
const ReportsPrintLoadPage = lazy(() => import('pages/reports/PrintLoadPage'));
const ReportsUnscheduledDeliveriesPage = lazy(() =>
    import('pages/reports/UnscheduledDeliveriesPage')
);
const ReportsExactloadDiscrepancyPage = lazy(() =>
    import('pages/reports/ExactloadDiscrepancyPage')
);

// Status pages
const StatusTripsPage = lazy(() => import('pages/status/trips/TripsPage'));
const StatusDeliveryPage = lazy(() => import('pages/status/delivery/StatusPage'));

// Admin Pages
const AdminAffiliatesPage = lazy(() => import('pages/admin/affiliates/AffiliateListPage'));
const AdminUsersPage = lazy(() => import('pages/admin/users/AdminUserListPage'));
const AdminAffiliateDetailPage = lazy(() => import('pages/admin/affiliates/AffiliateFormPage'));
const AdminAffiliateEditListPage = lazy(() =>
    import('pages/admin/affiliates/AffiliateListEditPage')
);
const AdminMessaging = lazy(() => import('pages/admin/messaging/MessagingPage'));
const AdminUserFormPage = lazy(() => import('pages/admin/users/AdminUserFormPage'));
const AdminPartnerApiKeysPage = lazy(() => import('pages/admin/apikeys/PartnerApiKeyListPage'));
const AdminPartnerApiKeyDetailPage = lazy(() =>
    import('pages/admin/apikeys/PartnerApiKeyFormPage')
);

// Single Pages
const NavigatorPage = lazy(() => import('components/Navigator'));
const LogoutNavigatorPage = lazy(() => import('components/LogoutNavigator'));
const HomePage = lazy(() => import('pages/home-page'));
const LoginPage = lazy(() => import('pages/login/LoginPage'));
const PasswordResetPage = lazy(() => import('pages/password-reset/PasswordReset'));
const LockPage = lazy(() => import('pages/login/UserLockedPage'));
const PermanentLockPage = lazy(() => import('pages/login/UserLockedPermanentlyPage'));
const AdminTotpPage = lazy(() => import('pages/totp/TotpPage'));
const SubscribePage = lazy(() => import('pages/subscribe/SubscribePage'));
const SupportPage = lazy(() => import('pages/support/SupportPage'));
const DeliveryStatusLoginPage = lazy(() => import('pages/public-status/PublicStatusLoginPage'));
const UserMessagesPage = lazy(() => import('pages/messages/MessagesPage'));
const MobileLoginPage = lazy(() => import('pages/mobile/MobilePage'));

// Demo pages
// Uncomment to enable demo pages
// const CheckboxDemoPage = lazy(() => import('pages/CheckboxDemoPage'));
// const TableDemoPage = lazy(() => import('pages/TableDemoPage'));
// const ThemeSwitcherDemoPage = lazy(() => import('pages/ThemeSwitcherDemoPage'));
// const FileUploaderDemoPage = lazy(() => import('pages/FileUploaderDemoPage'));
// const FileUploaderPreviewDemoPage = lazy(() => import('pages/FileUploaderPreviewDemoPage'));
function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        if (hotjar.initialized()) {
            hotjar.stateChange(location.pathname);
        }
    }, [location]);
}
const RouterConfig = () => {
    const selectedTheme = useSelector((state) => state.theme.currentTheme);
    usePageViews();
    return (
        <Suspense fallback={<PageLoading />}>
            <Switch>
                <Route path={ROOT} exact>
                    <LoginPage isAdminLogin={false} />
                </Route>
                <Route path={LOGIN} exact>
                    <LoginPage isAdminLogin={false} />
                </Route>
                <Route path={ADMIN_LOGIN} exact>
                    <LoginPage isAdminLogin={true} />
                </Route>
                <Route path={TEMPORARY_LOCK} exact>
                    <LockPage />
                </Route>
                <Route path={PERMANENT_LOCK} exact>
                    <PermanentLockPage />
                </Route>
                <Route path={DELIVERY_STATUS_VENDOR_LOGIN_WITH_PARAMS}>
                    <DeliveryStatusLoginPage />
                </Route>
                <Route path={DELIVERY_STATUS_VENDOR_LOGIN}>
                    <DeliveryStatusLoginPage />
                </Route>
                <Route path={PASSWORD_RESET_TOKEN}>
                    <PasswordResetPage
                        indexSupportEmail={selectedTheme.data.indexSupportEmail}
                        supportPhoneNumber={selectedTheme.data.supportPhoneNumber}
                        brandingSSL={selectedTheme.data.brandingSSL}
                    />
                </Route>
                <Route path={PASSWORD_RESET}>
                    <PasswordResetPage
                        indexSupportEmail={selectedTheme.data.indexSupportEmail}
                        supportPhoneNumber={selectedTheme.data.supportPhoneNumber}
                        brandingSSL={selectedTheme.data.brandingSSL}
                    />
                </Route>
                <Route path={TOTP}>
                    <AdminTotpPage />
                </Route>
                <Route path={SUBSCRIBE}>
                    <SubscribePage subscription={true} />
                </Route>
                <Route path={UNSUBSCRIBE}>
                    <SubscribePage subscription={false} />
                </Route>
                <Route path={STATUS_PRINT}>
                    <StatusPrintPage />
                </Route>
                {/* 
                Uncomment the following routes to enable the demo pages
                <Route path={DEMOPAGES.TABLE}>
                    <TableDemoPage />
                </Route>
                <Route path={DEMOPAGES.UPLOAD}>
                    <FileUploaderDemoPage />
                </Route>
                <Route path={DEMOPAGES.THEMESWITCH}>
                    <ThemeSwitcherDemoPage />
                </Route> 
                <PrivateRoute path={DEMOPAGES.UPLOAD_PREVIEW} exact>
                    <FileUploaderPreviewDemoPage />
                </PrivateRoute>
                <PrivateRoute path={DEMOPAGES.CHECKBOX} exact>
                    <CheckboxDemoPage />
                </PrivateRoute>
                */}
                {/* List all private/auth routes here */}
                <PrivateRoute path={HOME_NAVIGATOR}>
                    <NavigatorPage />
                </PrivateRoute>
                <PrivateRoute path={LOGINAS_NAVIGATOR}>
                    <NavigatorPage />
                </PrivateRoute>
                <PrivateRoute path={LOGOUTAS_NAVIGATOR}>
                    <LogoutNavigatorPage />
                </PrivateRoute>
                <PrivateRoute path={DASHBOARD} requiredAccess={'home'}>
                    <HomePage />
                </PrivateRoute>
                <PrivateRoute path={MESSAGES} requiredAccess={'messages'}>
                    <UserMessagesPage />
                </PrivateRoute>
                <PrivateRoute path={MOBILE} requiredAccess={'mobile'}>
                    <MobileLoginPage />
                </PrivateRoute>
                <PrivateRoute path={STATUS_DELIVERY} requiredAccess={'status.delivery'}>
                    <StatusDeliveryPage />
                </PrivateRoute>
                <PrivateRoute path={STATUS_TRIPS} requiredAccess={'status.trips'}>
                    <StatusTripsPage />
                </PrivateRoute>
                <PrivateRoute
                    path={REPORTS_DELIVERY_EFFICIENCY}
                    requiredAccess={'reports.delivery-efficiency'}>
                    <ReportsDeliveryEfficiencyPage />
                </PrivateRoute>
                <PrivateRoute
                    path={REPORTS_PLANNED_VS_ACTUAL_DETAIL}
                    requiredAccess={'reports.pva-detail'}>
                    <ReportsPlannedVsActualDetailPage />
                </PrivateRoute>
                <PrivateRoute
                    path={REPORTS_UNSCHEDULED_DELIVERIES}
                    requiredAccess={'reports.unscheduled-and-pickup'}>
                    <ReportsUnscheduledDeliveriesPage />
                </PrivateRoute>
                <PrivateRoute path={REPORTS_PRINT_LOAD} requiredAccess={'reports.print-load'}>
                    <ReportsPrintLoadPage />
                </PrivateRoute>
                <PrivateRoute
                    path={REPORTS_EXACTLOAD_DISCREPANCY}
                    requiredAccess={'reports.exactload-discrepancy'}>
                    <ReportsExactloadDiscrepancyPage />
                </PrivateRoute>
                <PrivateRoute
                    path={REPORTS_PLANNED_VS_ACTUAL_OVERVIEW}
                    requiredAccess={'reports.pva-report'}>
                    <ReportsPlannedVsActualOverviewPage />
                </PrivateRoute>
                <PrivateRoute path={REPORTS_ROUTE_STATUS} requiredAccess={'reports.route-status'}>
                    <ReportsRouteStatusPage />
                </PrivateRoute>
                <PrivateRoute
                    path={MAINTENANCE_DELIVERY}
                    requiredAccess={'maintenance.delivery-status'}>
                    <MaintenanceDeliveryStatusLinkPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_SITES_PREVIEW} requiredAccess={'maintenance.sites'}>
                    <MaintenanceSitesPreviewPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_SITES} requiredAccess={'maintenance.sites'}>
                    <MaintenanceSitesPage />
                </PrivateRoute>
                <PrivateRoute
                    path={MAINTENANCE_ROUTES_PREVIEW}
                    requiredAccess={'maintenance.routes'}>
                    <MaintenanceRoutesPreviewPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_ROUTES} requiredAccess={'maintenance.routes'}>
                    <MaintenanceRoutesPage />
                </PrivateRoute>

                <PrivateRoute path={MAINTENANCE_USERS_PREVIEW} requiredAccess={'maintenance.users'}>
                    <MaintenanceUsersPreviewPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_USERS} requiredAccess={'maintenance.users'}>
                    <MaintenanceUsersPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_USER_DETAIL} requiredAccess={'maintenance.users'}>
                    <MaintenanceUserDetailPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_PRODUCTS} requiredAccess={'maintenance.products'}>
                    <MaintenanceProductsPage />
                </PrivateRoute>
                <PrivateRoute path={MAINTENANCE_ACCOUNT} requiredAccess={'maintenance.account'}>
                    <MaintenanceAccountPage />
                </PrivateRoute>
                <PrivateRoute path={MANIFEST_CREATE} requiredAccess={'manifest.create'}>
                    <ManifestCreatePage />
                </PrivateRoute>
                <PrivateRoute path={MANIFEST_EDIT} requiredAccess={'manifest.edit'}>
                    <ManifestEditPage />
                </PrivateRoute>
                <PrivateRoute path={MANIFEST_FLEET} requiredAccess={'manifest.fleet'}>
                    <ManifestFleetPage />
                </PrivateRoute>
                <PrivateRoute path={MANIFEST_ORDER} requiredAccess={'manifest.order'}>
                    <ManifestOrderPage />
                </PrivateRoute>
                <PrivateRoute path={MANIFEST_IMPORT} requiredAccess={'manifest.import'}>
                    <ManifestImportPage />
                </PrivateRoute>
                <PrivateRoute path={SUPPORT} requiredAccess={'support'}>
                    <SupportPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_AFFILIATES} adminOnly={true}>
                    <AdminAffiliatesPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_USERS} exact adminOnly={true}>
                    <AdminUsersPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_AFFILIATE_DETAIL_CREATE} exact adminOnly={true}>
                    <AdminAffiliateDetailPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_AFFILIATE_DETAIL_EDIT} adminOnly={true}>
                    <AdminAffiliateDetailPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_AFFILIATE_EDIT} adminOnly={true}>
                    <AdminAffiliateEditListPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_MESSAGING} adminOnly={true}>
                    <AdminMessaging />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_USER_DETAIL_BY_AFFILIATE} adminOnly={true}>
                    <AdminUserFormPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_USER_FORM} exact adminOnly={true}>
                    <AdminUserFormPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_PARTNER_API_KEYS} exact adminOnly={true}>
                    <AdminPartnerApiKeysPage />
                </PrivateRoute>
                <PrivateRoute path={ADMIN_PARTNER_API_KEY_DETAIL} exact adminOnly={true}>
                    <AdminPartnerApiKeyDetailPage />
                </PrivateRoute>

                {/* List a generic 404-Not Found route here */}
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Suspense>
    );
};

export default RouterConfig;
