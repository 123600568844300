import React from 'react';
const CopyRight = () => {
    const year = new Date().getFullYear();
    return (
        <div id="footer-wrapper">
            <span className="copyright">
                &copy;2006-{year} ECi Software Solutions, Inc. All rights reserved. Terms, features,
                support, pricing and service options subject to change without notice.
            </span>
            <span className="poweredByViamente">Routes optimized by Viamente</span>
        </div>
    );
};
export default CopyRight;
