import { NETWORK_ERROR } from 'config/CONSTANTS';
import { LOGIN, LOGOUTAS_NAVIGATOR, MOBILE } from 'navigation/CONSTANTS';
import React, { useEffect, useRef } from 'react';
import { ArrowUpLeftSquareFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideLoading, showLoading } from 'redux/actions/loadingActions';
import { userLoggedAsInGoBack, userLoggedOut } from 'redux/actions/loginActions';
import { cleanRegDistRouteSelection } from 'redux/actions/regDisRouteActions';
import { showErrorMessage } from 'redux/actions/systemMessageActions';
import { clearUserInfo, setUserInfo } from 'redux/actions/userActions';
import { postLogoutAs } from 'services';
import { sendLogout } from 'services/authServices';
import { getUserInfo } from 'services/userServices';
import { navigateTo } from 'utils/JTUtils';
import { LAST_LOCATION_BROWSED_KEY } from 'utils/storage/CONSTANTS';
import { removeSessionStorage } from 'utils/storage/SessionStorageHelper';
const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const logoutController = useRef(new AbortController());
    const userInfoController = useRef(new AbortController());
    const logoutAsController = useRef(new AbortController());

    //#region selector hooks
    const login = useSelector((state) => state.login.login);
    const secondaryLogin = useSelector((state) => state.login.secondary_login);
    const isServerMobile = process.env.REACT_APP_SERVER_MOBILE === 'true';
    //#endregion
    //#region useEffect hooks

    useEffect(() => {
        return () => {
            logoutController.current.abort();
            userInfoController.current.abort();
            logoutAsController.current.abort();
        };
    }, []);
    //#endregion
    //#region Helpers
    const getUserInfoManual = () => {
        getUserInfo(userInfoController)
            .then((resp) => {
                dispatch(setUserInfo(resp));
                navigateTo(history, LOGOUTAS_NAVIGATOR);
            })
            .catch(() => {
                dispatch(userLoggedOut());
                dispatch(clearUserInfo());
            })
            .finally(() => {
                dispatch(hideLoading());
            });
    };
    //#endregion
    //#region Events
    const onMobilePageClick = () => {
        if (!isServerMobile) {
            return;
        }

        navigateTo(history, MOBILE);
    };
    const onLogoutClick = () => {
        dispatch(showLoading());
        sendLogout(logoutController)
            .then(() => {
                dispatch(userLoggedOut());
                dispatch(clearUserInfo());
                dispatch(hideLoading());
                navigateTo(history, LOGIN);
                removeSessionStorage(LAST_LOCATION_BROWSED_KEY);
            })
            .catch((error) => {
                console.log(error);
                dispatch(showErrorMessage({ message: error.message, error }));
                dispatch(hideLoading());
            });
    };

    const onGoBackClick = () => {
        dispatch(showLoading());
        postLogoutAs(logoutAsController)
            .then(() => {
                dispatch(cleanRegDistRouteSelection());

                dispatch(userLoggedAsInGoBack());

                getUserInfoManual();
                removeSessionStorage(LAST_LOCATION_BROWSED_KEY);
            })
            .catch((error) => {
                console.log(error);
                dispatch(showErrorMessage({ message: NETWORK_ERROR, error }));
                dispatch(hideLoading());
            });
    };
    //#endregion
    return (
        <div className="logoutContainer">
            <div>
                {isServerMobile ? (
                    <>
                        <button className="btn btn-link btn-sm" onClick={onMobilePageClick}>
                            <b>{login}</b>
                        </button>
                    </>
                ) : (
                    <span>{login}</span>
                )}
                <button className="btn btn-link btn-sm ml-3" onClick={onLogoutClick}>
                    Log out
                </button>
            </div>
            <div>
                {secondaryLogin ? (
                    <div>
                        <button className="btn btn-link btn-sm" onClick={onGoBackClick}>
                            <ArrowUpLeftSquareFill /> {secondaryLogin.split('@')[0]}
                        </button>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Logout;
