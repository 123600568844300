import { UK } from 'i18n/Translations';

// App specific constants that you don't want to keep in config can go here.
export const LOGIN_UID = 'UID';
export const LOGIN_USERTYPE = 'USERTYPE';
export const LOGIN_NLA = 'NLA';
export const LOGIN_ACCOUNT = 'ACCOUNT';
export const LOGIN_LOCKED = 'LCK';
export const LOGIN_LOCKED_TMP = 'LCK_TMP';
export const EMAIL_REGEX = '[A-Za-z0-9\\._%-]+@[A-Za-z0-9\\.-]+\\.[A-Za-z]{2,4}';
export const DECIMAL_REGEX = '\\d*';
export const DECIMAL_REGEX_FORMAT = /^\d+$/;
export const DECIMAL_POINT_REGEX_FORMAT = /^\d+\.?\d*$/;
export const MULTIPLE_EMAIL_REGEX =
    /^(\s*([A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*,?\s*){0,5}\s*)$/;

export const PASSWORD_FULL_FORMATTED_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
export const PASSWORD_LENGTH_REGEX = /^(.{8,20}$)/;
export const PASSWORD_UPPER_REGEX = /^(?=.*[A-Z])/;
export const PASSWORD_LOWER_REGEX = /^(?=.*[a-z])/;
export const PASSWORD_DIGIT_REGEX = /^(?=.*\d)/;
export const PASSWORD_SPECIAL_REGEX = /^(?=.*[@$!%*?&])/;
export const IP_REGEX = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

export const NETWORK_ERROR = 'Could not communicate with the server.';
export const COMMON_ERROR_TITLE = 'Error';
export const COMMON_SUCCESS_TITLE = 'Success';

export const END_METHOD_LAST_SIGNATURE = 'LAST_SIGNATURE';
export const END_METHOD_DISTRIBUTION = 'DISTRIBUTION';

export const SHOW_ALL_DROPDOWN_ITEM = { name: 'All', key: 'all' };

export const zIndexSitePosition = 30;
export const zIndexMapMarker = 20;
export const zIndexReferencePosition = 10;
export const zIndexDistribution = 5000;
export const zIndexSignature = 1000;
export const zIndexTruck = 7500;
export const zIndexDirectionsRoute = 1000;
export const zIndexDirectionsOptimized = 1100;
export const zIndexTripLeg = 2000;
export const zIndexStopHover = 10000;

export const cfgMinRadius = UK.minSiteRadius;
export const cfgMaxRadius = UK.maxSiteRadius;

export const windowTypes = {
    ANYTIME: 'ANYTIME,,,',
    BEFORE: 'BEFORE,,,before',
    AFTER: 'AFTER,after,,',
    BETWEEN: 'BETWEEN,start,until,end',
    EXCLUDING: 'EXCLUDING,before,to,after',
};

export const AUTHENTICATION_FAILED_ERROR = 'Authentication Failed';
export const CANCELED_REQUEST_ERROR = 'ERR_CANCELED';
export const ABORTED_REQUEST_ERROR = 'ECONNABORTED';
export const LOGIN_THROTTLE_TIMES = [0, 0, 0, 60, 60, 300, 300, 480, 480, 600];
export const LOCK_REMAINING_ATTEMPTS = [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, -1];
