import { LOADING_REDUCER_SHOW_LOADING, LOADING_REDUCER_HIDE_LOADING } from 'redux/CONSTANTS';

export const showLoading = (message) => {
    return {
        type: LOADING_REDUCER_SHOW_LOADING,
        message,
    };
};
export const hideLoading = () => {
    return {
        type: LOADING_REDUCER_HIDE_LOADING,
    };
};
