import React, { useEffect } from 'react';
import 'utils/dropConsole.js';
import './styles/app.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { GlobalStyles } from './themes/GlobalStyles';
import RouterConfig from './navigation/RouterConfig';
import SystemNotifications from 'components/SystemNotifications';
import Loading from 'components/Loading';
import EulaModal from 'components/EulaModal';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

function App() {
    const selectedTheme = useSelector((state) => state.theme.currentTheme);
    useEffect(() => {
        const siteId = process.env.REACT_APP_HOTJAR_ID;
        const hotjarVersion = process.env.REACT_APP_HOTJAR_SV;
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

        hotjar.initialize(siteId, hotjarVersion);
    }, []);

    console.log('version_number', process.env.REACT_APP_VERSION_NUMBER);
    return (
        <>
            {selectedTheme && (
                <ThemeProvider theme={selectedTheme}>
                    <GlobalStyles />
                    <Router>
                        <RouterConfig />
                    </Router>
                    <EulaModal />
                    <SystemNotifications />
                    <Loading />
                </ThemeProvider>
            )}
        </>
    );
}

export default App;
