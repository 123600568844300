import React, { useEffect, useRef } from 'react';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { House } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { HOME_NAVIGATOR } from './CONSTANTS';
import MainLogo from 'components/MainLogo';
import CopyRight from 'components/CopyRight';
import { removeSessionStorage } from 'utils/storage/SessionStorageHelper';
import { LAST_LOCATION_BROWSED_KEY } from 'utils/storage/CONSTANTS';
export const NotFound = () => {
    const notFoundTimeout = useRef(null);

    // redirect to homepage after 10 seconds
    useEffect(() => {
        removeSessionStorage(LAST_LOCATION_BROWSED_KEY);

        notFoundTimeout.current = setTimeout(() => {
            window.location.href = HOME_NAVIGATOR;
        }, 10000);
        return () => {
            if (notFoundTimeout.current) {
                clearTimeout(notFoundTimeout.current);
            }
        };
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <div>
                            <Row className="mt-3">
                                <Col>
                                    <Breadcrumb>
                                        <li className="breadcrumb-item">
                                            <Link to={HOME_NAVIGATOR}>
                                                <House />
                                            </Link>
                                        </li>
                                        <Breadcrumb.Item active>Page not Found</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-center">
                                        <MainLogo />
                                    </div>
                                    <h2 className="text-center">Page Not Found</h2>
                                    <div className="text-center">
                                        We&apos;re sorry, the page you are looking for was not
                                        found.
                                        <br /> please check your spelling or update your bookmark.
                                    </div>
                                    <div className="mt-5 mb-3 text-center">
                                        This page will re-direct you to the{' '}
                                        <Link to={HOME_NAVIGATOR}>home page</Link> in 10 seconds.
                                    </div>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <CopyRight />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
