import { USERINFO_SET, USERINFO_CLEAR } from '../CONSTANTS';

let initialData = {
    user: {},
    affiliate: {
        customerUrl: '',
    },
    loaded: false,
    showEula: false,
};

const userReducer = (state = initialData, action) => {
    switch (action.type) {
        case USERINFO_SET: {
            return {
                ...state,
                ...action.value,
                loaded: true,
            };
        }
        case USERINFO_CLEAR: {
            return {
                ...initialData,
            };
        }
    }
    return state;
};
export default userReducer;
