import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    .btn-primary{
        background-color: ${({ theme }) => theme.colors.button.background};
        border-color: ${({ theme }) => theme.colors.button.border};
    }
    .btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:focus, .btn-primary.focus{
        background-color: ${({ theme }) => theme.colors.button.backgroundActive};
        border-color: ${({ theme }) => theme.colors.button.border};
    }
    .btn-primary:focus, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .form-control:focus{
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colors.button.shadow};
    }
    .form-control:focus{
        border-color:  ${({ theme }) => theme.colors.input.borderFocus};
    }
    .header-top{
        background: ${({ theme }) => theme.images.headerBackground};
    }
    .navbar.bg-dark {
        box-shadow: none;
        background: ${({ theme }) => theme.images.navBg};
    }
    .nav-item.active .nav-link {
        background-color: ${({ theme }) => theme.colors.navLink.backgroundActive};
    }
    .nav-item:hover .nav-link {
        background-color: ${({ theme }) => theme.colors.navLink.backgroundActive};
        color: ${({ theme }) => theme.colors.navLink.color};
    }
    .navbar-nav .nav-link{
        color: ${({ theme }) => theme.colors.navLink.color} !important;
    }
    .navbar-nav .dropdown-menu {
        background-color: ${({ theme }) => theme.colors.navLink.background} !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item{
        color: ${({ theme }) => theme.colors.navLink.color};
    }
    
    .card-header{
        background: ${({ theme }) => theme.colors.cardHeader.background};
        color: ${({ theme }) => theme.colors.cardHeader.color};
    }
    .progress-bar{
        background: ${({ theme }) => theme.colors.button.background};
    }
    .nav-tabs .nav-link{
        background: ${({ theme }) => theme.colors.tabs.background};
        color: ${({ theme }) => theme.colors.tabs.color};
    }
    .nav-tabs .nav-link.active{
        background: ${({ theme }) => theme.colors.tabs.backgroundActive};
    }
    .timepicker-time{
        button{
            :hover{
                color: ${({ theme }) => theme.colors.button.backgroundActive};
            }
        }
    }
`;
