import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { LOGIN } from 'navigation/CONSTANTS';
import CopyRight from 'components/CopyRight';
import { useLocation } from 'react-router-dom';
import { removeSessionStorage, setSessionStorage } from 'utils/storage/SessionStorageHelper';
import { LAST_LOCATION_BROWSED_KEY } from 'utils/storage/CONSTANTS';
import NotAllowed from 'navigation/NotAllowed';
import { jumptechRoleMap } from 'pages/maintenance/users/CONSTANTS';

// screen if you're not yet authenticated.
const PrivateRoute = ({ children, requiredAccess, adminOnly = false, ...rest }) => {
    const isServerMobile = process.env.REACT_APP_SERVER_MOBILE === 'true';
    const location = useLocation();
    //#region selector hooks
    const login = useSelector((state) => state.login.login);
    const access = useSelector((state) => state.userinfo.user.access);
    const roles = useSelector((state) => state.userinfo.user.roles);

    //#endregion
    //#region useEffect hooks
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        if (!roles || !access) {
            return;
        }
        if (verifyAccess(access, roles)) {
            setSessionStorage(LAST_LOCATION_BROWSED_KEY, location.pathname);
        } else {
            removeSessionStorage(LAST_LOCATION_BROWSED_KEY);
        }
    }, [location, roles, access]);
    /* eslint-enable */
    //#endregion

    //#region helpers
    const verifyAccess = (accessParam, theRoles) => {
        if (!accessParam || accessParam.length === 0) {
            return true;
        }
        if (!roles) {
            return true;
        }
        if (!requiredAccess) {
            if (adminOnly) {
                return (
                    theRoles &&
                    theRoles.filter(
                        (item) =>
                            item === jumptechRoleMap.JUMPTECH.id ||
                            item === jumptechRoleMap.JUMPTECH_ADMIN.id
                    ).length > 0
                );
            }
            return true;
        }
        if (requiredAccess === 'mobile') {
            return isServerMobile;
        }

        const theAccess = requiredAccess.split('.');
        if (theAccess.length === 1) {
            return theAccess[0] in accessParam;
        }
        if (theAccess.length === 2) {
            return theAccess[0] in accessParam && theAccess[1] in access[theAccess[0]];
        }
        return false;
    };
    //#endregion
    return (
        <Route
            {...rest}
            render={({ location }) =>
                login ? (
                    <>
                        {verifyAccess(access, roles) ? <>{children}</> : <NotAllowed />}
                        <div className="text-center">
                            <CopyRight />
                        </div>
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: LOGIN,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
PrivateRoute.propTypes = {
    children: PropTypes.element,
    requiredAccess: PropTypes.string,
    adminOnly: PropTypes.bool,
};
export default PrivateRoute;
