export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';
export const USER_LOGGEDINAS = 'USER_LOGGEDINAS';
export const USER_LOGGEDINAS_BACK = 'USER_LOGGEDINAS_BACK';
export const THEME_CHANGE = 'THEME_CHANGE';
export const USERINFO_SET = 'USERINFO_SET';
export const USERINFO_CLEAR = 'USERINFO_CLEAR';
export const REGION_SET = 'REGION_SET';
export const ROUTE_SET = 'ROUTE_SET';
export const DISTRIBUTION_SET = 'DISTRIBUTION_SET';
export const LOOKUP_SET_VARIABLE = 'LOOKUP_SET_VARIABLE';
export const LOADING_REDUCER_SHOW_LOADING = 'LOADING_REDUCER_SHOW_LOADING';
export const LOADING_REDUCER_HIDE_LOADING = 'LOADING_REDUCER_HIDE_LOADING';
export const SYSTEM_MESSAGES_REDUCER_SHOW_ERROR = 'SYSTEM_MESSAGES_REDUCER_SHOW_ERROR';
export const SYSTEM_MESSAGES_REDUCER_HIDE = 'SYSTEM_MESSAGES_REDUCER_HIDE';
export const SYSTEM_MESSAGES_REDUCER_SHOW_INFO = 'SYSTEM_MESSAGES_REDUCER_SHOW_INFO';
export const IMAGES_REDUCER_SHOW_IMAGE_MODAL = 'IMAGES_REDUCER_SHOW_IMAGE_MODAL';
export const IMAGES_REDUCER_HIDE_IMAGE_MODAL = 'IMAGES_REDUCER_HIDE_IMAGE_MODAL';
export const DELIVERY_DETAIL_REDUCER_SHOW_MODAL = 'DELIVERY_DETAIL_REDUCER_SHOW_MODAL';
export const DELIVERY_DETAIL_REDUCER_HIDE_MODAL = 'DELIVERY_DETAIL_REDUCER_HIDE_MODAL';
export const SITE_PAGE_REDUCER_CHANGE_VARIABLE = 'SITE_PAGE_REDUCER_CHANGE_VARIABLE';
export const SITE_PAGE_REDUCER_CHANGE_SITE = 'SITE_PAGE_REDUCER_CHANGE_SITE';
export const SITE_PAGE_REDUCER_SET_CRUMBS = 'SITE_PAGE_REDUCER_SET_CRUMBS';
export const SITE_PAGE_REDUCER_GEOCODE_MODAL = 'SITE_PAGE_REDUCER_GEOCODE_MODAL';
export const SITE_PAGE_REDUCER_CHANGE_STATE_VARIABLE = 'SITE_PAGE_REDUCER_CHANGE_STATE_VARIABLE';
export const SITE_PAGE_REDUCER_CLEAR_SITE = 'SITE_PAGE_REDUCER_CLEAR_SITE';
export const MANIFEST_FLEET_REDUCER_SET_LIMITS = 'MANIFEST_FLEET_REDUCER_SET_LIMITS';
export const MANIFEST_FLEET_REDUCER_CHANGE_STATE_VARIABLE =
    'MANIFEST_FLEET_REDUCER_CHANGE_STATE_VARIABLE';
export const MANIFEST_FLEET_REDUCER_SET_ASSETS = 'MANIFEST_FLEET_REDUCER_SET_ASSETS';
export const MANIFEST_FLEET_REDUCER_SET_SCHEDULE = 'MANIFEST_FLEET_REDUCER_SET_SCHEDULE';
export const MANIFEST_FLEET_REDUCER_SET_PARAMS = 'MANIFEST_FLEET_REDUCER_SET_PARAMS';
export const MANIFEST_FLEET_REDUCER_SET_STOPS = 'MANIFEST_FLEET_REDUCER_SET_STOPS';
export const MANIFEST_FLEET_REDUCER_TOGGLE_HOVER = 'MANIFEST_FLEET_REDUCER_TOGGLE_HOVER';
export const MANIFEST_FLEET_REDUCER_CHANGE_OPERATION_MODAL =
    'MANIFEST_FLEET_REDUCER_CHANGE_OPERATION_MODAL';
export const MANIFEST_FLEET_REDUCER_CHANGE_DELIVERYSTOP_MODAL =
    'MANIFEST_FLEET_REDUCER_CHANGE_DELIVERYSTOP_MODAL';
export const MANIFEST_FLEET_REDUCER_CHANGE_PRIORITY_MODAL =
    'MANIFEST_FLEET_REDUCER_CHANGE_PRIORITY_MODAL';
export const MANIFEST_FLEET_REDUCER_CLEAN = 'MANIFEST_FLEET_REDUCER_CLEAN';
export const REGDISROUTE_SET_ROUTE_LIST = 'REGDISROUTE_SET_ROUTE_LIST';
export const REGDISROUTE_SET_REGION_LIST = 'REGDISROUTE_SET_REGION_LIST';
export const REGDISROUTE_SET_DISTRIBUTION_LIST = 'REGDISROUTE_SET_DISTRIBUTION_LIST';
export const REGDISROUTE_SELECT_REGION = 'REGDISROUTE_SELECT_REGION';
export const REGDISROUTE_SELECT_ROUTE = 'REGDISROUTE_SELECT_ROUTE';
export const REGDISROUTE_SELECT_DISTRIBUTION_CENTER = 'REGDISROUTE_SELECT_DISTRIBUTION_CENTER';
export const REGDISTROUTE_CLEAN_SELECTION = 'REGDISTROUTE_CLEAN_SELECTION';
export const STATUSTRIPS_SET_ROUTE_LIST = 'STATUSTRIPS_SET_ROUTE_LIST';
export const STATUSTRIPS_SET_SELECTED_ROUTES = 'STATUSTRIPS_SET_SELECTED_ROUTES';
export const STATUSTRIPS_CHANGE_DATE = 'STATUSTRIPS_CHANGE_DATE';
export const STATUSTRIPS_SELECT_TRIP = 'STATUSTRIPS_SELECT_TRIP';
export const STATUSTRIPS_LOADCRUMBS = 'STATUSTRIPS_LOADCRUMBS';
export const STATUSTRIPS_ADDROUTEKEY = 'STATUSTRIPS_ADDROUTEKEY';
export const STATUSTRIPS_DISTRIBUTIONCENTER = 'STATUSTRIPS_DISTRIBUTIONCENTER';
export const STATUSTRIPS_CLEAR = 'STATUSTRIPS_CLEAR';
export const MENU_SELECTED = 'MENU_SELECTED';
export const EMPTY_ACTION = 'EMPTY_ACTION';
export const EULA_SHOW = 'EULA_SHOW';
