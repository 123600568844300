import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';
import { POST_GET_USER_INFO } from './CONSTANTS';
/**
 * Function to do get the user information after authentication.
 */
export const getUserInfo = (controller) => {
    console.log('userServices > getUserInfo called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                POST_GET_USER_INFO,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('getUserInfo > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getUserInfo > axios err=', err);
                doRejectValidation(err, reject, 'getUserInfo');
            });
    });
};
