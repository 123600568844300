import PropTypes from 'prop-types';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AdminNavMenu from './AdminNavMenu';
import Logout from './Logout';
import MainLogo from './MainLogo';
import NavMenu from './NavMenu';
const Header = ({ admin }) => {
    return (
        <div className="header">
            <div className="header-top container-fluid">
                <Row>
                    <Col sm={6}>
                        <MainLogo />
                    </Col>

                    <Col sm={6}>
                        <Logout />
                    </Col>
                </Row>
            </div>
            <div className="header-bottom">{admin ? <AdminNavMenu /> : <NavMenu />}</div>
        </div>
    );
};
Header.propTypes = {
    admin: PropTypes.bool,
};
export default Header;
