import React from 'react';

const EulaUK = () => {
    const html = `<!-- 
    &#8220;  left "
    &#8221;  right "
    &#39;    '
    &sect;   section 
    &#8212;  em dash
     -->
    
    <div class=WordSection1>
    
    <p class=MsoNormal><b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></b></p>
    
    <p class=MsoPlainText align=center style='text-align:center'><b><span
    style='font-size:11.0pt;font-family:Arial'>JUMPTRACK<sup>&reg;</sup> LICENCE
    AGREEMENT</span></b></p>
    
    <p class=MsoPlainText align=center style='text-align:center'><b><span
    style='font-size:11.0pt;font-family:Arial'>Version May 2018</span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>BY
    ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE
    OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, YOU AGREE TO THE
    TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A
    COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
    BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH
    CASE THE TERMS &quot;YOU&quot; OR &quot;YOUR&quot; SHALL REFER TO SUCH ENTITY
    AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE
    WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT
    USE THE SERVICES. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>You may
    not access the Services if You are Our direct competitor, except with Our prior
    written consent. In addition, You may not access the Services for purposes of
    monitoring their availability, performance or functionality, or for any other
    benchmarking or competitive purposes. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>This
    Agreement was last updated 2 February 2017. It is effective between You and Us
    as of the date of You accepting this Agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>1.
    DEFINITIONS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Affiliate&quot;</span></b><span
    style='font-size:11.0pt;font-family:Arial'> means any entity which directly or
    indirectly controls, is controlled by, or is under common control with the
    subject entity. &quot;Control,&quot; for purposes of this definition, means
    direct or indirect ownership or control of more than 50% of the voting
    interests of the subject entity. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&#8220;Delivery
    Routes&#8221;</span></b><span style='font-size:11.0pt;font-family:Arial'> means a
    grouping of delivery and/or pick up stops of Your goods delivered and/or picked
    up by You or on Your behalf that have been assigned by You in the Service.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Malicious
    Code&quot;</span></b><span style='font-size:11.0pt;font-family:Arial'> means
    viruses, worms, time bombs, Trojan horses and other harmful or malicious code,
    files, scripts, agents or programs. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Privacy
    Policy&quot;</span></b><span style='font-size:11.0pt;font-family:Arial'> refers
    to Our privacy policy, posted at www.eci.eu, as such policy may change from
    time to time.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Order
    Forms&quot;</span></b><span style='font-size:11.0pt;font-family:Arial'> means
    the documents for placing orders hereunder, including addenda thereto, that are
    entered into between You and Us or any of Our Affiliates from time to time,
    including addenda and supplements thereto. By accepting and entering into an
    Order Form hereunder, an Affiliate agrees to be bound by the terms of this
    Agreement as if it were an original party hereto. Order Forms shall be deemed
    incorporated herein by reference. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Services&quot;</span></b><span
    style='font-size:11.0pt;font-family:Arial'> means the products and services
    that are purchased by You or Your Affiliates under an Order Form and made
    available by Us online via the customer login link at https://myjumptrack.com
    and/or other web pages designated by Us, including associated offline
    components, as described in the User Guide.&nbsp; We may change the products,
    services and features which comprise the Services from time to time, in Our
    sole discretion.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;User
    Guide&quot;</span></b><span style='font-size:11.0pt;font-family:Arial'> means
    the online user guide for the Services, accessible via login at https://myjumptrack.com,
    as updated from time to time. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Users&quot;</span></b><span
    style='font-size:11.0pt;font-family:Arial'> means individuals who are
    authorized by You to use the Services, for whom subscriptions to a Service have
    been ordered, and who have been supplied user identifications and passwords by
    You (or by Us at Your request). Users may include but are not limited to Your
    employees, consultants, contractors and agents, and third parties with which
    You transact business. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;We,&quot;
    &quot;Us&quot; </span></b><span style='font-size:11.0pt;font-family:Arial'>or <b>&quot;Our&quot;</b>
    means ECI Software Solutions Limited described in Section 11 (Who You Are
    Contracting With, Notices, Governing Law and Jurisdiction). </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;You&quot;
    </span></b><span style='font-size:11.0pt;font-family:Arial'>or<b>
    &quot;Your&quot;</b> means the company or other legal entity for which you are
    accepting this Agreement, and Affiliates of that company or entity.&nbsp; </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&quot;Your
    Data&quot;</span></b><span style='font-size:11.0pt;font-family:Arial'> means
    all electronic data or information submitted by You to the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>2.
    SERVICES </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>2.1.
    Provision of Services.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; We shall make the Services available to You pursuant to this
    Agreement and the relevant Order Forms during a subscription term. You agree
    that Your purchases hereunder are neither contingent on the delivery of any future
    functionality or features nor dependent on any oral or written public comments
    made by Us regarding future functionality or features. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>2.2.
    Subscriptions.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    Unless otherwise specified in the applicable Order Form, (i) Services are purchased
    as subscriptions with Services pricing based upon User Subscriptions and/or
    Delivery Route subscriptions, (ii) additional subscriptions may be added during
    the applicable subscription term at the same pricing as that for the
    pre-existing subscriptions thereunder, prorated for the remainder of the
    subscription term in effect at the time the additional subscriptions are added,
    and (iii) the added subscriptions shall terminate on the same date as the
    pre-existing subscriptions.&nbsp; Subscriptions are for designated Users and/or
    Delivery Routes only and cannot be shared, but may be reassigned to new Users
    replacing former Users who no longer require ongoing use of the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.
    USE OF THE SERVICES </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.1.
    Our Responsibilities.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    We shall: (i) provide Our basic support for the Services to You at no
    additional charge, including telephone and e-mail support, (ii) use
    commercially reasonable efforts to make the Services available 24 hours a day,
    7 days a week, except for: (a) <span lang=EN-GB>planned downtime (of which We
    shall give at least 8 hour notice via the Services (unless downtime is
    reasonably expected to be less than 10 minutes in which case no notice is
    required) and which We shall schedule to the extent practicable during the week
    hours from 9 P.M. to 10 P.M. and during the weekend hours from 9 P.M. Friday to
    9 P.M. Sunday U.S. Central Time; </span>or (b) any unavailability caused by
    circumstances beyond Our reasonable control, including without limitation, acts
    of God, acts of government, floods, fires, earthquakes, civil unrest, acts of
    terror, strikes or other labor problems (other than those involving Our
    employees), Internet service provider failures or delays, or denial of service
    attacks, and (iii) provide the Services only in accordance with applicable laws
    and government regulations. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.2.
    Our Protection of Your Data.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; We shall maintain appropriate administrative,
    physical, and technical safeguards for protection of the security,
    confidentiality and integrity of Your Data.&nbsp; Except as expressly provided
    in this Agreement, we shall not (a) modify Your Data, (b) disclose Your Data
    except as compelled by law in accordance with Section 6.3 (Compelled
    Disclosure) or (c) access Your Data except to provide the Services and prevent
    or address service or technical problems, or at Your request in connection with
    customer support matters.&nbsp;&nbsp;&nbsp; Our Privacy Policy relative to any
    use of Our Services is incorporated herein by this reference.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.3.
    Your Responsibilities.</span></b><span style='font-size:11.0pt;font-family:
    Arial'> You shall (i) be responsible for Users&#39; compliance with this Agreement,
    (ii) be responsible for the accuracy, quality and legality of Your Data and of
    the means by which You acquired Your Data, (iii) use commercially reasonable
    efforts to prevent unauthorized access to or use of the Services, and notify Us
    promptly of any such unauthorized access or use, and (iv) use the Services only
    in accordance with the User Guide and applicable laws and government
    regulations. You shall not (a) make the Services available to anyone other than
    Users, (b) sell, resell, rent or lease the Services, (c) use the Services to
    store or transmit infringing, libelous, or otherwise unlawful or tortious
    material, or to store or transmit material in violation of third-party privacy
    rights, (d) use the Services to store or transmit Malicious Code, (e) interfere
    with or disrupt the integrity or performance of the Services or third-party
    data contained therein, or (f) attempt to gain unauthorized access to the
    Services or their related systems or networks. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.4.
    Usage Limitations.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    Services may be subject to other limitations, such as, for example, limits on
    disk storage space. Any such limitations are specified in the User Guide. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.5&nbsp;
    Data Rights.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp; <span
    lang=EN-GB>You hereby grant us a perpetual, non-exclusive, fully transferable,
    worldwide right and licence to (i) collect Your Data solely in an anonymous
    format that would not permit any recipient of Your Data to identify You or Your
    customer as the subjects thereof, (ii) aggregate Your Data with other data from
    other users of the Service (in a manner which does not permit identification or
    segregation of Your Data), and (iii) use, disclose, market, sell and/or license
    Your Data (only when it remains anonymous and when aggregated with other data)
    to third parties. To the extent Your Data consists of personal data, We will
    only qualify as a data processor as referred to in Paragraph 1(1) of Part I of
    the Data Protection Act 1998 (&#8220;Act&#8221;). You shall qualify as the data controller
    as referred to in Paragraph 1(1) of Part I of the Act, meaning that You are
    responsible and liable for the processing of personal data. This Agreement also
    constitutes a processing agreement within the meaning of Paragraph 12 of Part
    II of Schedule 1 of the Act. Throughout the Agreement, You must comply with all
    applicable data protection laws.</span></span></p>
    
    <p class=MsoPlainText><span lang=EN-GB style='font-size:11.0pt;font-family:
    Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>3.6&nbsp;
    Data Retention Policies.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'>&nbsp; </span><span lang=EN-GB style='font-size:11.0pt;font-family:
    Arial'>We store Your Data in the Services for three (3) years, meaning at any
    given time You would be able to access the prior three (3) years of Data.&nbsp;
    If you require access to any Data beyond such three (3) year period, You will
    be required to maintain Your own backups thereof at Your sole cost and expense
    or attempt to retrieve such Data from other sources (e.g., Your other software
    systems that interface with the Services). Relevant manifest data can be
    manually exported from JumpTrack provided it is done within three (3) years.
    Please see the supporting documentation in JumpTrack.</span></p>
    
    <p class=MsoPlainText><span lang=EN-GB style='font-size:11.0pt;font-family:
    Arial'>&nbsp;</span></p>
    
    <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
    line-height:normal'><b><span style='font-size:11.0pt;font-family:Arial'>3.7
    Google Maps APIs.</span></b><span style='font-size:11.0pt;font-family:Arial'> 
    You are also bound by the Google Maps/Google Earth Additional Terms of Service,
    which can be found at <span class=MsoHyperlink><a
    href="https://www.google.com/help/terms_maps.html">https://www.google.com/help/terms_maps.html</a></span>
    (including the Google Privacy Policy, which can be found at <span
    class=MsoHyperlink><a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></span>
    .</span></p>
    
    <p class=MsoPlainText><span lang=EN-GB style='font-size:11.0pt;font-family:
    Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.
    FEES AND PAYMENT FOR SERVICES </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.1.
    Fees.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp; You
    shall pay all fees specified in all Order Forms hereunder. Except as otherwise
    specified herein or in an Order Form, (i) fees are based on services purchased
    and not actual usage, (ii) payment obligations are non-cancelable and fees paid
    are non-refundable, and (iii) the number of subscriptions purchased cannot be
    decreased during the relevant subscription term stated on the Order Form. Subscription
    fees are based on monthly periods that begin on the subscription start date and
    each monthly anniversary thereof; therefore, fees for subscriptions added in
    the middle of a monthly period will be charged for that full monthly period and
    the monthly periods remaining in the subscription term.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.2.
    Invoicing and Payment.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; <span lang=EN-GB>All fees are due and payable according to Our
    direct debit arrangement with You or, if no such arrangement, within thirty
    (30) days from date of invoice, and will be invoiced to You on a monthly
    basis.&nbsp; You are responsible for providing complete and accurate billing
    and contact information to Us and notifying Us of any changes to such
    information.</span></span><span lang=EN-GB style='font-size:10.0pt;font-family:
    Arial'> </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.3. Overdue
    Charges.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp; If
    any charges are not received from You by the due date, then at Our discretion,
    (a) such charges may accrue late interest at the rate of 1.5% of the
    outstanding balance per month, or the maximum rate permitted by law, whichever
    is lower, from the date such payment was due until the date paid, and/or (b) We
    may condition future subscription renewals and Order Forms on payment terms
    shorter than those specified in Section 4.2 (Invoicing and Payment). </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.4.
    Suspension of Service and Acceleration.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; If any amount owing by You under this or any other
    agreement for Our services is thirty (30) or more days overdue, We may, without
    limiting Our other rights and remedies, accelerate Your unpaid fee obligations
    under such agreements so that all such obligations become immediately due and
    payable, and suspend Our services to You until such amounts are paid in full.
    We will give You at least seven (7) days&#39; prior notice that Your account is
    overdue, in accordance with Section 11.2 (Manner of Giving Notice), before
    suspending services to You. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.5.
    Payment Disputes.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    We shall not exercise Our rights under Section 4.3 (Overdue Charges) or 4.4
    (Suspension of Service and Acceleration) if You are disputing the applicable
    charges reasonably and in good faith and are cooperating diligently to resolve
    the dispute. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>4.6.
    Taxes.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp; Unless
    otherwise stated, Our fees do not include any taxes, levies, duties or similar
    governmental assessments of any nature, including but not limited to
    value-added, sales, use or withholding taxes, assessable by any local, state,
    provincial, federal or foreign jurisdiction (collectively, &quot;<u>Taxes</u>&quot;).
    You are responsible for paying all Taxes associated with Your purchases
    hereunder. If We have the legal obligation to pay or collect Taxes for which
    You are responsible under this paragraph, the appropriate amount shall be
    invoiced to and paid by You, unless You provide Us with a valid tax exemption
    certificate authorized by the appropriate taxing authority. For clarity, We are
    solely responsible for taxes assessable against it based on Our income,
    property and employees. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>5.
    PROPRIETARY RIGHTS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>5.1.
    Reservation of Rights in Services.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; Subject to the limited rights expressly granted
    hereunder, We reserve all rights, title and interest in and to the Services,
    including all related intellectual property rights. No rights are granted to
    You hereunder other than as expressly set forth herein. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>5.2.
    Restrictions.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    You shall not (i) permit any third party to access the Services except as
    permitted herein or in an Order Form, (ii) modify, copy or create derivate
    works based on the Services except as authorized herein, (iii) copy, frame or
    mirror any part or content of the Services, other than copying or framing on
    Your own intranets or otherwise for Your own internal business purposes, (iv)
    reverse engineer the Services, or (v) access the Services in order to (a) build
    a competitive product or service, (b) build a product or service using similar
    ideas, features, functions or graphics of the Service, or (c) copy any ideas,
    features, functions or graphics of the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>5.4.
    Suggestions.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    We shall have a royalty-free, worldwide, irrevocable, perpetual licence to use
    and incorporate into the Services any suggestions, enhancement requests,
    recommendations or other feedback provided by You, including Users, relating to
    the operation of the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>6.
    CONFIDENTIALITY </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>6.1.
    Definition of Confidential Information.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; As used herein, &quot;<u>Confidential Information</u>&quot;
    means all confidential information disclosed by a party (&quot;<u>Disclosing
    Party</u>&quot;) to the other party (&quot;<u>Receiving Party</u>&quot;),
    whether orally or in writing, that is designated as confidential or that
    reasonably should be understood to be confidential given the nature of the information
    and the circumstances of disclosure. Your Confidential Information shall
    include Your Data; Our Confidential Information shall include the Services; and
    Confidential Information of each party shall include the terms and conditions
    of this Agreement and all Order Forms, as well as business and marketing plans,
    technology and technical information, product plans and designs, and business
    processes disclosed by such party. However, Confidential Information (other
    than Your Data) shall not include any information that (i) is or becomes
    generally known to the public without breach of any obligation owed to the
    Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure
    by the Disclosing Party without breach of any obligation owed to the Disclosing
    Party, (iii) is received from a third party without breach of any obligation
    owed to the Disclosing Party, or (iv) was independently developed by the
    Receiving Party. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>6.2.
    Protection of Confidential Information.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; The Receiving Party shall use the same degree of care
    that it uses to protect the confidentiality of its own confidential information
    of like kind (but in no event less than reasonable care) (i) not to use any
    Confidential Information of the Disclosing Party for any purpose outside the
    scope of this Agreement, and (ii) except as otherwise authorized by the
    Disclosing Party in writing, to limit access to Confidential Information of the
    Disclosing Party to those of its and its Affiliates&#39; employees, contractors and
    agents who need such access for purposes consistent with this Agreement and who
    have signed confidentiality agreements with the Receiving Party containing
    protections no less stringent than those herein. Neither party shall disclose
    the terms of this Agreement or any Order Form to any third party other than its
    Affiliates and their legal counsel and accountants without the other party&#39;s
    prior written consent. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>6.3.
    Compelled Disclosure.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    The Receiving Party may disclose Confidential Information of the Disclosing
    Party if it is compelled by law to do so, provided the Receiving Party gives
    the Disclosing Party prior notice of such compelled disclosure (to the extent
    legally permitted) and reasonable assistance, at the Disclosing Party's cost,
    if the Disclosing Party wishes to contest the disclosure. If the Receiving
    Party is compelled by law to disclose the Disclosing Party&#39;s Confidential
    Information as part of a civil proceeding to which the Disclosing Party is a
    party, and the Disclosing Party is not contesting the disclosure, the
    Disclosing Party will reimburse the Receiving Party for its reasonable cost of
    compiling and providing secure access to such Confidential Information. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>6.4&nbsp;
    Remedies.&nbsp; </span></b><span style='font-size:11.0pt;font-family:Arial'>If
    the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential
    Information of the Disclosing Party in breach of this Section 6, the Disclosing
    Party shall have the right, in addition to any other remedies available to it,
    to seek injunctive relief to enjoin such acts, it being specifically
    acknowledged by the parties that any other available remedies are inadequate. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>7.
    WARRANTIES AND DISCLAIMERS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>7.1.
    Our Warranties.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    We warrant that (i) We have validly entered into this Agreement and have the
    legal power to do so, (ii) the Services shall perform materially in accordance
    with the User Guide, (iii) the functionality of the Services will not be
    materially decreased during a subscription term, and (iv) We will not transmit
    Malicious Code to You, provided it is not a breach of this subpart (iv) if You
    or a User uploads a file containing Malicious Code into the Services and later
    downloads that file containing Malicious Code. For any breach of a warranty
    above, Your exclusive remedy shall be as provided in Section 10.3 (Termination
    for Cause) and Section 10.4 (Refund or Payment upon Termination) below. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>7.2.
    Your Warranties.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    You warrant that You have validly entered into this Agreement and have the
    legal power to do so. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>7.3.
    Disclaimer.</span></b><span style='font-size:11.0pt;font-family:Arial'> EXCEPT
    AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTIES OF ANY KIND,
    WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH PARTY SPECIFICALLY
    DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY
    OR FITNESS FOR A PARTICULAR PURPOSE, TO THE MAXIMUM EXTENT PERMITTED BY
    APPLICABLE LAW. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>8.
    MUTUAL INDEMNIFICATION </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>8.1.
    Indemnification by Us.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; We shall defend You against any claim, demand, suit, or
    proceeding made or brought against You by a third party alleging that the
    Services infringes or misappropriates the intellectual property rights of a
    third party (an &quot;<u>Infringement Claim</u>&quot;), and shall indemnify You
    for any damages, reasonable attorney fees and costs finally awarded against You
    as a result of, and for amounts paid by You under a court-approved settlement
    of, an Infringement Claim; provided that You (a) promptly give Us written
    notice of the Infringement Claim; (b) give Us sole control of the defense and
    settlement of the Infringement Claim (provided that We may not settle any
    Infringement Claim unless the settlement unconditionally releases You of all
    liability); and (c) provide to Us all reasonable assistance, at Our expense. In
    the event of an Infringement Claim, or if We reasonably believe the Services
    may infringe or misappropriate, We may in Our discretion and at no cost to You
    (i) modify the Services so that they no longer infringe or misappropriate,
    without breaching Our warranties under &#8220;Our Warranties&#8221; above, (ii) obtain a
    licence for Your continued use of the Services in accordance with this
    Agreement, or (iii) terminate Your subscriptions for such Services upon 30
    days&#39; written notice and refund to You any prepaid fees covering the remainder
    of the term of such subscriptions after the effective date of termination. This
    Section states Your sole and exclusive remedy, and Our sole and exclusive
    obligation, with respect to a Infringement Claim. We shall have no obligations
    under this Section to the extent that You modify (or have modified on Your
    behalf) the Services or combine the Services with other software, hardware or other
    technology not provided by Us.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>8.2.
    Indemnification by You.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; You shall defend Us, our Affiliates and each of their respective
    officers, directors, employees and agents against any and all claims, demands,
    suits or proceedings made or brought against them by a third party relating to
    Your use of the Services (other than an Infringement Claim) or alleging that
    Your Data, or Your use of the Services in breach of this Agreement, infringes
    or misappropriates the intellectual property rights of a third party or
    violates applicable law (a &quot;<u>Claim Against Us</u>&quot;), and shall
    indemnify Us, our Affiliates and each of their respective officers, directors,
    employees and agents for any damages, attorney fees and costs finally awarded
    against Us as a result of, or for any amounts paid by Us under a court-approved
    settlement of, a Claim Against Us; provided that We (a) promptly give You
    written notice of the Claim Against Us; (b) give You sole control of the
    defense and settlement of the Claim Against Us (provided that You may not
    settle any Claim Against Us unless the settlement unconditionally releases Us
    of all liability); and (c) provide to You all reasonable assistance, at Your
    expense. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>9.
    LIMITATION OF LIABILITY </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>9.1.
    Limitation of Liability.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; WE SHALL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL, CONSEQUENTIAL,
    INDIRECT, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION ANY LOSS
    OF REVENUE, PROFITS, OR DATA, ARISING IN CONNECTION WITH THIS AGREEMENT, EVEN
    IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ARE SOLELY
    RESPONSIBLE FOR THE USE, OPERATION, SUPPORT AND MAINTENANCE OF ALL THIRD PARTY
    PRODUCTS AND SERVICES, AND WE SHALL HAVE NO LIABILITY OR RESPONSIBILITY
    THEREFOR. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE OR LOSSES RESULTING FROM USE OF
    THIRD PARTY PRODUCTS AND SERVICES. THE USE OF THIRD PARTY PRODUCTS AND SERVICES
    IS AT YOUR SOLE RISK. OUR MAXIMUM CUMULATIVE LIABILITY FOR ANY AND ALL DAMAGES,
    COSTS, LOSSES, LIABILITIES AND EXPENSES OF ANY KIND AND NATURE ARISING OUT OF
    OR RELATED TO THIS AGREEMENT SHALL NOT EXCEED THE TOTAL FEES PAID BY YOU UNDER
    THIS AGREEMENT IN THE ONE (1) YEAR PERIOD IMMEDIATELY PRECEDING THE DATE THE
    CLAIM AROSE. The exclusions in this clause shall apply to the fullest extent
    permissible at law, but we do not exclude liability for death or personal
    injury caused by our negligence or that of our officers, employees, contractors
    or agents; for fraud or fraudulent misrepresentation; for breach of the
    obligations implied by section 12 Sale of Goods Act 1979 or section 2 Supply of
    Goods and Services Act 1982; or for any other liability which may not be
    excluded by law.</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></b></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>9.2&nbsp;
    Limitation of Action.&nbsp; </span></b><span style='font-size:11.0pt;
    font-family:Arial'>Except for actions for non-payment or breach of either
    party's intellectual property rights, no action (regardless of form) arising
    out of this Agreement may be commenced by either party more than two (2) years
    after the cause of action has accrued. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.
    TERM AND TERMINATION </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.1.
    Term of Agreement.&nbsp; </span></b><span style='font-size:11.0pt;font-family:
    Arial'>This Agreement commences on the date You accept it and continues until
    all User and/or Delivery Route subscriptions granted in accordance with this
    Agreement have expired or been terminated. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.2.
    Term of Purchased Subscriptions.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; Subscriptions purchased by You commence on the start
    date specified in the applicable Order Form and continue for the subscription
    term specified therein. Except as otherwise specified in the applicable Order
    Form, all subscriptions shall automatically renew for additional periods equal
    to the expiring subscription term or one (1) year (whichever is shorter),
    unless either party gives the other notice of non-renewal at least thirty (30)
    days before the end of the relevant subscription term. The pricing during any
    such renewal term shall be the same as that during the prior term unless We
    have given You written notice of a pricing increase at least thirty (30) days
    before the end of such prior term, in which case the pricing increase shall be
    effective upon renewal and thereafter.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.3.
    Termination for Cause.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; A party may terminate this Agreement for cause: (i) upon thirty (30)
    days written notice to the other party of a material breach if such breach
    remains uncured at the expiration of such period, or (ii) if the other party
    becomes the subject of a petition in bankruptcy or any other proceeding relating
    to insolvency, receivership, liquidation or assignment for the benefit of
    creditors. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.4.
    Refund or Payment upon Termination.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; Upon any termination for cause by You, We shall
    refund You any prepaid fees covering the remainder of the term of all subscriptions
    after the effective date of termination. Upon any termination for cause by Us,
    You shall pay any unpaid fees covering the remainder of the term of all Order
    Forms after the effective date of termination. In no event shall any
    termination relieve You of the obligation to pay any fees payable to Us for the
    period prior to the effective date of termination. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.5.
    Return of Your Data.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    Upon request by You made within thirty (30) days after the effective date of
    termination of a Services subscription, We will make available to You for
    download a file of Your Data for the three (3) year period immediately
    preceding Our receipt of your request for Your Data, which will be provided in
    comma separated value (.csv) format. After such 30-day period, We shall have no
    obligation to maintain or provide any of Your Data and shall thereafter, unless
    legally prohibited, delete all of Your Data in Our systems or otherwise in Our
    possession or under Our control.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>10.6.
    Surviving Provisions.&nbsp; </span></b><span style='font-size:11.0pt;
    font-family:Arial'>Section 4 (Fees and Payment for Services), 5 (Proprietary
    Rights), 6 (Confidentiality), 7.3 (Disclaimer), 8 (Mutual Indemnification), 9
    (Limitation of Liability), 10.4 (Refund or Payment upon Termination), 10.5
    (Return of Your Data), 11 (Who You Are Contracting With, Notices, Governing Law
    and Jurisdiction) and 12 (General Provisions) shall survive any termination or
    expiration of this Agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>11.
    WHO YOU ARE CONTRACTING WITH, NOTICES, GOVERNING LAW AND JURISDICTION </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>11.1.
    General.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    Under this Agreement, You are contracting with ECI Software Solutions Limited, a
    corporation organized under the laws of England.</span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>11.2.
    Manner of Giving Notice.</span></b><span style='font-size:11.0pt;font-family:
    Arial'>&nbsp; <span lang=EN-GB>Any notices required or permitted hereunder
    shall be deemed to have been given if in writing and delivered personally, sent
    by overnight delivery or by first class, registered, or certified mail, postage
    prepaid and addressed to the receiving party at its address set forth above (or
    to such other address as may be notified from one party to the other following
    the same notice procedures).&nbsp; You agree to send a copy of all such notices
    to the attention of our General Counsel at Our address set forth above.</span></span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>11.3.
    Agreement to Governing Law and Jurisdiction.</span></b><span style='font-size:
    11.0pt;font-family:Arial'>&nbsp; <span lang=EN-GB>This Agreement and any
    dispute or claim arising out of or in connection with it or its subject matter
    or formation (including non-contractual disputes or claims) shall be governed
    by and construed in accordance with English law and each party hereby submits
    to the exclusive jurisdiction of the English courts.&nbsp; The United Nations
    International Convention on Contracts for the International Sale of Goods is
    expressly excluded from applicability to this Agreement.</span></span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.
    GENERAL PROVISIONS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span lang=EN-GB style='font-size:10.0pt;font-family:
    Arial'>12.1. Force Majeure. </span></b><span lang=EN-GB style='font-size:11.0pt;
    font-family:Arial'>We shall have no liability to You for breach of this
    Agreement if Our performance is prevented or delayed by circumstances beyond
    Our reasonable control, including but not limited to acts of God, inclement
    weather, flood, lightning or fire, strikes or other labour disputes or
    industrial action, act or omission of government or other competent authority,
    terrorism, war, riot, or civil commotion, unavailability of supply or power
    outage, hackers, viruses, disruption in transmission, or disruption in
    telecommunications services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.2.
    Anti-Corruption.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    You have not received or been offered any illegal or improper bribe, kickback,
    payment, gift, or thing of value from any of Our employees or agents in
    connection with this Agreement. Reasonable gifts and entertainment provided in
    the ordinary course of business do not violate the above restriction. If You
    learn of any violation of the above restriction, You will use reasonable
    efforts to promptly notify Our Legal Department (legal@ecisolutions.com). </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.3.
    Relationship of the Parties.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; The parties are independent contractors. This
    Agreement does not create a partnership, franchise, joint venture, agency,
    fiduciary or employment relationship between the parties. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.4.
    No Third-Party Beneficiaries.</span></b><span style='font-size:11.0pt;
    font-family:Arial'>&nbsp; There are no third-party beneficiaries to this
    Agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.5.
    Waiver.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp; No
    failure or delay by either party in exercising any right under this Agreement
    shall constitute a waiver of that right. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.6.
    Severability.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    If any provision of this Agreement is held by a court of competent jurisdiction
    to be contrary to law, the provision shall be modified by the court and
    interpreted so as best to accomplish the objectives of the original provision
    to the fullest extent permitted by law, and the remaining provisions of this
    Agreement shall remain in effect. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.7.
    Attorney Fees.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    You shall pay on demand all of Our reasonable attorney fees and other costs
    incurred by Us to collect any fees or charges due Us under this Agreement
    following Your breach of Section 4.2 (Invoicing and Payment). </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.8.
    Assignment.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    You shall not sell, transfer, assign or otherwise convey this Agreement, the
    Services or any of Your rights or obligations hereunder without Our prior
    written consent, including without limitation by operation of law to a
    successor in interest in connection with a merger, consolidation, sale of
    shares or assets or other change of control transaction involving You.&nbsp;
    The purported transferee shall first be required to agree in writing with Us to
    assume and perform all of Your obligations under this Agreement. Any attempt to
    assign this Agreement without Our prior written consent shall be void. This
    Agreement shall be binding upon and inure to the benefit of the parties and
    their respective successors and permitted assigns. </span></p>
    
    <p class=MsoPlainText><span style='font-size:11.0pt;font-family:Arial'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:11.0pt;font-family:Arial'>12.9.
    Entire Agreement.</span></b><span style='font-size:11.0pt;font-family:Arial'>&nbsp;
    This Agreement, including all exhibits and addenda hereto and all Order Forms,
    constitutes the entire agreement between the parties and supersedes all prior
    and contemporaneous agreements, proposals or representations, written or oral,
    concerning its subject matter.&nbsp; We may amend this Agreement from time to
    time by posting an amended version at Our Website and sending You written
    notice thereof, which may include written notice to Your email address on file
    with us. Such amendment will be deemed accepted and become effective thirty (30)
    days after such notice (the &#8220;Proposed Amendment Date&#8221;) unless You first give Us
    written notice of rejection of the amendment. In the event of such rejection,
    this Agreement will continue under its original provisions, and the amendment
    will become effective at the start of Your next Term following the Proposed
    Amendment Date (unless You first terminates this Agreement pursuant to Section
    10 above).&nbsp; Your continued use of the Services following the effective
    date of an amendment will confirm Your consent thereto. Notwithstanding the
    foregoing, We may amend the Privacy Policy at any time by posting a new version
    at Our Website and sending You notice thereof, and such amended version will
    become effective ten (10) business days after such notice is sent.&nbsp;
    However, to the extent of any conflict or inconsistency between the provisions
    in the body of this Agreement and any exhibit or addendum hereto or any Order
    Form, the terms of this Agreement shall prevail. Notwithstanding any language
    to the contrary therein, no terms or conditions stated in Your purchase order
    or other order documentation (excluding Order Forms) shall be incorporated into
    or form any part of this Agreement, and all such terms or conditions shall be
    null and void. </span></p>
    
    <p class=MsoPlainText><span style='font-size:9.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    </div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default EulaUK;
