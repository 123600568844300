import { getUserInfo } from 'services/userServices';
import { userLoggedOut } from './loginActions';

const { USERINFO_SET, USERINFO_CLEAR } = require('redux/CONSTANTS');

export const setUserInfo = (response) => {
    return {
        type: USERINFO_SET,
        value: response,
    };
};

export const clearUserInfo = (response) => {
    return {
        type: USERINFO_CLEAR,
        value: response,
    };
};

export const fetchUserInformation = (forceReload, controller) => {
    return (dispatch, getState) => {
        const prevUserInfoLoaded = getState().userinfo.loaded;
        if (prevUserInfoLoaded && !forceReload) {
            return;
        }
        getUserInfo(controller)
            .then((resp) => {
                dispatch(setUserInfo(resp));
            })
            .catch((e) => {
                console.log('fetch user info failed', e);
                dispatch(userLoggedOut());
                dispatch(clearUserInfo());
            });
    };
};
