import { SHOW_ALL_DROPDOWN_ITEM } from 'config/CONSTANTS';
import {
    GLOBAL_DISTRIBUTION_SELECTED_KEY,
    GLOBAL_REGION_SELECTED_KEY,
    GLOBAL_ROUTE_SELECTED_KEY,
} from 'utils/storage/CONSTANTS';
import { setLocalStorage } from 'utils/storage/LocalStorageHelper';
import {
    REGDISROUTE_SET_ROUTE_LIST,
    REGDISROUTE_SET_REGION_LIST,
    REGDISROUTE_SET_DISTRIBUTION_LIST,
    REGDISROUTE_SELECT_REGION,
    REGDISROUTE_SELECT_ROUTE,
    REGDISROUTE_SELECT_DISTRIBUTION_CENTER,
    REGDISTROUTE_CLEAN_SELECTION,
} from '../CONSTANTS';

let initialData = {
    routeList: [],
    regionList: [],
    distributionList: [],
    selectedRoute: null,
    selectedRegion: null,
    selectedDistributionCenter: null,
    regionCachedFor: [],
    distributionCenterCachedFor: [],
};

const regDistRouteReducer = (state = initialData, action) => {
    switch (action.type) {
        case REGDISROUTE_SET_ROUTE_LIST:
            return {
                ...state,
                routeList: action.value,
                distributionCenterCachedFor: action.distributionCenter,
            };
        case REGDISROUTE_SET_REGION_LIST:
            return {
                ...state,
                regionList: action.value,
            };
        case REGDISROUTE_SET_DISTRIBUTION_LIST: {
            return {
                ...state,
                distributionList: action.value,
                regionCachedFor: action.region,
            };
        }
        case REGDISROUTE_SELECT_REGION: {
            if (action.key !== SHOW_ALL_DROPDOWN_ITEM.key) {
                setLocalStorage(
                    GLOBAL_REGION_SELECTED_KEY,
                    { name: action.name, key: action.key },
                    true
                );
            }
            return {
                ...state,
                selectedRegion: action.key,
            };
        }
        case REGDISROUTE_SELECT_ROUTE: {
            if (action.key !== SHOW_ALL_DROPDOWN_ITEM.key) {
                setLocalStorage(
                    GLOBAL_ROUTE_SELECTED_KEY,
                    { name: action.name, key: action.key },
                    true
                );
            }

            return {
                ...state,
                selectedRoute: action.key,
            };
        }
        case REGDISROUTE_SELECT_DISTRIBUTION_CENTER: {
            if (action.key !== SHOW_ALL_DROPDOWN_ITEM.key) {
                setLocalStorage(
                    GLOBAL_DISTRIBUTION_SELECTED_KEY,
                    {
                        name: action.name,
                        key: action.key,
                    },
                    true
                );
            }

            return {
                ...state,
                selectedDistributionCenter: action.key,
            };
        }
        case REGDISTROUTE_CLEAN_SELECTION: {
            return {
                ...state,
                selectedDistributionCenter: null,
                selectedRegion: null,
                selectedRoute: null,
                routeList: [],
                regionList: [],
                distributionList: [],
                regionCachedFor: [],
                distributionCenterCachedFor: [],
            };
        }
        default:
            return state;
    }
};
export default regDistRouteReducer;
