import React from 'react';
const EulaUS = () => {
    const html = `<!--
    &reg;
    &trade; 
    &#8220;  left "
    &#8221;  right "
    &#39;    '
    &sect;   section 
    &#8212;  em dash
     -->
    
    
    <div class=WordSection1>
    
    <p class=MsoPlainText align=center style='text-align:center'><span
    style='font-family:"Courier New"'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-family:"Courier New"'>&nbsp;</span></p>
    
    <p class=MsoPlainText align=center style='text-align:center'><b><span
    style='font-size:12.0pt;font-family:Calibri'>JUMPTRACK&reg; LICENSE AGREEMENT</span></b></p>
    
    <p class=MsoPlainText align=center style='text-align:center'><b><span
    style='font-size:12.0pt;font-family:Calibri'>Version May 2018</span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>IF YOU
    REGISTER FOR A FREE TRIAL FOR OUR SERVICES, THIS AGREEMENT WILL ALSO GOVERN
    THAT FREE TRIAL. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>BY
    ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE
    OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, YOU AGREE TO THE
    TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A
    COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
    BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH
    CASE THE TERMS &quot;YOU&quot; OR &quot;YOUR&quot; SHALL REFER TO SUCH ENTITY
    AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE
    WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT
    USE THE SERVICES. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>You
    may not access the Services if You are Our direct competitor, except with Our
    prior written consent. In addition, You may not access the Services for
    purposes of monitoring their availability, performance or functionality, or for
    any other benchmarking or competitive purposes. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>This
    Agreement was last updated on February 1, 2017. It is effective between You and
    Us as of the date of You accepting this Agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>1.
    DEFINITIONS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Affiliate&quot;</span></b><span
    style='font-size:12.0pt;font-family:Calibri'> means any entity which directly
    or indirectly controls, is controlled by, or is under common control with the
    subject entity. &quot;Control,&quot; for purposes of this definition, means
    direct or indirect ownership or control of more than 50% of the voting
    interests of the subject entity. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&#8220;Delivery
    Routes&#8221;</span></b><span style='font-size:12.0pt;font-family:Calibri'> means a
    grouping of delivery and/or pick up stops of Your goods delivered and/or picked
    up by You or on Your behalf that have been assigned by You in the Service.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&#8220;Locations&#8221;</span></b><span
    style='font-size:12.0pt;font-family:Calibri'> means a specific location whereby
    You wish to track inventory that have been assigned by You in the Service.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Malicious
    Code&quot;</span></b><span style='font-size:12.0pt;font-family:Calibri'> means
    viruses, worms, time bombs, Trojan horses and other harmful or malicious code,
    files, scripts, agents or programs. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Privacy
    Policy&quot;</span></b><span style='font-size:12.0pt;font-family:Calibri'> refers
    to Our privacy policy, posted at <a href="http://www.ecisolutions.com">www.ecisolutions.com</a>,
    as such policy may change from time to time.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Purchased
    Services&quot;</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    means Services that You or Your Affiliates purchase under an Order Form, as
    distinguished from those provided pursuant to a free trial. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Order
    Forms&quot;</span></b><span style='font-size:12.0pt;font-family:Calibri'> means
    the documents for placing orders hereunder, including addenda thereto, that are
    entered into between You and Us or any of Our Affiliates from time to time,
    including addenda and supplements thereto. By accepting and entering into an
    Order Form hereunder, an Affiliate agrees to be bound by the terms of this
    Agreement as if it were an original party hereto. Order Forms shall be deemed
    incorporated herein by reference. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Services&quot;</span></b><span
    style='font-size:12.0pt;font-family:Calibri'> means the products and services
    that are ordered by You under a free trial or an Order Form and made available
    by Us online via the customer login link at http://www.ecisolutions.com and/or
    other web pages designated by Us, including associated offline components, as
    described in the User Guide.&nbsp; Services may include, without limitation,
    JumpTrack&reg; and ExactLoad&trade;.&nbsp; We may change the products, services and
    features which comprise the Services from time to time, in Our sole discretion.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;User
    Guide&quot;</span></b><span style='font-size:12.0pt;font-family:Calibri'> means
    the online user guide for the Services, accessible via login at <a
    href="http://www.ecisolutions.com">www.ecisolutions.com</a> , as updated from
    time to time. You acknowledge that You have had the opportunity to review the
    User Guide during the free trial described in Section 2 (30-Day Free Trial)
    below. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Users&quot;</span></b><span
    style='font-size:12.0pt;font-family:Calibri'> means individuals who are
    authorized by You to use the Services, for whom subscriptions to a Service have
    been ordered, and who have been supplied user identifications and passwords by
    You (or by Us at Your request). Users may include but are not limited to Your
    employees, consultants, contractors and agents, and third parties with which
    You transact business. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;We,&quot;
    &quot;Us&quot; </span></b><span style='font-size:12.0pt;font-family:Calibri'>or
    <b>&quot;Our&quot;</b> means ECI Software Solutions, Inc. described in Section
    12 (Who You Are Contracting With, Notices, Governing Law and Jurisdiction). </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;You&quot;
    </span></b><span style='font-size:12.0pt;font-family:Calibri'>or<b>
    &quot;Your&quot;</b> means the company or other legal entity for which you are
    accepting this Agreement, and Affiliates of that company or entity. &nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&quot;Your
    Data&quot;</span></b><span style='font-size:12.0pt;font-family:Calibri'> means
    all electronic data or information submitted by You to the Purchased Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&#8220;Vendors&#8221;</span></b><span
    style='font-size:12.0pt;font-family:Calibri'> means a business or service whose
    product or service You wish to inventory and/or order through the Service and
    has been assigned You in the Service.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>2.
    FREE TRIAL </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>If You
    register on our website for a free trial, We will make one or more Services
    available to You on a trial basis free of charge until the earlier of (a) the
    end of the free trial period for which you registered or are registering to use
    the applicable Service or (b) the start date of any Purchased Services ordered
    by You. Additional trial terms and conditions may appear on the trial
    registration web page. Any such additional terms and conditions are
    incorporated into this Agreement by reference and are legally binding. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>ANY
    DATA YOU ENTER INTO THE SERVICES, AND ANY CUSTOMIZATIONS MADE TO THE SERVICES
    BY OR FOR YOU, DURING YOUR FREE TRIAL WILL BE PERMANENTLY LOST UNLESS YOU
    PURCHASE A SUBSCRIPTION TO THE SAME SERVICES AS THOSE COVERED BY THE TRIAL,
    PURCHASE UPGRADED SERVICES, OR EXPORT SUCH DATA, BEFORE THE END OF THE TRIAL
    PERIOD. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>NOTWITHSTANDING
    SECTION 8 (WARRANTIES AND DISCLAIMERS), DURING THE FREE TRIAL THE SERVICES ARE
    PROVIDED &#8220;AS-IS&#8221; WITHOUT ANY WARRANTY. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>Please
    review the User Guide during the trial period so that You become familiar with
    the features and functions of the Services before You make Your purchase. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>3.
    PURCHASED SERVICES </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>3.1.
    Provision of Purchased Services.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;We shall make the Purchased Services available to
    You pursuant to this Agreement and the relevant Order Forms during a
    subscription term. You agree that Your purchases hereunder are neither
    contingent on the delivery of any future functionality or features nor
    dependent on any oral or written public comments made by Us regarding future
    functionality or features. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>3.2.
    Subscriptions.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;Unless
    otherwise specified in the applicable Order Form, (i) Services are purchased as
    subscriptions with Services pricing based upon User Subscriptions and/or Delivery
    Route subscriptions, (ii) additional subscriptions may be added during the
    applicable subscription term at the same pricing as that for the pre-existing
    subscriptions thereunder, prorated for the remainder of the subscription term
    in effect at the time the additional subscriptions are added, and (iii) the
    added subscriptions shall terminate on the same date as the pre-existing
    subscriptions.&nbsp; Subscriptions are for designated Users and/or Delivery
    Routes only and cannot be shared, but may be reassigned to new Users replacing
    former Users who no longer require ongoing use of the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.
    USE OF THE SERVICES </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.1.
    Our Responsibilities.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;We shall: (i) provide Our basic support for the Purchased Services to You
    at no additional charge, including telephone and e-mail support, (ii) use
    commercially reasonable efforts to make the Purchased Services available 24
    hours a day, 7 days a week, except for: (a) planned downtime (of which We shall
    give at least 8 hour notice via the Purchased Services and which We shall
    schedule to the extent practicable during the weekend hours from 6:00 p.m.
    Friday to 3:00 a.m. Monday Central Time), or (b) any unavailability caused by
    circumstances beyond Our reasonable control, including without limitation, acts
    of God, acts of government, floods, fires, earthquakes, civil unrest, acts of
    terror, strikes or other labor problems (other than those involving Our
    employees), Internet service provider failures or delays, or denial of service
    attacks, and (iii) provide the Purchased Services only in accordance with
    applicable laws and government regulations. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.2.
    Our Protection of Your Data.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;We shall maintain appropriate administrative,
    physical, and technical safeguards for protection of the security,
    confidentiality and integrity of Your Data.&nbsp; Except as expressly provided
    in this Agreement, we shall not (a) modify Your Data, (b) disclose Your Data except
    as compelled by law in accordance with Section 7.3 (Compelled Disclosure) or
    (c) access Your Data except to provide the Services and prevent or address
    service or technical problems, or at Your request in connection with customer
    support matters.&nbsp;&nbsp; &nbsp;Our Privacy Policy relative to any use of
    Our Services is incorporated herein by this reference.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.3.
    Your Responsibilities.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> You shall (i) be responsible for Users&#39; compliance with this
    Agreement, (ii) be responsible for the accuracy, quality and legality of Your
    Data and of the means by which You acquired Your Data, (iii) use commercially
    reasonable efforts to prevent unauthorized access to or use of the Services,
    and notify Us promptly of any such unauthorized access or use, and (iv) use the
    Services only in accordance with the User Guide and applicable laws and
    government regulations. You shall not (a) make the Services available to anyone
    other than Users, (b) sell, resell, rent or lease the Services, (c) use the
    Services to store or transmit infringing, libelous, or otherwise unlawful or
    tortious material, or to store or transmit material in violation of third-party
    privacy rights, (d) use the Services to store or transmit Malicious Code, (e)
    interfere with or disrupt the integrity or performance of the Services or
    third-party data contained therein, or (f) attempt to gain unauthorized access
    to the Services or their related systems or networks. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.4.
    Usage Limitations.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;Services may be subject to other limitations, such as, for example,
    limits on disk storage space. Any such limitations are specified in the User
    Guide. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.5&nbsp;
    Data Rights.</span></b><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;
    You hereby grant us a perpetual, non-exclusive, fully transferable, worldwide
    right and license to (i) collect Your Data solely in an anonymous format that
    would not permit any recipient of such Your Data to identify You or Your
    customer as the subjects of such Your Data, (ii) aggregate Your Data with other
    data from other users of the Service (in a manner which does not permit
    identification or segregation of Your Data), and (iii) use, disclose, market,
    sell and/or license Your Data (only when it remains anonymous and when
    aggregated with other data) to third parties.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>4.6&nbsp;
    Data Retention Policies.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'>&nbsp; We store Your Data in the Services for three (3) years, meaning
    at any given time You would be able to access the prior three (3) years of
    Data.&nbsp; If you require access to any Data beyond such three (3) year
    period, You will be required to maintain Your own backups thereof at Your sole
    cost and expense or attempt to retrieve such Data from other sources (e.g.,
    Your other software systems that interface with the Services). Relevant
    manifest data can be manually exported from JumpTrack&reg; provided it is done
    within three (3) years. Please see the supporting documentation in JumpTrack&reg;. </span></p>  
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
    line-height:normal'><b><span style='font-size:12.0pt;font-family:Calibri'>4.7
    Google Maps APIs.</span></b><span style='font-size:12.0pt;font-family:Calibri'> 
    You are also bound by the Google Maps/Google Earth Additional Terms of Service,
    which can be found at <span class=MsoHyperlink><a
    href="https://www.google.com/help/terms_maps.html">https://www.google.com/help/terms_maps.html</a></span>
    (including the Google Privacy Policy, which can be found at <span
    class=MsoHyperlink><a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></span>
    .</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></b></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.
    FEES AND PAYMENT FOR PURCHASED SERVICES </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.1.
    Fees.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;You
    shall pay all fees specified in all Order Forms hereunder. Except as otherwise
    specified herein or in an Order Form, (i) fees are based on services purchased
    and not actual usage, (ii) payment obligations are non-cancelable and fees paid
    are non-refundable, and (iii) the number of User subscriptions purchased cannot
    be decreased during the relevant subscription term stated on the Order Form.
    User subscription fees are based on monthly periods that begin on the
    subscription start date and each monthly anniversary thereof; therefore, fees
    for User subscriptions added in the middle of a monthly period will be charged
    for that full monthly period and the monthly periods remaining in the subscription
    term. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.2.
    Invoicing and Payment.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> &nbsp;You will provide Us with valid and updated credit card
    information, or with a valid purchase order or alternative document reasonably
    acceptable to Us. If You provide credit card information to Us, You authorize
    Us to charge such credit card for all Services listed in the Order Form for the
    initial subscription term and any renewal subscription term(s) as set forth in
    Section 11.2 (Term of Purchased User Subscriptions). Such charges shall be made
    in advance, either annually or in accordance with any different billing
    frequency stated in the applicable Order Form. If the Order Form specifies that
    payment will be by a method other than a credit card, We will invoice You in
    advance and otherwise in accordance with the relevant Order Form. Unless
    otherwise stated in the Order Form, invoiced charges are due net 30 days from
    the invoice date. You are responsible for providing complete and accurate
    billing and contact information to Us and notifying Us of any changes to such
    information. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.3.
    Overdue Charges.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;If
    any charges are not received from You by the due date, then at Our discretion,
    (a) such charges may accrue late interest at the rate of 1.5% of the
    outstanding balance per month, or the maximum rate permitted by law, whichever
    is lower, from the date such payment was due until the date paid, and/or (b) We
    may condition future subscription renewals and Order Forms on payment terms
    shorter than those specified in Section 5.2 (Invoicing and Payment). </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.4.
    Suspension of Service and Acceleration.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;If any amount owing by You under this or any other
    agreement for Our services is 30 or more days overdue (or 10 or more days
    overdue in the case of amounts You have authorized Us to charge to Your credit
    card), We may, without limiting Our other rights and remedies, accelerate Your
    unpaid fee obligations under such agreements so that all such obligations
    become immediately due and payable, and suspend Our services to You until such
    amounts are paid in full. We will give You at least 7 days&#39; prior notice that
    Your account is overdue, in accordance with Section 12.2 (Manner of Giving
    Notice), before suspending services to You. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.5.
    Payment Disputes.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;We shall not exercise Our rights under Section 5.3 (Overdue Charges) or 5.4
    (Suspension of Service and Acceleration) if You are disputing the applicable
    charges reasonably and in good faith and are cooperating diligently to resolve
    the dispute. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>5.6.
    Taxes.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;Unless
    otherwise stated, Our fees do not include any taxes, levies, duties or similar
    governmental assessments of any nature, including but not limited to
    value-added, sales, use or withholding taxes, assessable by any local, state,
    provincial, federal or foreign jurisdiction (collectively, &quot;<u>Taxes</u>&quot;).
    You are responsible for paying all Taxes associated with Your purchases
    hereunder. If We have the legal obligation to pay or collect Taxes for which
    You are responsible under this paragraph, the appropriate amount shall be
    invoiced to and paid by You, unless You provide Us with a valid tax exemption
    certificate authorized by the appropriate taxing authority. For clarity, We are
    solely responsible for taxes assessable against it based on Our income,
    property and employees. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>6.
    PROPRIETARY RIGHTS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>6.1.
    Reservation of Rights in Services.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;Subject to the limited rights expressly granted
    hereunder, We reserve all rights, title and interest in and to the Services,
    including all related intellectual property rights. No rights are granted to
    You hereunder other than as expressly set forth herein. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>6.2.
    Restrictions.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;You
    shall not (i) permit any third party to access the Services except as permitted
    herein or in an Order Form, (ii) modify, copy or create derivate works based on
    the Services except as authorized herein, (iii) copy, frame or mirror any part
    or content of the Services, other than copying or framing on Your own intranets
    or otherwise for Your own internal business purposes, (iv) reverse engineer the
    Services, or (v) access the Services in order to (a) build a competitive product
    or service, (b) build a product or service using similar ideas, features,
    functions or graphics of the Service, or (c) copy any ideas, features,
    functions or graphics of the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>6.3.
    Suggestions.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;We
    shall have a royalty-free, worldwide, irrevocable, perpetual license to use and
    incorporate into the Services any suggestions, enhancement requests,
    recommendations or other feedback provided by You, including Users, relating to
    the operation of the Services. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>6.4.
    Federal Government End Use Provisions.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;We provide the Services, including related software
    and technology, for ultimate federal government end use solely in accordance
    with the following: Government technical data and software rights related to
    the Services include only those rights customarily provided to the public as
    defined in this Agreement. This customary commercial license is provided in
    accordance with FAR 12.211 (Technical Data) and FAR 12.212 (Software) and, for
    Department of Defense transactions, DFAR 252.227-7015 (Technical Data &#8212;
    Commercial Items) and DFAR 227.7202-3 (Rights in Commercial Computer Software
    or Computer Software Documentation). If a government agency has a need for
    rights not conveyed under these terms, it must negotiate with Us to determine
    if there are acceptable terms for transferring such rights, and a mutually
    acceptable written addendum specifically conveying such rights must be included
    in any applicable contract or agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>7.
    CONFIDENTIALITY </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>7.1.
    Definition of Confidential Information.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;As used herein, &quot;<u>Confidential Information</u>&quot;
    means all confidential information disclosed by a party (&quot;<u>Disclosing
    Party</u>&quot;) to the other party (&quot;<u>Receiving Party</u>&quot;),
    whether orally or in writing, that is designated as confidential or that
    reasonably should be understood to be confidential given the nature of the
    information and the circumstances of disclosure. Your Confidential Information
    shall include Your Data; Our Confidential Information shall include the
    Services; and Confidential Information of each party shall include the terms
    and conditions of this Agreement and all Order Forms, as well as business and
    marketing plans, technology and technical information, product plans and
    designs, and business processes disclosed by such party. However, Confidential
    Information (other than Your Data) shall not include any information that (i)
    is or becomes generally known to the public without breach of any obligation
    owed to the Disclosing Party, (ii) was known to the Receiving Party prior to
    its disclosure by the Disclosing Party without breach of any obligation owed to
    the Disclosing Party, (iii) is received from a third party without breach of
    any obligation owed to the Disclosing Party, or (iv) was independently
    developed by the Receiving Party. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>7.2.
    Protection of Confidential Information.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;The Receiving Party shall use the same degree of
    care that it uses to protect the confidentiality of its own confidential
    information of like kind (but in no event less than reasonable care) (i) not to
    use any Confidential Information of the Disclosing Party for any purpose
    outside the scope of this Agreement, and (ii) except as otherwise authorized by
    the Disclosing Party in writing, to limit access to Confidential Information of
    the Disclosing Party to those of its and its Affiliates&#39; employees, contractors
    and agents who need such access for purposes consistent with this Agreement and
    who have signed confidentiality agreements with the Receiving Party containing
    protections no less stringent than those herein. Neither party shall disclose
    the terms of this Agreement or any Order Form to any third party other than its
    Affiliates and their legal counsel and accountants without the other party&#39;s
    prior written consent. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>7.3.
    Compelled Disclosure.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;The Receiving Party may disclose Confidential Information of the
    Disclosing Party if it is compelled by law to do so, provided the Receiving
    Party gives the Disclosing Party prior notice of such compelled disclosure (to
    the extent legally permitted) and reasonable assistance, at the Disclosing Party's
    cost, if the Disclosing Party wishes to contest the disclosure. If the
    Receiving Party is compelled by law to disclose the Disclosing Party&#39;s
    Confidential Information as part of a civil proceeding to which the Disclosing
    Party is a party, and the Disclosing Party is not contesting the disclosure,
    the Disclosing Party will reimburse the Receiving Party for its reasonable cost
    of compiling and providing secure access to such Confidential Information. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>7.4&nbsp;
    Remedies.&nbsp; </span></b><span style='font-size:12.0pt;font-family:Calibri'>If
    the Receiving Party discloses or uses (or threatens to disclose or use) any
    Confidential Information of the Disclosing Party in breach of this Section 7,
    the Disclosing Party shall have the right, in addition to any other remedies
    available to it, to seek injunctive relief to enjoin such acts, it being
    specifically acknowledged by the parties that any other available remedies are
    inadequate. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>8.
    WARRANTIES AND DISCLAIMERS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>8.1.
    Our Warranties.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;We
    warrant that (i) We have validly entered into this Agreement and have the legal
    power to do so, (ii) the Services shall perform materially in accordance with
    the User Guide, (iii) the functionality of the Services will not be materially
    decreased during a subscription term, and (iv) We will not transmit Malicious
    Code to You, provided it is not a breach of this subpart (iv) if You or a User
    uploads a file containing Malicious Code into the Services and later downloads
    that file containing Malicious Code. For any breach of a warranty above, Your
    exclusive remedy shall be as provided in Section 11.3 (Termination for Cause)
    and Section 11.4 (Refund or Payment upon Termination) below. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>8.2.
    Your Warranties.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;You
    warrant that You have validly entered into this Agreement and have the legal
    power to do so. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>8.3.
    Disclaimer.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    EXCEPT AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTIES OF ANY
    KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH PARTY
    SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY WARRANTIES OF
    MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TO THE MAXIMUM EXTENT
    PERMITTED BY APPLICABLE LAW. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>9.
    MUTUAL INDEMNIFICATION </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>9.1.
    Indemnification by Us.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> &nbsp;We shall defend You against any claim, demand, suit, or
    proceeding made or brought against You by a third party alleging that the
    Services infringes or misappropriates the intellectual property rights of a
    third party (an &quot;<u>Infringement Claim</u>&quot;), and shall indemnify You
    for any damages, reasonable attorney fees and costs finally awarded against You
    as a result of, and for amounts paid by You under a court-approved settlement
    of, an Infringement Claim; provided that You (a) promptly give Us written
    notice of the Infringement Claim; (b) give Us sole control of the defense and
    settlement of the Infringement Claim (provided that We may not settle any Infringement
    Claim unless the settlement unconditionally releases You of all liability); and
    (c) provide to Us all reasonable assistance, at Our expense. In the event of an
    Infringement Claim, or if We reasonably believe the Services may infringe or
    misappropriate, We may in Our discretion and at no cost to You (i) modify the
    Services so that they no longer infringe or misappropriate, without breaching
    Our warranties under &#8220;Our Warranties&#8221; above, (ii) obtain a license for Your
    continued use of the Services in accordance with this Agreement, or (iii)
    terminate Your User subscriptions for such Services upon 30 days&#39; written
    notice and refund to You any prepaid fees covering the remainder of the term of
    such User subscriptions after the effective date of termination. This Section
    states Your sole and exclusive remedy, and Our sole and exclusive obligation,
    with respect to a Infringement Claim. We shall have no obligations under this
    Section to the extent that You modify (or have modified on Your behalf) the Services
    or combine the Services with other software, hardware or other technology not
    provided by Us.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>9.2.
    Indemnification by You.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> &nbsp;You shall defend Us, our Affiliates and each of their
    respective officers, directors, employees and agents against any and all claims,
    demands, suits or proceedings made or brought against them by a third party relating
    to Your use of the Services (other than an Infringement Claim) or alleging that
    Your Data, or Your use of the Services in breach of this Agreement, infringes
    or misappropriates the intellectual property rights of a third party or
    violates applicable law (a &quot;<u>Claim Against Us</u>&quot;), and shall
    indemnify Us, our Affiliates and each of their respective officers, directors,
    employees and agents for any damages, attorney fees and costs finally awarded
    against Us as a result of, or for any amounts paid by Us under a court-approved
    settlement of, a Claim Against Us; provided that We (a) promptly give You
    written notice of the Claim Against Us; (b) give You sole control of the
    defense and settlement of the Claim Against Us (provided that You may not
    settle any Claim Against Us unless the settlement unconditionally releases Us
    of all liability); and (c) provide to You all reasonable assistance, at Your
    expense. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>10.
    LIMITATION OF LIABILITY </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>10.1.
    Limitation of Liability.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> &nbsp;WE SHALL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL,
    CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT
    LIMITATION ANY LOSS OF REVENUE, PROFITS, OR DATA, ARISING IN CONNECTION WITH
    THIS AGREEMENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
    DAMAGES. YOU ARE SOLELY RESPONSIBLE FOR THE USE, OPERATION, SUPPORT AND
    MAINTENANCE OF ALL THIRD PARTY PRODUCTS AND SERVICES, AND WE SHALL HAVE NO
    LIABILITY OR RESPONSIBILITY THEREFOR. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE OR
    LOSSES RESULTING FROM USE OF THIRD PARTY PRODUCTS AND SERVICES. THE USE OF
    THIRD PARTY PRODUCTS AND SERVICES IS AT YOUR SOLE RISK. OUR MAXIMUM CUMULATIVE
    LIABILITY FOR ANY AND ALL DAMAGES, COSTS, LOSSES, LIABILITIES AND EXPENSES OF
    ANY KIND AND NATURE ARISING OUT OF OR RELATED TO THIS AGREEMENT SHALL NOT
    EXCEED THE TOTAL FEES PAID BY YOU UNDER THIS AGREEMENT IN THE ONE (1) YEAR
    PERIOD IMMEDIATELY PRECEDING THE DATE THE CLAIM AROSE.</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></b></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>10.2&nbsp;
    Limitation of Action.&nbsp; </span></b><span style='font-size:12.0pt;
    font-family:Calibri'>Except for actions for non-payment or breach of either
    party's intellectual property rights, no action (regardless of form) arising
    out of this Agreement may be commenced by either party more than two (2) years
    after the cause of action has accrued. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.
    TERM AND TERMINATION </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.1.
    Term of Agreement. &nbsp;</span></b><span style='font-size:12.0pt;font-family:
    Calibri'>This Agreement commences on the date You accept it and continues until
    all User subscriptions granted in accordance with this Agreement have expired
    or been terminated. If You elect to use the Services for a free trial period
    and do not purchase a subscription before the end of that period, this
    Agreement will terminate at the end of the free trial period. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.2.
    Term of Purchased User Subscriptions.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;User subscriptions purchased by You commence on the
    start date specified in the applicable Order Form and continue for the
    subscription term specified therein. Except as otherwise specified in the
    applicable Order Form, all User subscriptions shall automatically renew for
    additional periods equal to the expiring subscription term or one year
    (whichever is shorter), unless either party gives the other notice of
    non-renewal at least 30 days before the end of the relevant subscription term.
    The pricing during any such renewal term shall be the same as that during the
    prior term unless We have given You written notice of a pricing increase at
    least 30 days before the end of such prior term, in which case the pricing
    increase shall be effective upon renewal and thereafter. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.3.
    Termination for Cause.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> &nbsp;A party may terminate this Agreement for cause: (i) upon 30
    days written notice to the other party of a material breach if such breach
    remains uncured at the expiration of such period, or (ii) if the other party
    becomes the subject of a petition in bankruptcy or any other proceeding relating
    to insolvency, receivership, liquidation or assignment for the benefit of
    creditors. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.4.
    Refund or Payment upon Termination.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;Upon any termination for cause by You, We shall
    refund You any prepaid fees covering the remainder of the term of all subscriptions
    after the effective date of termination. Upon any termination for cause by Us,
    You shall pay any unpaid fees covering the remainder of the term of all Order
    Forms after the effective date of termination. In no event shall any
    termination relieve You of the obligation to pay any fees payable to Us for the
    period prior to the effective date of termination. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.5.
    Return of Your Data.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;Upon request by You made within 30 days after the effective date of
    termination of a Purchased Services subscription, We will make available to You
    for download a file of Your Data for the three (3) year period immediately
    preceding Our receipt of your request for Your Data, which will be provided in
    comma separated value (.csv) format. After such 30-day period, We shall have no
    obligation to maintain or provide any of Your Data and shall thereafter, unless
    legally prohibited, delete all of Your Data in Our systems or otherwise in Our
    possession or under Our control. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>11.6.
    Surviving Provisions. &nbsp;</span></b><span style='font-size:12.0pt;
    font-family:Calibri'>Section 5 (Fees and Payment for Purchased Services), 6
    (Proprietary Rights), 7 (Confidentiality), 8.3 (Disclaimer), 9 (Mutual
    Indemnification), 10 (Limitation of Liability), 11.4 (Refund or Payment upon
    Termination), 11.5 (Return of Your Data), 12 (Who You Are Contracting With, Notices,
    Governing Law and Jurisdiction) and 13 (General Provisions) shall survive any
    termination or expiration of this Agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>12.
    WHO YOU ARE CONTRACTING WITH, NOTICES, GOVERNING LAW AND JURISDICTION </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>12.1.
    General.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;Under
    this Agreement, You are contracting with ECI Software Solutions, Inc., a
    Delaware corporation.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>12.2.
    Manner of Giving Notice.</span></b><span style='font-size:12.0pt;font-family:
    Calibri'> &nbsp;Except as otherwise specified in this Agreement, all notices,
    permissions and approvals hereunder shall be in writing and shall be deemed to
    have been given upon: (i) personal delivery, (ii) the second business day after
    mailing, (iii) the second business day after sending by confirmed facsimile, or
    (iv) the first business day after sending by email (provided email shall not be
    sufficient for notices of termination or an indemnifiable claim).
    Billing-related notices to You shall be addressed to the relevant billing
    contact designated by You. All other notices to You shall be addressed to the
    relevant Services system administrator designated by You. &nbsp;&nbsp;You shall
    send a copy of all notices you send to us in connection with this Agreement to:
    ECI Software Solutions, Inc., Attn: SVP and General Counsel, 4400 Alliance
    Gateway Freeway, Suite 154, Fort Worth, Texas 76177.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>12.3.
    Agreement to Governing Law and Jurisdiction.</span></b><span style='font-size:
    12.0pt;font-family:Calibri'> &nbsp;This Agreement shall be governed and
    construed in accordance with the laws of the State of Texas, without regard to
    its conflict of laws provisions.&nbsp; The parties agree that exclusive
    jurisdiction for any dispute arising under or related to this Agreement shall
    be with the appropriate state or federal court in Dallas, Texas and each party
    consents to the jurisdiction of such court in any action or proceeding and
    waives any objection to such venue. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>12.4.
    Waiver of Jury Trial.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;TO THE FULLEST EXTENT PERMITTED BY LAW, AND AS SEPARATELY BARGAINED-FOR
    CONSIDERATION, EACH PARTY HEREBY WAIVES ANY RIGHT TO TRIAL BY JURY IN ANY
    ACTION, SUIT, PROCEEDING, OR COUNTERCLAIM OF ANY KIND ARISING OUT OF OR
    RELATING TO THIS AGREEMENT. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.
    GENERAL PROVISIONS </span></b></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.1.
    Export Compliance.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;&#8220;Export Control Laws&#8221; mean (i) United States export control laws and
    United States laws governing exports of controlled commodities, software or
    technology, embargoes, sanctions and boycotts, including, but not limited to,
    the Arms Export Controls Act (22 U.S.C. &sect; 2778 and 2779), the International
    Emergency Economic Powers Act (50 U.S.C. &sect; 1701 et. seq.), the Trading With the
    Enemy Act (50 U.S.C. app. &sect; 1 et. seq.), the Export Administration Act of 1979
    (50 U.S.C. app. &sect; 2401-2420), and all rules, regulations and executive orders
    relating to any of the foregoing, including, but not limited to, the
    International Traffic in Arms Regulations (22 C.F.R. &sect; 120 et. seq.), the
    Export Administration Regulations (15 C.F.R. &sect; 730 et. seq.), and the
    regulations administered by the Office of Foreign Assets Control of the United
    States Department of the Treasury, and (ii) all similar applicable laws,
    statutes and regulations in countries where Licensee conducts business.&nbsp;
    You represent and warrant to Us that You shall perform Your obligations under
    this Agreement in compliance with all applicable Export Control Laws.&nbsp; You
    agrees to obtain all necessary licenses, approvals, or authorizations from
    relevant governmental authorities prior to exporting or re-exporting any
    products, including hardware, software, technology, or technical data, acquired
    or to be provided under this Agreement, or the direct product of any such
    hardware, software, technology, or technical data (collectively, the &#8220;Items&#8221;).&nbsp;
    You agree that You (i) shall not export, re-export, or transfer any Items to
    any country that is designated a supporter of international terrorism, or that
    is subject to an embargo by the U.S. Government (currently Cuba, Iran, North
    Korea, Sudan, and Syria); (ii) shall not engage in any transactions or dealings
    with any person identified on the List of Specially Designated Nationals and
    Blocked Persons (&#8220;SDNs&#8221;), which is maintained by the Office of Foreign Assets
    Control of the U.S. Treasury Department, or the Entity List, Denied Persons
    List, or Unverified List, which are maintained by the Bureau of Industry and
    Security of the U.S. Commerce Department; and (iii) shall immediately notify Us
    if You have any information or suspicion that there may be a violation of
    Export Control Laws.</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;<b>13.2.
    Anti-Corruption.</b> &nbsp;You have not received or been offered any illegal or
    improper bribe, kickback, payment, gift, or thing of value from any of Our
    employees or agents in connection with this Agreement. Reasonable gifts and entertainment
    provided in the ordinary course of business do not violate the above
    restriction. If You learn of any violation of the above restriction, You will
    use reasonable efforts to promptly notify Our Legal Department (legal@ecisolutions.com).
    </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.3.
    Relationship of the Parties.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;The parties are independent contractors. This
    Agreement does not create a partnership, franchise, joint venture, agency,
    fiduciary or employment relationship between the parties. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.4.
    No Third-Party Beneficiaries.</span></b><span style='font-size:12.0pt;
    font-family:Calibri'> &nbsp;There are no third-party beneficiaries to this
    Agreement. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.5.
    Waiver.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;No
    failure or delay by either party in exercising any right under this Agreement
    shall constitute a waiver of that right. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.6.
    Severability.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;If
    any provision of this Agreement is held by a court of competent jurisdiction to
    be contrary to law, the provision shall be modified by the court and
    interpreted so as best to accomplish the objectives of the original provision
    to the fullest extent permitted by law, and the remaining provisions of this
    Agreement shall remain in effect. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.7.
    Attorney Fees.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;You
    shall pay on demand all of Our reasonable attorney fees and other costs
    incurred by Us to collect any fees or charges due Us under this Agreement
    following Your breach of Section 5.2 (Invoicing and Payment). </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.8.
    Assignment.</span></b><span style='font-size:12.0pt;font-family:Calibri'> &nbsp;You
    shall not sell, transfer, assign or otherwise convey this Agreement, the Services
    or any of Your rights or obligations hereunder without Our prior written
    consent, including without limitation by operation of law to a successor in
    interest in connection with a merger, consolidation, sale of shares or assets
    or other change of control transaction involving You.&nbsp; The purported
    transferee shall first be required to agree in writing with Us to assume and
    perform all of Your obligations under this Agreement. Any attempt to assign
    this Agreement without Our prior written consent shall be void. This Agreement
    shall be binding upon and inure to the benefit of the parties and their
    respective successors and permitted assigns. </span></p>
    
    <p class=MsoPlainText><span style='font-size:12.0pt;font-family:Calibri'>&nbsp;</span></p>
    
    <p class=MsoPlainText><b><span style='font-size:12.0pt;font-family:Calibri'>13.9.
    Entire Agreement.</span></b><span style='font-size:12.0pt;font-family:Calibri'>
    &nbsp;This Agreement, including all exhibits and addenda hereto and all Order
    Forms, constitutes the entire agreement between the parties and supersedes all
    prior and contemporaneous agreements, proposals or representations, written or
    oral, concerning its subject matter. &nbsp;We may amend this Agreement from
    time to time by posting an amended version at Our Website and sending You
    written notice thereof, which may include written notice to Your email address
    on file with us. Such amendment will be deemed accepted and become effective 30
    days after such notice (the &#8220;Proposed Amendment Date&#8221;) unless You first give Us
    written notice of rejection of the amendment. In the event of such rejection,
    this Agreement will continue under its original provisions, and the amendment
    will become effective at the start of Your next Term following the Proposed
    Amendment Date (unless You first terminates this Agreement pursuant to Section 11
    above). &nbsp;Your continued use of the Services following the effective date
    of an amendment will confirm Your consent thereto. Notwithstanding the
    foregoing, We may amend the Privacy Policy at any time by posting a new version
    at Our Website and sending You notice thereof, and such amended version will
    become effective 10 business days after such notice is sent. &nbsp;However, to
    the extent of any conflict or inconsistency between the provisions in the body
    of this Agreement and any exhibit or addendum hereto or any Order Form, the
    terms of this Agreement shall prevail. Notwithstanding any language to the
    contrary therein, no terms or conditions stated in Your purchase order or other
    order documentation (excluding Order Forms) shall be incorporated into or form
    any part of this Agreement, and all such terms or conditions shall be null and
    void. </span></p>
    
    </div>
    
    
    `;
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default EulaUS;
