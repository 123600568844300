import React from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { hideNotificationMessage } from 'redux/actions/systemMessageActions';

const SystemNotifications = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.systemMessages.notifications);
    const hideIndex = (index) => {
        dispatch(hideNotificationMessage(index));
    };
    return (
        <div className="systemNotifications">
            {notifications.map((item, index) => (
                <Toast
                    key={'toast-' + index}
                    onClose={() => hideIndex(index)}
                    show={true}
                    delay={10000}
                    autohide
                    className={'alert-' + item.type}>
                    <Toast.Header>
                        <strong className="mr-auto">{item.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{item.text}</Toast.Body>
                </Toast>
            ))}
        </div>
    );
};

export default SystemNotifications;
