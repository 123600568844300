import axios from 'axios';
import { POST_LOGIN, POST_LOGOUT } from './CONSTANTS';
/**
 * Function to do a Login Attempt.
 */
export const sendLogin = (login, passwd, twoFactorCode, controller) => {
    console.log('authServices > sendLogin called...');
    return new Promise((resolve, reject) => {
        const params = { login, passwd };
        if (twoFactorCode) {
            params.twoFactor = twoFactorCode;
        }
        axios
            .post(POST_LOGIN, params, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('sendLogin > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('sendLogin > axios err=', err);
                reject({
                    message: 'Error in sendLogin axios!',
                    error: err,
                    data: err.response?.data,
                });
            });
    });
};
export const sendLogout = (controller) => {
    console.log('authServices > sendLogout called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                POST_LOGOUT,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('sendLogout > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('sendLogout > axios err=', err);
                reject({
                    message: 'Error in sendLogout axios!',
                    error: err,
                    data: err.response?.data,
                });
            });
    });
};
