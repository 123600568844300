import { getTranslationByName, USANDC } from 'i18n/Translations';
import { LOGIN } from 'navigation/CONSTANTS';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'redux/actions/loadingActions';
import { userLoggedOut } from 'redux/actions/loginActions';
import { showErrorMessage } from 'redux/actions/systemMessageActions';
import { clearUserInfo, fetchUserInformation } from 'redux/actions/userActions';
import { postAcceptRequestLicense } from 'services';
import { sendLogout } from 'services/authServices';
import EulaUK from './eula/EulaUK';
import EulaUS from './eula/EulaUS';
const EulaModal = () => {
    const dispatch = useDispatch();
    const fetchUserInfoController = useRef(new AbortController());
    const logoutController = useRef(new AbortController());
    const acceptEulaController = useRef(new AbortController());

    //#region selector hooks
    const showEula = useSelector((state) => state.userinfo.showEula);
    const translation = useSelector((state) => state.userinfo.affiliate.translation);
    //#endregion

    //#region state hooks
    const [eulaAccepted, setEulaAccepted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showEulaError, setShowEulaError] = useState(false);
    //#endregion

    //#region useEffect hooks
    useEffect(() => {
        return () => {
            fetchUserInfoController.current.abort();
            logoutController.current.abort();
            acceptEulaController.current.abort();
        };
    }, []);
    //#endregion

    //#region Events
    const onCancelClick = () => {
        dispatch(userLoggedOut());
        dispatch(clearUserInfo());

        sendLogout(logoutController)
            .then(() => {
                window.location = LOGIN;
            })
            .catch((error) => {
                dispatch(showErrorMessage({ message: error.message, error }));
            });
    };

    const onSaveSubmit = (e) => {
        const form = e.currentTarget;
        let isValid = form.checkValidity();
        e.preventDefault();
        e.stopPropagation();

        if (!eulaAccepted) {
            setShowEulaError(true);
            isValid = false;
        }

        setValidated(true);
        if (!isValid) {
            return;
        }
        doSave();
    };
    const onChangeEulaAccepted = (e) => {
        setEulaAccepted(e.target.checked);
    };
    const onShowModal = () => {
        setValidated(false);

        setShowEulaError(false);
        setEulaAccepted(false);
    };
    //#endregion
    //#region Helpers

    const doSave = () => {
        const version = process.env.REACT_APP_EULA_VERSION;
        const location = getTranslationByName(translation);

        const product = location.eulaProduct;
        dispatch(showLoading());

        postAcceptRequestLicense(product, version, acceptEulaController)
            .then((response) => {
                if (response === 'success') {
                    dispatch(fetchUserInformation(true, fetchUserInfoController));
                }
            })
            .catch((error) => {
                dispatch(
                    showErrorMessage({
                        message:
                            'Could not communicate with the server. Please try again in a few moments',
                        error,
                    })
                );
            })
            .finally(() => {
                dispatch(hideLoading());
            });
    };
    //#endregion
    return (
        <Modal
            dialogClassName="modal-70vw"
            show={showEula}
            onShow={onShowModal}
            onHide={onCancelClick}>
            <Form noValidate validated={validated} onSubmit={onSaveSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body className="messagesContainer">
                    <Alert variant="danger">
                        <ExclamationTriangleFill size={24} />
                        <div>
                            You must agree to these terms before continuing.
                            {showEulaError && (
                                <>
                                    <br />
                                    <b>
                                        {' '}
                                        Check &quot;I Accept&quot; to agree and click &quot;OK&quot;{' '}
                                    </b>
                                </>
                            )}
                        </div>
                    </Alert>
                    <div id="userwarning" className="user-terms">
                        {translation === USANDC.name ? <EulaUS /> : <EulaUK />}
                    </div>
                    <div className="text-center">
                        <Form.Check
                            type="checkbox"
                            id="eulaAccepted"
                            label="I Accept"
                            checked={eulaAccepted}
                            onChange={onChangeEulaAccepted}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center w-100">
                        <Button variant="secondary" onClick={onCancelClick}>
                            Cancel
                        </Button>
                        &nbsp;
                        <Button variant="primary" type="submit">
                            OK
                        </Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default EulaModal;
