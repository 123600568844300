import { NETWORK_ERROR } from 'config/CONSTANTS';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'redux/actions/loadingActions';
import { showErrorMessage } from 'redux/actions/systemMessageActions';
import { postGetPrintStatusInfo } from 'services';
import { createGlobalStyle } from 'styled-components';
import { userDateTime } from 'utils/JTUtils';
import {
    PRINT_ACCOUNT_KEY,
    PRINT_DELIVERY_ID_KEY,
    PRINT_DISPLAY_CD_KEY,
    PRINT_SHOW_BIGGER_KEY,
    PRINT_VENDOR_ID_KEY,
} from 'utils/storage/CONSTANTS';
import { getLocalStorage, removeLocalStorage } from 'utils/storage/LocalStorageHelper';
import { isResponseFailed } from 'utils/serviceUtils';
import { fetchUserInformation } from 'redux/actions/userActions';
const StatusPrintPage = () => {
    const dispatch = useDispatch();
    const printStatusController = useRef(new AbortController());
    const fetchUserInfoController = useRef(new AbortController());

    //#region selector hooks
    const contactCompany = useSelector((state) => state.userinfo.affiliate.contactCompany);

    //#endregion
    //#region state hooks
    const [vendorId, setVendorId] = useState('');
    const [account, setAccount] = useState('');
    const [deliveryId, setDeliveryId] = useState('');
    const [displayCd, setDisplayCd] = useState('');
    const [preloadData, setPreloadData] = useState(false);
    const [showBiggerPhoto, setShowBiggerPhoto] = useState(false);
    const [delivery, setDelivery] = useState(null);
    const [isPhotoBig, setIsPhotoBig] = useState(false);
    //#endregion

    //#region useEffect hooks
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        document.title = 'JumpTrack | Delivery Status Detail';
        return () => {
            printStatusController.current.abort();
        };
    }, []);
    useEffect(() => {
        let receivedValues = false;
        const selectedVendorId = getLocalStorage(PRINT_VENDOR_ID_KEY);
        const selectedAccount = getLocalStorage(PRINT_ACCOUNT_KEY);
        const selectedDeliveryId = getLocalStorage(PRINT_DELIVERY_ID_KEY);
        const selectedDisplayCd = getLocalStorage(PRINT_DISPLAY_CD_KEY);
        const selectedShowBigger = getLocalStorage(PRINT_SHOW_BIGGER_KEY);
        if (selectedVendorId) {
            setVendorId(getLocalStorage(PRINT_VENDOR_ID_KEY));
            receivedValues = true;
        }
        if (selectedAccount) {
            setAccount(getLocalStorage(PRINT_ACCOUNT_KEY));
            receivedValues = true;
        }
        if (selectedDeliveryId) {
            setDeliveryId(getLocalStorage(PRINT_DELIVERY_ID_KEY));
            receivedValues = true;
        }
        if (selectedDisplayCd) {
            setDisplayCd(getLocalStorage(PRINT_DISPLAY_CD_KEY));
            receivedValues = true;
        }
        if (selectedShowBigger) {
            setShowBiggerPhoto(getLocalStorage(PRINT_SHOW_BIGGER_KEY) === 'true');
            receivedValues = true;
        }
        if (receivedValues) {
            setPreloadData(true);
        }
        return () => {
            removeLocalStorage(PRINT_VENDOR_ID_KEY);
            removeLocalStorage(PRINT_ACCOUNT_KEY);
            removeLocalStorage(PRINT_DELIVERY_ID_KEY);
            removeLocalStorage(PRINT_DISPLAY_CD_KEY);
            removeLocalStorage(PRINT_SHOW_BIGGER_KEY);
        };
    }, []);
    useEffect(() => {
        if (!preloadData) {
            return;
        }
        fetchStatusPageInfo();
    }, [preloadData]);
    useEffect(() => {
        dispatch(fetchUserInformation(false, fetchUserInfoController));
        return () => {
            fetchUserInfoController.current.abort();
        };
    }, [dispatch]);
    /* eslint-enable */
    //#endregion
    //#region Helpers
    const fetchStatusPageInfo = () => {
        dispatch(showLoading());
        postGetPrintStatusInfo({
            vendorId,
            account,
            deliveryId,
            displayCd,
            controller: printStatusController,
        })
            .then((res) => {
                if (isResponseFailed(res)) {
                    const error = res.response.errors.error;
                    dispatch(showErrorMessage({ message: error.message }));
                    return;
                }
                let theDelivery = res.response.result.deliverys.delivery;
                if (
                    theDelivery.detail['driver-photo'] &&
                    !Array.isArray(theDelivery.detail['driver-photo'])
                ) {
                    theDelivery.detail['driver-photo'] = [theDelivery.detail['driver-photo']];
                }
                if (
                    theDelivery['delivery-lines']['delivery-line'] &&
                    !Array.isArray(theDelivery['delivery-lines']['delivery-line'])
                ) {
                    theDelivery['delivery-lines']['delivery-line'] = [
                        theDelivery['delivery-lines']['delivery-line'],
                    ];
                }
                setDelivery(theDelivery);
            })
            .catch((error) => {
                console.log(error);
                dispatch(showErrorMessage({ message: NETWORK_ERROR, error }));
            })
            .finally(() => {
                dispatch(hideLoading());
            });
    };
    //#endregion
    //#region Events
    const onPrintClick = () => {
        window.print();
    };
    const onCloseClick = () => {
        // when opened on target blank
        if (history.length == 1) {
            window.close();
        } else {
            window.history.back();
        }
    };
    //#endregion
    return (
        <>
            <div id="container1" className="centered">
                <div id="systemMessage"></div>
                <div id="branding">
                    <h2 className="floatLeft margin-top-10px margin-left-10px">
                        {contactCompany} Delivery Status Detail
                    </h2>
                </div>
                {delivery && (
                    <>
                        <div className="blackborder alignLeft">
                            <table
                                id="deliveryStatusReport"
                                className="deliveryStatusReport printStatus jumptrack mb-2">
                                <thead>
                                    <tr>
                                        <th>Delivery Id</th>
                                        <th>P.O. #</th>
                                        <th>Customer #</th>
                                        <th>Customer Name</th>
                                        <th>Route</th>
                                        <th>Date Delivered</th>
                                        <th>Order Status</th>
                                        <th>Signed by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span id="display-cd">{delivery['display-cd']}</span>
                                        </td>
                                        <td>
                                            <span id="po-number">{delivery['po-number']}</span>
                                        </td>
                                        <td>
                                            <span id="customer-number">
                                                {delivery.site['customer-number']}
                                            </span>
                                        </td>
                                        <td>
                                            <span id="customer-name">
                                                {delivery.site['customer-name']}
                                            </span>
                                        </td>
                                        <td>
                                            <span id="route">{delivery.route._}</span>
                                        </td>
                                        <td>
                                            <span id="date-signed">
                                                {userDateTime(delivery['date-display'].date)}
                                            </span>
                                        </td>
                                        <td>
                                            <span id="status">{delivery.status._}</span>
                                        </td>
                                        <td>
                                            <span id="signed-by">
                                                {delivery.sig && delivery.sig.name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="details">
                                        <td colSpan="9">
                                            <div className="details">
                                                <div className="column1">
                                                    <div>
                                                        <label>Time Accepted:&nbsp;</label>
                                                        <span id="sign-time">
                                                            {delivery.sig && delivery.sig.time
                                                                ? delivery.sig.time.replace(
                                                                      /^.* /,
                                                                      ''
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <label>Delivery Notes:&nbsp;</label>
                                                        {delivery.detail['delivery-note'] && (
                                                            <span id="delivery-note">
                                                                {
                                                                    delivery.detail['delivery-note']
                                                                        .value
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label>Driver Notes:&nbsp;</label>
                                                        {delivery.detail['driver-note'] && (
                                                            <span id="driver-note">
                                                                {
                                                                    delivery.detail['driver-note']
                                                                        .value
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="column2">
                                                    <label>Address:</label>
                                                    <div id="address1">
                                                        {delivery.site.address1}
                                                    </div>
                                                    <div id="address2">
                                                        {delivery.site.address2}
                                                    </div>
                                                    <div id="address3">
                                                        {delivery.site.address3}
                                                    </div>
                                                    <div id="city-state-zip">
                                                        {delivery.site.city},{' '}
                                                        {delivery.site['state-province']}{' '}
                                                        {delivery.site['postal-code']}
                                                    </div>
                                                </div>
                                                <div className="column3">
                                                    <label>Signature:</label>
                                                    <br />
                                                    {delivery.sig && (
                                                        <img
                                                            id="sig-image"
                                                            className="signature"
                                                            src={delivery.sig.image}
                                                            alt="signature"
                                                        />
                                                    )}
                                                </div>
                                                <div className="column4">
                                                    <label>
                                                        Photo:{' '}
                                                        {showBiggerPhoto &&
                                                        delivery.detail['driver-photo'] ? (
                                                            <button
                                                                className="btn-link btn btn-sm"
                                                                id="toggle-photo-size"
                                                                onClick={() => {
                                                                    setIsPhotoBig(!isPhotoBig);
                                                                }}
                                                                title="Show Larger Photo">
                                                                {isPhotoBig ? 'smaller' : 'bigger'}
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </label>
                                                    <br />
                                                    <div id="photo-set">
                                                        {delivery.detail['driver-photo'] &&
                                                            delivery.detail['driver-photo'].map(
                                                                (item, index) => (
                                                                    <img
                                                                        key={'sig' + index}
                                                                        className={
                                                                            'driverphoto ' +
                                                                            (isPhotoBig
                                                                                ? ''
                                                                                : 'thumb')
                                                                        }
                                                                        src={item.value}
                                                                        alt="driver photo"
                                                                    />
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="9">
                                            <div className="delivery-lines">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="deliveredQty">
                                                                <label>Delivered Qty</label>
                                                            </th>
                                                            <th className="productNo">
                                                                <label>Product #</label>
                                                            </th>
                                                            <th className="productName">
                                                                <label>Product Name</label>
                                                            </th>
                                                            <th className="uom">
                                                                <label>Unit Of Measure</label>
                                                            </th>
                                                            <th className="description">
                                                                <label>Description</label>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="delivery-line">
                                                        {delivery['delivery-lines'][
                                                            'delivery-line'
                                                        ] &&
                                                            delivery['delivery-lines'][
                                                                'delivery-line'
                                                            ].map((dLine, lIndex) => (
                                                                <tr key={'dLine' + lIndex}>
                                                                    <td>
                                                                        {dLine['qty-final']} of{' '}
                                                                        {dLine['qty-target']}
                                                                    </td>
                                                                    <td>{dLine['product-no']}</td>
                                                                    <td>{dLine.name}</td>
                                                                    <td>{dLine.uom}</td>
                                                                    <td>{dLine.desc}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                <div className="datestamp mt-3">Report generated: {new Date().toString()}</div>
                <div className="centered mt-3 noprint">
                    <button
                        className="btn btn-secondary noprint"
                        type="button"
                        title="Done"
                        onClick={onCloseClick}>
                        Done
                    </button>{' '}
                    <button
                        className="btn btn-secondary noprint"
                        onClick={onPrintClick}
                        type="button"
                        title="Print">
                        Print
                    </button>
                </div>
            </div>
            <Styles />
        </>
    );
};
const Styles = createGlobalStyle`

    thead {
        text-align: left;
    }
    #container1{
        width: 99%;
        font-family: Helvetica, Arial, sans-serif !important;
        font-size: 0.8em;
    }
    .centered {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
    table{
        position: relative;
        width: 100%;
        border-collapse: collapse !important;
    }
    table thead tr th{
        font-size: 0.8em;   
    }
    th{
        padding: 5px 0 4px 10px;
    }
    td {
        font-size: 1em;
        vertical-align: top;
    }
    table.deliveryStatusReport.printStatus td {
        padding: 5px 0 5px 10px;
    }
    .deliveryStatusReport > thead{
        border-bottom: 1px solid black
    }
    #footer-wrapper{
        display: none;
    }
    .blackborder {
        border: black solid 1px;
        padding: 0;
        overflow-x: auto
    }
    .datestamp {
        font-size: .85em;
        color: #999999;
    }
    .column4 {
        width: auto;
        float: left;
        padding-right: 10px;
    }
    .column3 {
        width: 20%;
        float: left;
        padding-right: 10px;
    }
    .column3 img{
        max-width: 100%;
        height: auto !important;
    }
    .column2 {
        width: 20%;
        float: left;
        padding-right: 10px;
    }
    .column1 {
        width: 20%;
        float: left;
        padding-right: 10px;
    }
    label {
        color: black;
        font-weight: bold;
    }
    .alignLeft {
        text-align: left;
    }
    tr.details {
        border-top: 1px solid black;
    }
    div.details {
        padding: 10px 0;
        word-break: break-word;
    }
    img.signature {
        width: 150px;
        height: 75px;
    }
    img.driverphoto {
        border: 0;
        cursor: default;
        width: 200px;
        margin: 0 5px 5px 0;
    }
    img.driverphoto.thumb {
        border: 0;
        cursor: default;
        width: 50px;
        margin: 0 3px 3px 0;
    }
    .delivery-lines thead{
        border-bottom: 1px solid black;
        border-top: 1px solid black;
    }
    #toggle-photo-size {
        font-size: 11px;
    }
    #branding{
        padding: 10px
    }
    #branding h2{
        text-align: left;
        font-size: 2em;
    }
    @media print {
        .noprint {display: none}
        div#container1 { width: 100%; margin: 0; float: none;}
        input.text {border: 0px;}
    }
`;
export default StatusPrintPage;
