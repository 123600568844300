import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

import {
    GET_REMAINING_DELIVERY,
    POST_DELIVERY_INFO_URL,
    POST_LIST_DELIVERY_STATUS,
    POST_LIST_DELIVERY_STATUS_CSV,
    POST_EMAIL_SUSCRIPTION_URL,
    POST_EMAIL_SUSCRIPTION_INVITE_URL,
    POST_FLEET_STOP_WINDOW,
    POST_PVA_DETAIL,
    POST_LOADING_REPORT,
    GET_LOADERS_LIST,
    POST_DISCREPANCY_REPORT,
    POST_STOP_PENDING,
    POST_STOP_ORDER,
    POST_STOP_OPTIMIZE,
    GET_ROUTES_BY_DAY,
    GET_ROUTE_TRAIL,
    POST_TRIP_LEG_INFO_URL,
    GET_ADMIN_AFFILIATE_LIST,
    GET_ADMIN_USER_LIST,
    GET_ADMIN_AFFILIATE_EDIT,
    GET_POST_DELETE_MESSAGING,
    DELETE_ADMIN_AFFILIATE,
    GET_HOMEPAGE_MESSAGING,
    POST_SEND_EMAIL_VALIDATION,
    POST_RESET_PASSWORD,
    GET_ADMIN_USER_EDIT,
    GET_MOBILE_INFO,
    GET_USER_MESSAGES,
    POST_GET_TOTP,
    POST_SUBSCRIBE,
    GET_ADMIN_PARTNER_APIKEY_LIST,
    DELETE_PARTNER_API_KEY,
    GET_PARTNER_API_KEY,
    POST_SYNC_WORKER,
} from './CONSTANTS';
/**
 * Obtain the remaining deliveries for the following route and date
 * @param {String} routeKey Route to get remaining deliveries
 * @param {String} routeDate Date to get remaining deliveries from
 * @returns Json array with results
 */
export const getRemainingDeliveriesForRoute = (routeKey, routeDate, controller) => {
    console.log('webHandlerServices > getRemainingDeliveries called...');
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${GET_REMAINING_DELIVERY}&routeKey=${routeKey}&routeDate=${encodeURIComponent(
                    routeDate
                )}`,
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getRemainingDeliveries');
            });
    });
};
/**
 * Obtains a list of deliveries matching the searchParams criteria
 * @param {String} searchParams search parameters for deliveries
 * @returns Json array with results
 */
export const postFetchDeliveryStatusReport = (
    {
        startDate,
        endDate,
        deliveryType,
        siteName,
        deliveryCd,
        deliveryPo,
        deliveryStatus,
        gdr: { region, distributionCenter, route },
        resultLimit,
    },
    legKey,
    stopKey,
    controller
) => {
    console.log('webHandlerServices > postFetchDeliveryStatusReport called...');
    return new Promise((resolve, reject) => {
        let requestData = {};
        if (legKey || stopKey) {
            if (legKey) {
                requestData.tripLegKey = legKey;
            }
            if (stopKey) {
                requestData.stopKey = stopKey;
            }
        } else {
            requestData.search = {
                startDate: startDate,
                endDate: endDate,
                deliveryType,
                siteName: siteName,
                deliveryCd: deliveryCd,
                deliveryPo: deliveryPo,
                deliveryStatus: deliveryStatus,
                gdr: {
                    regionKey: region,
                    distributionKey: distributionCenter,
                    routeKey: route,
                },
                limit: { offset: 0, count: resultLimit },
            };
        }

        axios
            .post(POST_LIST_DELIVERY_STATUS, requestData, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postFetchDeliveryStatusReport');
            });
    });
};
/**
 * Obtains a list of deliveries filtered to obtain as a CSV

 * @param {String} searchParams search parameters for deliveries
 * @returns Response element with file URL
 */
export const postFetchDeliveryStatusCsvReport = (
    {
        startDate,
        endDate,
        deliveryType,
        siteName,
        deliveryCd,
        deliveryPo,
        deliveryStatus,
        gdr: { region, distributionCenter, route },
        resultLimit,
    },
    legKey,
    stopKey,
    controller
) => {
    console.log('webHandlerServices > postFetchDeliveryStatusReport called...');
    return new Promise((resolve, reject) => {
        let requestData = {};
        if (legKey || stopKey) {
            if (legKey) {
                requestData.tripLegKey = legKey;
            }
            if (stopKey) {
                requestData.stopKey = stopKey;
            }
        } else {
            requestData.search = {
                startDate: startDate,
                endDate: endDate,
                deliveryType,
                siteName: siteName,
                deliveryCd: deliveryCd,
                deliveryPo: deliveryPo,
                deliveryStatus: deliveryStatus,
                gdr: {
                    regionKey: region,
                    distributionKey: distributionCenter,
                    routeKey: route,
                },
                limit: { offset: 0, count: resultLimit },
            };
        }
        axios
            .post(POST_LIST_DELIVERY_STATUS_CSV, requestData, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postFetchDeliveryStatusReport');
            });
    });
};
/**
 * Obtains the delivery information related to the delivery key
 * @param {String} deliveryKey Delivery key to get information from
 * @returns Json array with results
 */
export const postFetchDeliveryInfo = (deliveryKey, controller) => {
    console.log('webHandlerServices > postFetchDeliveryStatusReport called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_DELIVERY_INFO_URL}&deliveryKey=${deliveryKey}`,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postFetchDeliveryInfo');
            });
    });
};
/**
 * Updates subscription status for the selected emails
 * @param {String} emails Emails as string
 * @returns Json array with results
 */
export const postEmailSubscriptionStatus = (emails, controller) => {
    console.log('webHandlerServices > postEmailSubscriptionStatus called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_EMAIL_SUSCRIPTION_URL}&emails=${encodeURIComponent(emails)}`,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postEmailSubscriptionStatus');
            });
    });
};
/**
 * Sends subscription invite for selected email
 * @param {String} email email for invite
 * @returns Result object
 */
export const postEmailSubscriptionInvite = (email, controller) => {
    console.log('webHandlerServices > postEmailSubscriptionInvite called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_EMAIL_SUSCRIPTION_INVITE_URL}&email=${encodeURIComponent(email)}`,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postEmailSubscriptionInvite');
            });
    });
};
/**
 * Sends subscription invite for selected email
 * @param {String} params suscription list
 * @returns Result object
 */
export const postFleetStopWindow = (params, controller) => {
    console.log('webHandlerServices > postFleetStopWindow called...');
    return new Promise((resolve, reject) => {
        axios
            .post(POST_FLEET_STOP_WINDOW, params, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postFleetStopWindow');
            });
    });
};

/**
 * Get Pva Detail data for PVA Detail report
 * @param {String} routeKey Route to get PVA detail from
 * @param {String} date Date for report
 * @returns Result Array
 */
export const postPlannedVsActualDetail = (routeKey, routeDate, controller) => {
    console.log('webHandlerServices > postPlannedVsActualDetail called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_PVA_DETAIL}&routeKey=${routeKey}&date=${encodeURIComponent(routeDate)}`,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postPlannedVsActualDetail');
            });
    });
};
/**
 * Get Print load report data
 * @param {String} departureDate Departure date for report
 * @param {Array} routeKeys Route keys selected
 * @returns Result json routes object
 */
export const postLoadingReport = (departureDate, routeKeys, controller) => {
    console.log('webHandlerServices > postLoadingReport called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                POST_LOADING_REPORT,
                { departureDate, routeKeys },
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postLoadingReport');
            });
    });
};
/**
 * Get Loader list for selector by Distribution Center
 * @param {String} distributionCenter Distribution Center to get Loader List from
 * @returns Result json Loaders list
 */
export const getLoadersList = (distributionCenter, controller) => {
    console.log('webHandlerServices > getLoadersList called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_LOADERS_LIST}&distKey=${distributionCenter}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getLoadersList');
            });
    });
};

/**
 * Get Exactload Discrepancy loaded list
 * @param {String} params Report parameters
 * @returns Result json Loaded data list
 */
export const postLoadedData = (params, controller) => {
    console.log('webHandlerServices > postLoadedData called...');
    return new Promise((resolve, reject) => {
        axios
            .post(POST_DISCREPANCY_REPORT, params, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postLoadedData');
            });
    });
};

/**
 * Get Pending stops for manifest order list
 * @param {String} routeKey Route to get Pending stops from
 * @param {String} date Date for report
 * @returns Result json Loaded data list
 */
export const postGetPendingStops = (routeKey, date, controller) => {
    console.log('webHandlerServices > postGetPendingStops called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_STOP_PENDING}&routeKey=${routeKey}&date=${encodeURIComponent(date)}`,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postGetPendingStops');
            });
    });
};

/**
 * Get Pending stops for manifest order list
 * @param {String} routeKey Route to get Pending stops from
 * @param {String} date Date for report
 * @param {Array} orderedStops Array of keys ordered
 * @returns Result json Loaded data list
 */
export const postSaveStopOrder = (routeKey, date, orderedStops, controller) => {
    console.log('webHandlerServices > postSaveStopOrder called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_STOP_ORDER}&routeKey=${routeKey}&date=${encodeURIComponent(date)}`,
                orderedStops,
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postSaveStopOrder');
            });
    });
};

/**
 * Get Pending stops for manifest order list
 * @param {String} params optimization parameters
 * @returns Result json Loaded data list
 */
export const postOptimizeStops = (params, controller) => {
    console.log('webHandlerServices > postOptimizeStops called...');
    return new Promise((resolve, reject) => {
        axios
            .post(POST_STOP_OPTIMIZE, params, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postOptimizeStops');
            });
    });
};
/**
 *
 * Get Routes for trip status page
 * @param {String} distCenter Distribution center selected
 * @param {String} date Date selected
 * @returns Result json Loaded routes list
 */
export const getRoutesByDay = (distCenter, date, controller) => {
    console.log('webHandlerServices > getRoutesByDay called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_ROUTES_BY_DAY}&distKey=${distCenter}&date=${date}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getRoutesByDay');
            });
    });
};
/**
 *
 * Get route crumbs for the trail

 * @param {String} tripKey Trip to retrieve the trail from
 * @returns Result json Loaded routes crumbs list
 */
export const getRouteTrail = (tripKey, controller) => {
    console.log('webHandlerServices > getRouteTrail called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_ROUTE_TRAIL}&tripKey=${tripKey}`, {
                withCredentials: true,
                signal: controller.current.signal,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getRouteTrail');
            });
    });
};
/**
 *
 * Get Trip leg info for status trips info window

 * @param {String} tripLegKey Leg to retrieve information from
 * @returns Result json object with trip leg information
 */
export const postGetTriplegInfo = (tripLegKey, controller) => {
    console.log('webHandlerServices > postGetTriplegInfo called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_TRIP_LEG_INFO_URL}&tripLegKey=${encodeURIComponent(tripLegKey)}`,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postGetTriplegInfo');
            });
    });
};
/**
 *
 * Get Admin affiliate list

 * @returns Result json Loaded Affiliate List for Admin Page
 */
export const getAdminAffiliateList = (controller) => {
    console.log('webHandlerServices > getAdminAffiliateList called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_ADMIN_AFFILIATE_LIST}`, {
                withCredentials: true,
                signal: controller.current.signal,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getAdminAffiliateList');
            });
    });
};
/**
 *
 * Get Admin User list
 * @returns Result json Loaded User List for Admin Page
 */
export const getAdminUserList = (affiliateId, controller) => {
    console.log('webHandlerServices > getAdminUserList called...');
    return new Promise((resolve, reject) => {
        const url = affiliateId
            ? `${GET_ADMIN_USER_LIST}&affiliateId=${encodeURI(affiliateId)}`
            : `${GET_ADMIN_USER_LIST}`;
        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('getAdminUserList > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getAdminUserList > axios err=', err);
                doRejectValidation(err, reject, 'getAdminUserList');
            });
    });
};
/**
 *
 * Get Admin Affiliate Edit
 * @param {String} affiliateId Affiliate to edit (can be null)
 * @returns Result json With affiliate edit info
 */
export const getAffiliateEditInfo = (affiliateId, controller) => {
    console.log('webHandlerServices > getAffiliateEditInfo called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_ADMIN_AFFILIATE_EDIT}`;
        if (affiliateId) {
            url += `&affiliateId=${affiliateId}`;
        }
        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getAffiliateEditInfo');
            });
    });
};
/**
 *
 * Post Admin affiliate Edit
 * @param {String} affiliateId Affiliate to edit (can be null)
 * @returns Result json With affiliate edit info
 */
export const postSaveAffiliateEditInfo = (affiliateId, affiliateInfo, controller) => {
    console.log('webHandlerServices > postSaveAffiliateEditInfo called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_ADMIN_AFFILIATE_EDIT}`;
        if (affiliateId) {
            url += `&affiliateId=${affiliateId}`;
        }
        axios
            .post(url, affiliateInfo, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveAffiliateEditInfo > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postSaveAffiliateEditInfo > axios err=', err);
                doRejectValidation(err, reject, 'postSaveAffiliateEditInfo');
            });
    });
};

/**
 *
 * Post Admin affiliate Delete
 * @param {String} affiliateId Affiliate to delete
 * @returns Result json with response
 */
export const deleteAffiliate = (affiliateId, controller) => {
    console.log('webHandlerServices > deleteAffiliate called...');
    return new Promise((resolve, reject) => {
        axios
            .delete(`${DELETE_ADMIN_AFFILIATE}&affiliateId=${affiliateId}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'deleteAffiliate');
            });
    });
};
/**
 * Get Messaging info
 * @param {String} isScheduledMaintenance when true, sends scheduled maintenance info, when false sends notice info
 * @returns Messaging info
 */
export const getMessagingInfo = (isScheduledMaintenance, controller) => {
    console.log('webHandlerServices > getScheduledMaintenanceInfo called...');
    return new Promise((resolve, reject) => {
        let url;
        if (!isScheduledMaintenance) {
            url = `${GET_POST_DELETE_MESSAGING}&noticeAction=true`;
        } else {
            url = `${GET_POST_DELETE_MESSAGING}&scheduledAction=true`;
        }
        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getMessagingInfo');
            });
    });
};

/**
 * Save Messaging info
 * @param {String} isScheduledMaintenance when true, sends scheduled maintenance info, when false sends notice info
 * @returns Messaging info
 */
export const postMessagingInfo = (isScheduledMaintenance, postData, controller) => {
    console.log('webHandlerServices > postMessagingInfo called...');
    return new Promise((resolve, reject) => {
        let url;
        if (!isScheduledMaintenance) {
            url = `${GET_POST_DELETE_MESSAGING}&noticeAction=true`;
        } else {
            url = `${GET_POST_DELETE_MESSAGING}&scheduledAction=true`;
        }
        axios
            .post(url, postData, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postMessagingInfo');
            });
    });
};

/**
 * Save Messaging info
 * @param {String} isScheduledMaintenance when true, sends scheduled maintenance info, when false sends notice info
 * @returns Messaging info
 */
export const deleteMessagingInfo = (isScheduledMaintenance, controller) => {
    console.log('webHandlerServices > deleteMessagingInfo called...');
    return new Promise((resolve, reject) => {
        let url;
        if (!isScheduledMaintenance) {
            url = `${GET_POST_DELETE_MESSAGING}&noticeAction=true`;
        } else {
            url = `${GET_POST_DELETE_MESSAGING}&scheduledAction=true`;
        }
        axios
            .delete(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'deleteMessagingInfo');
            });
    });
};
/**
 * Get Messaging info for home page
 * @returns Messaging info
 */
export const getHomepageMessagingInfo = (productName, controller) => {
    console.log('webHandlerServices > getHomepageMessagingInfo called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_HOMEPAGE_MESSAGING}&productName=${productName}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getHomepageMessagingInfo');
            });
    });
};
/**
 * @param {String} email
 * @param {Boolean} brandingSSL
 * @returns ok response always
 */
export const postSendEmailValidation = (email, brandingSSL, controller) => {
    console.log('webHandlerServices > postSendEmailValidation called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_SEND_EMAIL_VALIDATION}`,
                { email, brandingSSL },
                {
                    signal: controller.current.signal,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postSendEmailValidation');
            });
    });
};
/**
 * @param {String} token password reset token
 * @param {String} password
 * @returns ok response always
 */
export const postPasswordReset = (token, password, controller) => {
    console.log('webHandlerServices > postPasswordReset called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_RESET_PASSWORD}`,
                { token, password },
                {
                    signal: controller.current.signal,
                }
            )
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postPasswordReset');
            });
    });
};
/**
 *
 * Get Admin User Edit

 * @param {String} affiliateId Affiliate for the user
 * @param {String} userId user to edit (can be null)
 * @returns Result json With user edit info
 */
export const getUserEditInfo = (affiliateId, userId, controller) => {
    console.log('webHandlerServices > getUserEditInfo called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_ADMIN_USER_EDIT}&affiliateId=${affiliateId}&userId=${userId}`;
        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'getUserEditInfo');
            });
    });
};
/**
 *
 * Post Admin affiliate Edit
 * @param {String} affiliateId Affiliate to edit
 * @param {String} userId user to edit (can be null)
 * @param {object} userInfo user edit info
 *
 * @returns Result json With User edit info
 */
export const postSaveUserEditInfo = (affiliateId, userId, userInfo, controller) => {
    console.log('webHandlerServices > postSaveUserEditInfo called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_ADMIN_USER_EDIT}&affiliateId=${affiliateId}`;
        if (userId) {
            url += `&userId=${userId}`;
        }
        axios
            .post(url, userInfo, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'postSaveUserEditInfo');
            });
    });
};

/**
 *
 * Delete Admin affiliate delete
 * @param {String} userId user to delete
 *
 * @returns Result res true if deleted or not
 */
export const deleteUserEditInfo = (userId, controller) => {
    console.log('webHandlerServices > deleteUserEditInfo called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_ADMIN_USER_EDIT}&userId=${userId}`;

        axios
            .delete(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('webHandlerServices > axios err=', err);
                doRejectValidation(err, reject, 'deleteUserEditInfo');
            });
    });
};

/**
 *
 * Get mobile page information based on the logged in user
 *
 * @returns Result json With Mobile page info
 */
export const getMobileInfo = (controller) => {
    console.log('webHandlerServices > postGetMobileInfo called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_MOBILE_INFO}`;

        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('postGetMobileInfo > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postGetMobileInfo > axios err=', err);
                doRejectValidation(err, reject, 'getMobileInfo');
            });
    });
};

/**
 *
 * Get messages for logged in user
 * @returns Result user message list
 */
export const getUserMessages = (controller) => {
    console.log('webHandlerServices > getUserMessages called...');
    return new Promise((resolve, reject) => {
        let url = GET_USER_MESSAGES;

        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('webHandlerServices > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getUserMessages > axios err=', err);
                doRejectValidation(err, reject, 'getUserMessages');
            });
    });
};
/**
 * @param {String} secret Totp secret if it was generated before
 * @param {String} qrFilePart QR file part to don't generate duplicated qr codes while session is active
 * @returns TOTP QR Code information
 */
export const postTotpGenerateCode = (secret, qrFilePart, controller) => {
    console.log('webHandlerServices > getTotpGenerateCode called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_GET_TOTP}&action=generate`,
                { secret, qrFilePart },
                {
                    signal: controller.current.signal,
                }
            )
            .then((res) => {
                console.log('getTotpGenerateCode > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getTotpGenerateCode > axios err=', err);
                doRejectValidation(err, reject, 'getTotpGenerateCode');
            });
    });
};
/**
 * @param {object} params Totp params for validation
 * @returns TOTP validated correctly or not
 */
export const postValidateTotpCode = (params, controller) => {
    console.log('webHandlerServices > postValidateTotpCode called...');
    return new Promise((resolve, reject) => {
        axios
            .post(`${POST_GET_TOTP}&action=validate`, params, {
                signal: controller.current.signal,
            })
            .then((res) => {
                console.log('postValidateTotpCode > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postValidateTotpCode > axios err=', err);
                doRejectValidation(err, reject, 'postValidateTotpCode');
            });
    });
};

/**
 * @param {object} params Totp params for validation
 * @returns TOTP validated correctly or not
 */
export const postManageSubscription = (token, subscribe, controller) => {
    console.log('webHandlerServices > postManageSubscription called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${POST_SUBSCRIBE}`,
                { token, subscribe },
                {
                    signal: controller.current.signal,
                }
            )
            .then((res) => {
                console.log('postManageSubscription > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postManageSubscription > axios err=', err);
                doRejectValidation(err, reject, 'postManageSubscription');
            });
    });
};

/**
 *
 * Get Admin Partner API key list
 * @returns Result json Loaded Partner API Key List for Admin Page
 */
export const getAdminPartnerApiKeyList = (controller) => {
    console.log('webHandlerServices > getAdminPartnerApiKeyList called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_ADMIN_PARTNER_APIKEY_LIST}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('getAdminPartnerApiKeyList > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getAdminPartnerApiKeyList > axios err=', err);
                doRejectValidation(err, reject, 'getAdminPartnerApiKeyList');
            });
    });
};

/**
 *
 * Delete partner api key by id
 * @returns true if the api key was deleted
 */
export const deletePartnerApiKey = (id, controller) => {
    console.log('webHandlerServices > deleteParnerApiKey called...');
    return new Promise((resolve, reject) => {
        axios
            .delete(`${DELETE_PARTNER_API_KEY}&id=${id}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('deleteParnerApiKey > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('deleteParnerApiKey > axios err=', err);
                doRejectValidation(err, reject, 'deleteParnerApiKey');
            });
    });
};
/**
 * Method to obtain a new api key for insert
 * @returns Result json with a new Partner API Key for Admin Page
 */
export const getGeneratedApiKey = (controller) => {
    console.log('webHandlerServices > getGeneratedApiKey called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_PARTNER_API_KEY}`, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('getGeneratedApiKey > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getGeneratedApiKey > axios err=', err);
                doRejectValidation(err, reject, 'getGeneratedApiKey');
            });
    });
};

/**
 *
 * Post Admin Api Key for insert
 * @param {object} params Api Key params for save
 *
 * @returns Result json With Api Key Info
 */
export const postSavePartnerApiKey = (params, controller) => {
    console.log('webHandlerServices > postSavePartnerApiKey called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_PARTNER_API_KEY}`;
        axios
            .post(url, params, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSavePartnerApiKey > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postSavePartnerApiKey > axios err=', err);
                doRejectValidation(err, reject, 'postSavePartnerApiKey');
            });
    });
};

/**
 * Function to get the affiliate SyncWorker
 */
export const postSyncWorker = (controller) => {
    console.log('webHandlerServices > postSyncWorker called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                POST_SYNC_WORKER,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postSyncWorker > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postSyncWorker > axios err=', err);
                doRejectValidation(err, reject, 'postSyncWorker');
            });
    });
};
