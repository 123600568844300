import { getLocalStorage, setLocalStorage } from 'utils/storage/LocalStorageHelper';
import { THEME_CHANGE } from '../CONSTANTS';
import { CURRENT_THEME_KEY } from 'utils/storage/CONSTANTS';

const themes = require('themes/schema.json');

let selectedTheme = getLocalStorage(CURRENT_THEME_KEY, true);
if (selectedTheme === null) {
    if (process.env.REACT_APP_CURRENT_THEME) {
        selectedTheme = themes.data[process.env.REACT_APP_CURRENT_THEME];
    } else {
        selectedTheme = themes.data.jumptrack;
    }
}
let initialData = {
    currentTheme: selectedTheme,
};
setLocalStorage(CURRENT_THEME_KEY, selectedTheme, true);

const themeReducer = (state = initialData, action) => {
    switch (action.type) {
        case THEME_CHANGE: {
            const theTheme = themes.data[action.value];
            setLocalStorage(CURRENT_THEME_KEY, theTheme, true);
            return {
                ...state,
                currentTheme: theTheme,
            };
        }
        default:
            return state;
    }
};
export default themeReducer;
