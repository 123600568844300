import { MENU_SELECTED } from '../CONSTANTS';

let initialData = {
    menuSelected: 'home',
};

const menuReducer = (state = initialData, action) => {
    switch (action.type) {
        case MENU_SELECTED:
            return {
                ...state,
                menuSelected: action.value,
            };
        default:
            return state;
    }
};
export default menuReducer;
