import { hotjar } from 'react-hotjar';

import {
    ABORTED_REQUEST_ERROR,
    AUTHENTICATION_FAILED_ERROR,
    CANCELED_REQUEST_ERROR,
    SHOW_ALL_DROPDOWN_ITEM,
} from 'config/CONSTANTS';

export const xmlDefault = () => {
    return new Date();
};
export const xmlDate = (date) => {
    if (date === undefined || date === null) date = xmlDefault();
    return (
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
    );
};
export const xmlDateTime = (date) => {
    if (date === undefined) date = xmlDefault();
    //yyyy-MM-dd HH:mm:ss
    return (
        xmlDate(date) +
        ' ' +
        ('0' + date.getHours()).slice(-2) +
        ':' +
        ('0' + date.getMinutes()).slice(-2) +
        ':' +
        ('0' + date.getSeconds()).slice(-2)
    );
};

export const escapeXml = (unsafe) => {
    return unsafe
        ? unsafe.replace(/[<>&'"]/g, function (c) {
              switch (c) {
                  case '<':
                      return '&lt;';
                  case '>':
                      return '&gt;';
                  case '&':
                      return '&amp;';
                  case "'":
                      return '&apos;';
                  case '"':
                      return '&quot;';
                  default:
                      return c;
              }
          })
        : '';
};
export const parseXmlResponseDataList = (response, selector = 'results q r') => {
    const responseDoc = new DOMParser().parseFromString(response, 'text/xml');
    const regionList = responseDoc.querySelectorAll(selector);
    const parsedResponse = Array.from(regionList).map((theRegion) => {
        const theName = theRegion.querySelector('name');
        return { key: theRegion.getAttribute('key'), name: theName.textContent };
    });
    return parsedResponse;
};
export const parseXmlResult = (xmlResponse) => {
    const xml2js = require('xml2js');
    return new Promise((resolve, reject) => {
        xml2js
            .parseStringPromise(xmlResponse, {
                ignoreAttrs: false,
                mergeAttrs: true,
                explicitArray: false,
            })
            .then(function (result) {
                resolve(result);
            })
            .catch(function (err) {
                reject(err);
            });
    });
};
export const userDate = (date) => {
    if (date === undefined) date = xmlDateTime();
    //Expects yyyy-MM-dd HH:mm:ss
    return date.substr(5, 2) + '/' + date.substr(8, 2) + '/' + date.substr(0, 4);
};
export const userTime = (date) => {
    //Expects yyyy-MM-dd HH:mm:ss
    if (date === undefined || date.length < 16) return '';
    let hours = +date.substr(11, 2);
    const xm = hours < 12 ? 'AM' : 'PM';
    if (hours === 0) hours = 12;
    else if (hours > 12) hours -= 12;

    return hours + ':' + date.substr(14, 2) + ' ' + xm;
};
export const userDateTime = (date) => {
    return userDate(date) + ' ' + userTime(date);
};
export const durationHours = (mills) => {
    if (isNaN(mills)) return '';
    const ms = mills < 0 ? -mills : mills;
    const seconds = parseInt((ms / 1000) % 60);
    const minutes = parseInt((ms / 60000) % 60);
    const hours = parseInt((ms / 3600000) % 24);
    return (
        (mills < 0 ? '-' : '') +
        (hours.toString().length === 1 ? '0' + hours : hours) +
        ':' +
        (minutes.toString().length === 1 ? '0' + minutes : minutes) +
        ':' +
        (seconds.toString().length === 1 ? '0' + seconds : seconds)
    );
};
export const durationHHMM = (mills) => {
    return durationHours(mills).substring(0, 5);
};
export const nullIfAll = (value) => {
    if (value === null) {
        return null;
    }

    return -1 === value || SHOW_ALL_DROPDOWN_ITEM.key === value.toLowerCase() ? null : value;
};
export const nullIfAllArray = (value) => {
    if (value === null) {
        return null;
    }
    if (value.length === 0) {
        return null;
    }
    if (value[0] === null) {
        return null;
    }
    return -1 === value[0].key || SHOW_ALL_DROPDOWN_ITEM.key === value[0].key.toLowerCase()
        ? null
        : value[0].key;
};
export const nullIfNegative = (val) => {
    return val < 0 ? null : val;
};
export const nullIfEmpty = (val, key = null) => {
    if (val === null) {
        return null;
    }
    if (val.length === 0) {
        return null;
    }
    return key === null ? val : val[key];
};
export const dateStr = (jsDate) => {
    if (jsDate === undefined) jsDate = new Date();
    let month = jsDate.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    return month + '/' + jsDate.getDate() + '/' + jsDate.getFullYear();
};
export const dateStrFull = (jsDate) => {
    if (jsDate === undefined) jsDate = new Date();
    let month = jsDate.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let date = jsDate.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    return month + '/' + date + '/' + jsDate.getFullYear();
};
export const dd2dms = (decimalDegree, isLatitude) => {
    let dd = Math.abs(decimalDegree);
    let deg = Math.floor(dd);
    let min = (dd - deg) * 60;
    let sec = (min - Math.floor(min)) * 60;
    let presign = '';
    let postsign = '';
    if (isLatitude === undefined) presign = decimalDegree >= 0 ? '+' : '-';
    else if (isLatitude) postsign = ' ' + (decimalDegree >= 0 ? 'N' : 'S');
    else postsign = ' ' + (decimalDegree >= 0 ? 'E' : 'W');

    return (
        presign +
        deg +
        '\u00B0 ' +
        Math.floor(min) +
        "' " +
        Math.round(sec * 100) / 100 +
        '"' +
        postsign
    );
};
export const userLatLng = (decimalDegLat, decimalDegLng) => {
    return dd2dms(decimalDegLat, true) + ' ' + dd2dms(decimalDegLng, false);
};
export const getFileExtension = (fileName) => {
    return fileName.split('.').pop();
};
export const minuteInDay = (value) => {
    let mins;
    let groups = value.match(/\\s*(1[0-2]|0?[1-9]):?([0-5]\\d)\\s*([ap]m)\\s*/i);
    if (groups !== undefined && groups != null) {
        mins = ((+groups[1] % 12) + (groups[3] === 'PM' ? 12 : 0)) * 60 + +groups[2];
    }
    return mins;
};
export const validatePhoneNumber = (phone) => {
    return phone.length > 9 && phone.match(/^(\D*\d){10,14}\D*$/);
};
export const openVideoUrl = (e, href) => {
    e.preventDefault();
    window.open(href, 'title', 'width=660, height=380, resizable=1');
};
export const compareArrays = (a1, a2) => {
    if (a1.length !== a2.length) {
        return false;
    }

    let i = a1.length;
    while (i--) {
        if (a1[i] !== a2[i]) return false;
    }
    return true;
};

export const textSort = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};
export const objectEquals = (x, y) => {
    if (x === null || x === undefined || y === null || y === undefined) {
        return x === y;
    }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) {
        return false;
    }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) {
        return x === y;
    }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) {
        return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
        return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
        return false;
    }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) {
        return false;
    }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) {
        return false;
    }
    if (!(y instanceof Object)) {
        return false;
    }

    // recursive object equality check
    let xKeys = Object.keys(x);
    return (
        Object.keys(y).every((i) => xKeys.indexOf(i) !== -1) &&
        xKeys.every((i) => objectEquals(x[i], y[i]))
    );
};

export const doRejectValidation = (err, reject, method) => {
    if (err.code && err.code === CANCELED_REQUEST_ERROR) {
        console.log('canceled request ' + method);
        return;
    }
    if (err.code && err.code === ABORTED_REQUEST_ERROR) {
        console.log('request aborted ' + method);
        return;
    }
    if (err === AUTHENTICATION_FAILED_ERROR || err.message === AUTHENTICATION_FAILED_ERROR) {
        reject(AUTHENTICATION_FAILED_ERROR);
    } else if (err.response && err.response.data && err.response.data.message) {
        reject(err.response.data);
    } else {
        reject(`Error in ${method} axios!`);
    }
};
export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
        function () {
            /* clipboard successfully set */
            window.alert('Success! The text was copied to your clipboard');
        },
        function () {
            /* clipboard write failed */
            window.alert('Oops! Your browser does not support copying to the clipboard');
        }
    );
};
export const navigateTo = (history, url) => {
    if (hotjar.initialized()) {
        hotjar.stateChange(url);
    }
    history.push(url);
};
export const recordEvent = (eventName) => {
    if (hotjar.initialized()) {
        hotjar.event(eventName);
    }
};
export const getUniqueStringArray = (arr) => {
    let list = [];
    let set = new Set();
    for (const item of arr) {
        const lowerItem = item.toString().trim().toLowerCase();
        if (!set.has(lowerItem)) {
            set.add(lowerItem);
            list.push(item);
        }
    }
    return list;
};
