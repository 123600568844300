import { REGION_SET, ROUTE_SET, DISTRIBUTION_SET, LOOKUP_SET_VARIABLE } from '../CONSTANTS';

let initialData = {
    distributionCenters: [],
    distCenter: null,
    distCenterForSave: null,
    distCenterMoved: null,
    distCenterRemoved: null,
    regions: [],
    routes: [],
};

const siteLookupReducer = (state = initialData, action) => {
    switch (action.type) {
        case REGION_SET: {
            const newState = { ...state };
            newState.regions = [...action.value];
            newState.routes = [];
            newState.distributionCenters = [];
            newState.distCenter = null;
            return newState;
        }
        case ROUTE_SET:
            return {
                ...state,
                routes: [...action.value],
            };
        case DISTRIBUTION_SET:
            return {
                ...state,
                distributionCenters: [...action.value],
                routes: [],
                distCenter: {},
            };
        case LOOKUP_SET_VARIABLE: {
            const newState = { ...state };
            newState[action.variable] = action.value;
            return newState;
        }
        default:
            return state;
    }
};
export default siteLookupReducer;
