export const ROOT = '/';
export const LOGIN = '/login';
export const ADMIN_LOGIN = '/al';
export const PASSWORD_RESET = '/passwordreset';
export const PASSWORD_RESET_TOKEN = '/passwordreset/:token';
export const DEMOPAGES = {
    UPLOAD: '/upload',
    THEMESWITCH: '/theme-switch',
    TABLE: '/table',
    STATUS: '/status',
    SUPPORT: '/support',
    UPLOAD_PREVIEW: '/upload-preview',
    CHECKBOX: '/checkbox-demo',
};
export const DASHBOARD = '/home';
export const HOME_NAVIGATOR = '/home-nav';
export const LOGINAS_NAVIGATOR = '/loginas';
export const LOGOUTAS_NAVIGATOR = '/logoutas';
export const MESSAGES = '/messages';
export const MOBILE = '/mobile';
export const ADMIN_AFFILIATES = '/admin/home';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_AFFILIATE_DETAIL_CREATE = '/admin/affiliates/add';
export const ADMIN_AFFILIATE_DETAIL_EDIT = '/admin/affiliates/:affiliateId';
export const ADMIN_AFFILIATE_EDIT = '/admin/affiliates';
export const ADMIN_MESSAGING = '/admin/messaging';
export const ADMIN_USER_DETAIL_BY_AFFILIATE = '/admin/users/:affiliateId';
export const ADMIN_USER_FORM = '/admin/administrators';
export const ADMIN_PARTNER_API_KEYS = '/admin/api-keys';
export const ADMIN_PARTNER_API_KEY_DETAIL = '/admin/api-keys/create';

export const REPORTS_UNSCHEDULED_DELIVERIES = '/unscheduled-deliverys';
export const REPORTS_DELIVERY_EFFICIENCY = '/reports/delivery-efficiency';
export const REPORTS_ROUTE_STATUS = '/reports/route-status';
export const REPORTS_PLANNED_VS_ACTUAL_OVERVIEW = '/reports/planned-vs-actual-overview';
export const REPORTS_PLANNED_VS_ACTUAL_DETAIL = '/reports/planned-vs-actual-detail';
export const REPORTS_PRINT_LOAD = '/reports/print-load';
export const REPORTS_EXACTLOAD_DISCREPANCY = '/reports/exactload-discrepancy';
export const MAINTENANCE_SITES = '/maintenance/sites';
export const MAINTENANCE_SITES_PREVIEW = '/maintenance/sites/preview';
export const MAINTENANCE_ROUTES = '/maintenance/routes';
export const MAINTENANCE_ROUTES_PREVIEW = '/maintenance/routes/preview';

export const MAINTENANCE_USERS = '/maintenance/users';
export const MAINTENANCE_USERS_PREVIEW = '/maintenance/users/preview';
export const MAINTENANCE_USER_DETAIL = '/maintenance/user';
export const MAINTENANCE_DELIVERY = '/maintenance/customers';
export const MAINTENANCE_PRODUCTS = '/maintenance/products';
export const MAINTENANCE_PRODUCTS_PREVIEW = '/maintenance/products/preview';
export const MAINTENANCE_ACCOUNT = '/maintenance/account';
export const MANIFEST_IMPORT = '/manifest/import';
export const MANIFEST_IMPORT_PREVIEW = '/manifest/import/preview';
export const MANIFEST_EDIT = '/manifest/edit';
export const MANIFEST_CREATE = '/manifest/create';
export const MANIFEST_FLEET = '/manifest/fleet';
export const MANIFEST_ORDER = '/manifest/order';
export const STATUS_DELIVERY = '/status/delivery';
export const STATUS_TRIPS = '/status/trips';
export const STATUS_PRINT = '/status/print';
export const DELIVERY_STATUS_VENDOR_LOGIN = '/delivery-status/:vendorKey';
export const DELIVERY_STATUS_VENDOR_LOGIN_WITH_PARAMS =
    '/delivery-status/:vendorKey/:accountNo/:displayCd';
export const SUPPORT = '/support';
export const TOTP = '/totp';
export const SUBSCRIBE = '/subscribe/:token';
export const UNSUBSCRIBE = '/unsubscribe/:token';
export const MENU_HOME = 'home';
export const MENU_REPORTS = 'reports';
export const MENU_STATUS = 'status';
export const MENU_MAINTENANCE = 'maintenance';
export const MENU_MANIFEST = 'manifest';
export const MENU_SUPPORT = 'support';
export const MENU_ADMINISTRATION = 'administration';
export const TEMPORARY_LOCK = '/locked';
export const PERMANENT_LOCK = '/permalocked';
