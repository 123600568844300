import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

import { GET_STOP_RECENT_URL } from './CONSTANTS';
/**
 *

 * @param {String} actualDate Current date formatted as XML
 * @param {String} rowCount Amount of rows to get from the service
 * @returns Json array with results
 */
export const getRecentDeliveries = (actualDate, rowCount, controller) => {
    console.log('stopRecentServices > getRecentDeliveries called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_STOP_RECENT_URL}&actual_date=${actualDate}&row_count=${rowCount}`, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getRecentDeliveries > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getRecentDeliveries > axios err=', err);
                doRejectValidation(err, reject, 'getRecentDeliveries');
            });
    });
};
