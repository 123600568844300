import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';
import {
    GET_ADMIN_FLEET_OPTIMIZATION_LIMIT,
    GET_PARTNER_IPS,
    POST_LOGIN_AS,
    POST_LOGOUT_AS,
    POST_TOTP_RESET,
} from './CONSTANTS';

/**
 *
 * Get Admin Edit
 * @param {String} fleetKey Fleet Key to get limits from
 * @returns Fleet Optimization Limit for the fleet key
 */
export const getFleetOptimizationLimits = (fleetKey, controller) => {
    console.log('jumpTechRequestServices > getFleetOptimizationLimits called...');
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${GET_ADMIN_FLEET_OPTIMIZATION_LIMIT}&optimizationKey=${encodeURIComponent(
                    fleetKey
                )}`,
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('getFleetOptimizationLimits > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getFleetOptimizationLimits > axios err=', err);
                doRejectValidation(err, reject, 'getFleetOptimizationLimits');
            });
    });
};
/**
 * Request to login as another user
 * @param {String} username username to login as
 * @returns A valid response if its valid to log in, and an invalid one if not
 */
export const postLoginAs = (username, controller) => {
    console.log('jumpTechRequestServices > loginAs called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                POST_LOGIN_AS,
                { username },
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postLoginAs > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postLoginAs > axios err=', err);
                doRejectValidation(err, reject, 'postLoginAs');
            });
    });
};
/**
 *
 * Reset TOTP Token for user
 * @param {String} userId user to reset totp
 *
 * @returns Result for reset completed or not
 */
export const postTotpReset = (userId, controller) => {
    console.log('jumpTechRequestServices > postTotpReset called...');
    return new Promise((resolve, reject) => {
        let url = `${POST_TOTP_RESET}&userId=${userId}`;

        axios
            .post(
                url,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postTotpReset > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postTotpReset > axios err=', err);
                doRejectValidation(err, reject, 'postTotpReset');
            });
    });
};

/**
 * Request to login as another user
 * @returns Request to remove cookies from the session
 */
export const postLogoutAs = (controller) => {
    console.log('jumpTechRequestServices > postLogoutAs called...');
    return new Promise((resolve, reject) => {
        axios
            .post(
                POST_LOGOUT_AS,
                {},
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postLogoutAs > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postLogoutAs > axios err=', err);
                doRejectValidation(err, reject, 'postLogoutAs');
            });
    });
};

/**
 *
 * Get valid IPs for API Key
 * @param {String} apiKeyId api key id to
 *
 * @returns Result IPs for the api key
 */
export const getApiKeyValidIps = (apiKeyId, controller) => {
    console.log('jumpTechRequestServices > getApiKeyValidIps called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_PARTNER_IPS}&apiKeyId=${apiKeyId}`;

        axios
            .get(url, {
                signal: controller.current.signal,
                withCredentials: true,
            })
            .then((res) => {
                console.log('getApiKeyValidIps > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getApiKeyValidIps > axios err=', err);
                doRejectValidation(err, reject, 'getApiKeyValidIps');
            });
    });
};
/**
 *
 * Get valid IPs for API Key
 * @param {String} apiKeyId api key id to
 *
 * @returns Result IPs for the api key
 */
export const postSaveApiKeyIPs = (apiKeyId, ips, controller) => {
    console.log('jumpTechRequestServices > postSaveApiKeyIPs called...');
    return new Promise((resolve, reject) => {
        let url = `${GET_PARTNER_IPS}&apiKeyId=${apiKeyId}`;

        axios
            .post(
                url,
                { ips },
                {
                    signal: controller.current.signal,
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postSaveApiKeyIPs > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postSaveApiKeyIPs > axios err=', err);
                doRejectValidation(err, reject, 'postSaveApiKeyIPs');
            });
    });
};
