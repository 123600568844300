// /rhWWW?type=route-color&format=RAW
import axios from 'axios';
import { doRejectValidation } from 'utils/JTUtils';

import { GET_ROUTE_COLOR_URL } from './CONSTANTS';
/**
 *
 * @returns Json object with next possible route color
 */
export const getNextRouteColor = (controller) => {
    console.log('routeColorServices > getNextRouteColor called...');
    return new Promise((resolve, reject) => {
        axios
            .get(`${GET_ROUTE_COLOR_URL}`, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('getNextRouteColor > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('getNextRouteColor > axios err=', err);
                doRejectValidation(err, reject, 'getNextRouteColor');
            });
    });
};
