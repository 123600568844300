import axios from 'axios';
import { AUTHENTICATION_FAILED_ERROR } from 'config/CONSTANTS';
import { userLoggedOut } from 'redux/actions/loginActions';
import { clearUserInfo } from 'redux/actions/userActions';
import { LOGIN_ERROR_MSG } from 'utils/storage/CONSTANTS';
import { setLocalStorage } from 'utils/storage/LocalStorageHelper';

export const setupInterceptors = (store) => {
    axios.interceptors.response.use(
        function (response) {
            if (
                (typeof response.data === 'string' || response.data instanceof String) &&
                (response.data.indexOf(AUTHENTICATION_FAILED_ERROR) !== -1 ||
                    response.data.indexOf('Exception authenticating user') !== -1)
            ) {
                console.log('Authentication error');
                setLocalStorage(LOGIN_ERROR_MSG, 'Your session has expired.  Please re-login.');
                store.dispatch(userLoggedOut());
                store.dispatch(clearUserInfo());

                return Promise.reject(AUTHENTICATION_FAILED_ERROR);
            }
            return response;
        },
        function (error) {
            console.log(error);

            if (error.response && error.response.status === 401) {
                console.log('Authentication error');
                setLocalStorage(LOGIN_ERROR_MSG, 'Your session has expired.  Please re-login.');
                store.dispatch(userLoggedOut());
                store.dispatch(clearUserInfo());

                return Promise.reject({ ...error, message: AUTHENTICATION_FAILED_ERROR });
            }
            return Promise.reject(error);
        }
    );
};
