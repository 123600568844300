import axios from 'axios';
import { doRejectValidation, escapeXml, parseXmlResult } from 'utils/JTUtils';

import {
    POST_FETCH_ACCOUNT_LOOKUP_NAME_URL,
    POST_FETCH_ACCOUNT_LOOKUP_URL,
    POST_DELIVERY_SEARCH,
    POST_MANIFEST_EDIT,
    POST_DELIVERY_STOP_SEARCH,
    POST_UPLOAD_CSV,
    POST_DELIVERY_UNSCHEDULED_AND_RETURN,
    POST_PLANNED_VS_ACTUAL_OVERVIEW,
    POST_REPLAN,
    POST_PVA_DETAIL,
    POST_OPTIMIZE,
    POST_QUERY_ROUTE_CRUMBS,
    POST_QUERY_TRIP_ROUTE,
    POST_SEND_EMAIL,
    POST_PRINT_STATUS,
    POST_ACCEPT_JUMPTRACK_EULA,
    POST_SYNC_WORKER_GET_MANIFEST_DATA,
} from './CONSTANTS';
/**
 * Account lookup by accountNo or name
 * @returns Json array with results
 */
export const postAccountLookup = (term, isNameSearch, controller) => {
    console.log('jumpPodRequestServices > postAccountLookup called...');
    return new Promise((resolve, reject) => {
        const url =
            (isNameSearch ? POST_FETCH_ACCOUNT_LOOKUP_NAME_URL : POST_FETCH_ACCOUNT_LOOKUP_URL) +
            `&term=${term}`;

        axios
            .post(
                url,
                {},
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'text/plain; charset=UTF-8',
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postAccountLookup > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postAccountLookup > axios err=', err);
                doRejectValidation(err, reject, 'postAccountLookup');
            });
    });
};
/**
 * Delivery Search Lookup
 * @returns Json array with results
 */
export const postDeliverySearch = ({
    startDate,
    endDate,
    distributionCenter,
    route,
    customerName,
    controller,
}) => {
    console.log('jumpPodRequestServices > postDeliverySearch called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-delivery-search"><delivery type=""><start-date>${startDate}</start-date><end-date>${endDate}</end-date><distribution key="${distributionCenter}"/><route key="${route}"/><limit offset="0" count="999"/><customer-name>${escapeXml(
            customerName
        )}</customer-name><display-cd/><invoice-no/><po-number/><complete>false</complete><status/></delivery></request>`;
        axios
            .post(POST_DELIVERY_SEARCH, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postDeliverySearch > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postDeliverySearch > axios err=', err);
                doRejectValidation(err, reject, 'postDeliverySearch');
            });
    });
};
const getParsedDeliveryLine = (item) => {
    return item.deliveryLines
        .map((product) => {
            const licensePlate = product.detail ? product.detail['license-plate'] : [];
            let plates = '';
            licensePlate.forEach((item) => {
                if (!item || !item.meta) {
                    return;
                }
                plates += `<license-plate><meta>${escapeXml(item.meta)}</meta></license-plate>`;
            });
            const qty = product['qty-target'] ? product['qty-target'] : '0';
            const productScan =
                product.detail && product.detail['scan'] ? product.detail['scan'].meta : '';
            return `<delivery-line qty-target="${escapeXml(qty)}" ${
                product.key ? 'key="' + escapeXml(product.key) + '"' : ''
            } remove="${product.remove ? product.remove : false}"><name>${escapeXml(
                product.name
            )}</name><product-no>${escapeXml(product['product-no'])}</product-no><uom>${escapeXml(
                product.uom
            )}</uom><desc>${escapeXml(product.desc)}</desc>${plates}<scan>${escapeXml(
                productScan
            )}</scan></delivery-line>`;
        })
        .join('');
};
/**
 * Save manifest edit values
 * @param {array} changedDeliveries deliveries changed
 * @returns Json results
 */
export const postSaveManifestEdit = (changedDeliveries, controller) => {
    console.log('jumpPodRequestServices > postSaveManifestEdit called...');
    return new Promise((resolve, reject) => {
        const parsedDeliveries = changedDeliveries
            .map((item) => {
                if (item.remove) {
                    return `<delivery key="${escapeXml(
                        item.key
                    )}" remove="true"><route key=""/><schedule-date/></delivery>`;
                } else {
                    const deliveryLinesParsed = getParsedDeliveryLine(item);

                    return `<delivery key="${escapeXml(item.key)}" remove="false"><route key="${
                        item.route
                    }"/><schedule-date>${item.date}</schedule-date>${
                        deliveryLinesParsed.length > 0
                            ? '<delivery-lines>' + deliveryLinesParsed + '</delivery-lines>'
                            : ''
                    }</delivery>`;
                }
            })
            .join('');
        const xmlRequestData = `<request type="jumptrack-submit-xml" version="1.2"><deliverys>${parsedDeliveries}</deliverys></request>`;
        axios
            .post(POST_MANIFEST_EDIT, xmlRequestData, {
                signal: controller.current.signal,
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSaveManifestEdit > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSaveManifestEdit > axios err=', err);
                doRejectValidation(err, reject, 'postSaveManifestEdit');
            });
    });
};

/**
 *
 * @param {String} startDate start date for search
 * @param {String} endDate end date for search
 * @param {String} region Region key selected
 * @param {String} distribution Distribution key selected
 * @param {String} route Route Key Selected
 * @returns
 */
export const postDeliveryStopSearch = (
    startDate,
    endDate,
    region,
    distribution,
    route,
    controller
) => {
    console.log('jumpPodRequestServices > postDeliveryStopSearch called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-delivery-stop-search"><response class="delivery-stop" /><delivery-stop><start-date>${startDate}</start-date><end-date>${endDate}</end-date><region key="${region}" /><distribution key="${distribution}"/><route key="${route}"/><limit offset="0" count="1000" /><complete /><old-first>1</old-first></delivery-stop></request>`;
        axios
            .post(POST_DELIVERY_STOP_SEARCH, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postDeliveryStopSearch > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postDeliveryStopSearch > axios err=', err);
                doRejectValidation(err, reject, 'postDeliveryStopSearch');
            });
    });
};
/**
 *
 * @param {Array} data  data Array
 * @param {String} name export CSV name
 * @returns CSV File Upload
 */
export const postExportCsv = (data, name, controller) => {
    console.log('jumpPodRequestServices > postExportCsv called...');
    return new Promise((resolve, reject) => {
        const dataStr = data
            .map((item) => {
                return (
                    '<row>' +
                    item
                        .map((cell) => `<cell>${cell ? escapeXml(cell.toString()) : ''}</cell>`)
                        .join('') +
                    '</row>'
                );
            })
            .join('');
        const xmlRequestData = `<request type="export-csv" name="${name}"><data>${dataStr}</data></request>`;
        axios
            .post(POST_UPLOAD_CSV, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                console.log('postExportCsv > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postExportCsv > axios err=', err);
                doRejectValidation(err, reject, 'postExportCsv');
            });
    });
};

/**
 *
 * @param {Object} params
 * @returns Json array with results
 */
export const postDeliveryUnscheduledAndReturn = ({
    startDate,
    endDate,
    region,
    distribution,
    route,
    controller,
}) => {
    console.log('jumpPodRequestServices > postDeliveryUnscheduledAndReturn called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-delivery-unscheduled-and-return"><delivery><start-date>${startDate}</start-date><end-date>${endDate}</end-date><region key="${region}"/><distribution key="${distribution}"/><route key="${route}"/><limit offset="0" count="999" /></delivery></request>`;
        axios
            .post(POST_DELIVERY_UNSCHEDULED_AND_RETURN, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postDeliveryUnscheduledAndReturn > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postDeliveryUnscheduledAndReturn > axios err=', err);
                doRejectValidation(err, reject, 'postDeliveryUnscheduledAndReturn');
            });
    });
};
/**
 *
 * @param {Object} params
 * @returns Json array with results
 */
export const postPlannedVsActualOverview = ({
    startDate,
    endDate,
    distribution,
    route,
    controller,
}) => {
    console.log('jumpPodRequestServices > postPlannedVsActualOverview called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_TRIP"><start date="${startDate}"/><end date="${endDate}"/><distribution key="${distribution}"/><route key="${route}"/></q></request>`;
        axios
            .post(POST_PLANNED_VS_ACTUAL_OVERVIEW, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postPlannedVsActualOverview > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postPlannedVsActualOverview > axios err=', err);
                doRejectValidation(err, reject, 'postPlannedVsActualOverview');
            });
    });
};
/**
 *
 * @param {String} tripKey
 * @returns Json array with results
 */
export const postReplan = (tripKey, controller) => {
    console.log('jumpPodRequestServices > postReplan called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-dispatch"><replan><trip key="${tripKey}" /></replan></request>`;
        axios
            .post(POST_REPLAN, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postReplan > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postReplan > axios err=', err);
                doRejectValidation(err, reject, 'postReplan');
            });
    });
};
/**
 *
 * @param {String} tripKey
 * @returns Json array with results
 */
export const postUpdatePlan = (tripKey, controller) => {
    console.log('jumpPodRequestServices > postUpdatePlan called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_TRIP" plannable="true" key="${tripKey}"></q></request>`;
        axios
            .post(`${POST_PVA_DETAIL}&tripKey=${tripKey}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postUpdatePlan > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postUpdatePlan > axios err=', err);
                doRejectValidation(err, reject, 'postUpdatePlan');
            });
    });
};
/**
 *
 * @param {String} tripKey
 * @returns Json array with results
 */
export const postOptimizationReset = (tripKey, controller) => {
    console.log('jumpPodRequestServices > postOptimizationReset called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-dispatch"><optimize><trip key="${tripKey}" /></optimize></request>`;
        axios
            .post(POST_OPTIMIZE, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postOptimizationReset > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postOptimizationReset > axios err=', err);
                doRejectValidation(err, reject, 'postOptimizationReset');
            });
    });
};
/**
 *
 * @param {String} routeKey
 * @returns Json array with results
 */
export const postOptimizationResetByRoute = (routeKey, date, controller) => {
    console.log('jumpPodRequestServices > postOptimizationReset called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-dispatch"><optimize><route key="${routeKey}" date="${escapeXml(
            date
        )}"/></optimize></request>`;
        axios
            .post(POST_OPTIMIZE, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postOptimizationReset > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postOptimizationReset > axios err=', err);
                doRejectValidation(err, reject, 'postOptimizationReset');
            });
    });
};
/**
 * Get Trip route by date and route
 * @param {String} routeKey
 * @param {String} date report date
 * @returns Json array with results
 */

export const postQueryTripRoute = (routeKey, date, controller) => {
    console.log('jumpPodRequestServices > postQueryTripRoute called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q name="TRACK_TRIP"><start date="${date}"></start><end date="${date}"></end><distribution></distribution><route key="${routeKey}"></route></q></request>`;

        axios
            .post(POST_QUERY_TRIP_ROUTE, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postQueryTripRoute > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postQueryTripRoute > axios err=', err);
                doRejectValidation(err, reject, 'postQueryTripRoute');
            });
    });
};

/**
 * Get route crumbs a driver
 * @param {String} routeKey
 * @returns Json array with results
 */

export const postQueryRouteCrumbs = (routeKey, controller) => {
    console.log('jumpPodRequestServices > postQueryRouteCrumbs called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-lookup"><q key="${routeKey}" name="TRACK_ROUTE"></q></request>`;

        axios
            .post(POST_QUERY_ROUTE_CRUMBS, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postQueryRouteCrumbs > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postQueryRouteCrumbs > axios err=', err);
                doRejectValidation(err, reject, 'postQueryRouteCrumbs');
            });
    });
};

/**
 * Search deliveries for route status
 * @param {String} startDate start date for search
 * @param {String} endDate end date for search
 * @param {String} region Region key selected
 * @param {String} distribution Distribution key selected
 * @returns
 */
export const postDeliveryStopSearchForRouteStatus = (
    startDate,
    endDate,
    region,
    distribution,
    controller
) => {
    console.log('jumpPodRequestServices > postDeliveryStopSearchForRouteStatus called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-delivery-stop-search"><response class="route-status"/><delivery-stop><start-date>${startDate}</start-date><end-date>${endDate}</end-date><region key="${region}"/><distribution key="${distribution}"/><route/><limit offset="0" count="999"/><complete/><old-first/></delivery-stop></request>`;
        axios
            .post(POST_DELIVERY_STOP_SEARCH, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postDeliveryStopSearchForRouteStatus > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postDeliveryStopSearchForRouteStatus > axios err=', err);
                doRejectValidation(err, reject, 'postDeliveryStopSearchForRouteStatus');
            });
    });
};
/**
 * Send email for a single delivery
 * @param {String} emails email string to send
 * @param {number} deliveryId delivery id to send on the email
 * @param {boolean} siteDefault boolean to set if needs to be the site default for email
 * @returns
 */
export const postSendStatusEmail = (emails, deliveryId, siteDefault, controller) => {
    console.log('jumpPodRequestServices > postSendStatusEmail called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumppod-email-delivery-detail"><email sender="AFFILIATE"><delivery key="${deliveryId}"></delivery><to saveSiteDefault="${siteDefault}">${escapeXml(
            emails
        )}</to></email></request>`;
        axios
            .post(POST_SEND_EMAIL, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log('postSendStatusEmail > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postSendStatusEmail > axios err=', err);
                doRejectValidation(err, reject, 'postSendStatusEmail');
            });
    });
};

/**
 * Get Print status information for delivery
 * @param {object} query parameters
 * @returns XML Parsed delivery
 */
export const postGetPrintStatusInfo = ({
    vendorId,
    account,
    deliveryId,
    displayCd,
    controller,
}) => {
    console.log('jumpPodRequestServices > postGetPrintStatusInfo called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumptrack-enduser-portal"><auth><vendor>${escapeXml(
            vendorId
        )}</vendor><account>${escapeXml(account)}</account></auth><delivery-id>${escapeXml(
            deliveryId
        )}</delivery-id><display-cd>${escapeXml(displayCd)}</display-cd></request>`;
        axios
            .post(POST_PRINT_STATUS, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                console.log('postGetPrintStatusInfo > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postGetPrintStatusInfo > axios err=', err);
                doRejectValidation(err, reject, 'postGetPrintStatusInfo');
            });
    });
};
/**
 *
 * @returns Acceptance of EULA
 */
export const postAcceptRequestLicense = (product, version, controller) => {
    console.log('jumpPodRequestServices > postAcceptRequestLicense called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = ``;
        axios
            .post(
                `${POST_ACCEPT_JUMPTRACK_EULA}&product=${product}&version=${version}`,
                xmlRequestData,
                {
                    signal: controller.current.signal,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    responseType: 'text',
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log('postAcceptRequestLicense > axios res=', res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log('postAcceptRequestLicense > axios err=', err);
                doRejectValidation(err, reject, 'postAcceptRequestLicense');
            });
    });
};
/**
 * Get the manifest data from sync worker
 * @param {String} manifestNo Manifest number
 * @param {String} fromDate From date
 * @param {String} toDate To date
 * @returns Manifest data from sync worker
 */
export const postDownloadManifestDataSyncWorker = (manifestNo, fromDate, toDate, controller) => {
    console.log('jumpPodRequestServices > postDownloadManifestDataSyncWorker called...');
    return new Promise((resolve, reject) => {
        const xmlRequestData = `<request type="jumppod-get-manifest-data"><manifest number="${manifestNo}"><from-day>${fromDate}</from-day><to-day>${toDate}</to-day><upload-file></upload-file><upload-file-type></upload-file-type></manifest></request>`;
        axios
            .post(`${POST_SYNC_WORKER_GET_MANIFEST_DATA}`, xmlRequestData, {
                signal: controller.current.signal,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'text',
                withCredentials: true,
            })
            .then((res) => {
                console.log('postDownloadManifestDataSyncWorker > axios res=', res);
                resolve(parseXmlResult(res.data));
            })
            .catch((err) => {
                console.log('postDownloadManifestDataSyncWorker > axios err=', err);
                doRejectValidation(err, reject, 'postDownloadManifestDataSyncWorker');
            });
    });
};
