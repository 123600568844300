import { IMAGES_REDUCER_HIDE_IMAGE_MODAL, IMAGES_REDUCER_SHOW_IMAGE_MODAL } from 'redux/CONSTANTS';

let initialData = {
    showModal: false,
    imageUrl: null,
};

const imagesReducer = (state = initialData, action) => {
    switch (action.type) {
        case IMAGES_REDUCER_SHOW_IMAGE_MODAL:
            return {
                ...state,
                showModal: true,
                imageUrl: action.url,
            };
        case IMAGES_REDUCER_HIDE_IMAGE_MODAL:
            return {
                ...state,
                showModal: false,
                imageUrl: null,
            };
        default:
            return state;
    }
};
export default imagesReducer;
