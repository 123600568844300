import {
    SYSTEM_MESSAGES_REDUCER_SHOW_ERROR,
    SYSTEM_MESSAGES_REDUCER_HIDE,
    SYSTEM_MESSAGES_REDUCER_SHOW_INFO,
} from 'redux/CONSTANTS';

let initialData = {
    notifications: [],
};

const systemMessagesReducer = (state = initialData, action) => {
    switch (action.type) {
        case SYSTEM_MESSAGES_REDUCER_SHOW_ERROR: {
            console.log('error_title', action.title);
            console.log('error_text', action.message);
            return {
                ...state,
                notifications: [
                    {
                        title: action.title,
                        text: action.message,
                        show: true,
                        type: 'warning',
                    },
                    ...state.notifications,
                ],
            };
        }
        case SYSTEM_MESSAGES_REDUCER_SHOW_INFO: {
            console.log('info_title', action.title);
            console.log('info_text', action.message);
            return {
                ...state,
                notifications: [
                    {
                        title: action.title,
                        text: action.message,
                        show: true,
                        type: 'success',
                    },
                    ...state.notifications,
                ],
            };
        }
        case SYSTEM_MESSAGES_REDUCER_HIDE: {
            let newNotifications = state.notifications;
            newNotifications.splice(state.index, 1);
            return {
                ...state,
                notifications: [...newNotifications],
            };
        }
        default:
            return state;
    }
};
export default systemMessagesReducer;
