import React from 'react';
import loading from 'assets/images/loading.gif';
import { useSelector } from 'react-redux';
const Loading = () => {
    const isLoading = useSelector((state) => state.loading.loading);
    const loadingMessage = useSelector((state) => state.loading.message);

    return (
        <>
            {isLoading ? (
                <div className="block-background">
                    <div className={'loading-container ' + (loadingMessage ? 'with-message' : '')}>
                        <div>
                            <img src={loading} />
                        </div>
                        {loadingMessage && <div>{loadingMessage}</div>}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Loading;
